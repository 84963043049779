import Input from "../shared/Input";
import Button from "../shared/Button";
import { toast } from "react-toastify";

export default function ProjectDiscount({
  projectDiscount,
  registerProjectDiscount,
  closeDiscount,
  salesOfficeMargin,
  setProjectDiscount,
  setSalesOfficeMargin,
  updateProjectDiscounts,
  discountLimit,
}) {
  const onApplyProjectDiscounts = () => {
    if (discountLimit != 0 && projectDiscount > discountLimit) {
      toast("Discount not allowed")
      return;
    }
    updateProjectDiscounts({
      projectDiscount: projectDiscount,
      salesMargin: salesOfficeMargin,
    });
    closeDiscount();
  };

  return (
    <div className="grid gap-2">
      <Input
        elementtype="input"
        label={
          "Project Discount" //+
          //(discountLimit > 0 ? `(Max Dsicount: ${discountLimit}%)` : "")
        }
        name="projectDiscount"
        id="projectDiscount"
        // value={projectDiscount}
        type="number"
        placeholder="0.00"
        value={projectDiscount}
        // onKeyPress={(e) => dutyInputKeyPressHandler(e, "volume", e.target.value)}
        onChange={(e) => setProjectDiscount(e.target.value)}
        // onOptionChanged={(e) => inputChangedHandler("volumeUnit", e.target.value)}
      />
      <Input
        elementtype="input"
        label="Sales Office Margin (%)"
        name="salesOfficeMargin"
        id="salesOfficeMargin"
        type="number"
        placeholder="0.00"
        value={salesOfficeMargin}
        // onKeyPress={(e) => dutyInputKeyPressHandler(e, "pressure", e.target.value)}
        onChange={(e) => setSalesOfficeMargin(e.target.value)}
      />
      <div className="flex flex-row gap-2">
        <Button
          text="Apply"
          buttoncolor="primary"
          buttonSize="xs"
          onClick={onApplyProjectDiscounts}
        ></Button>
        <Button
          text="Cancel"
          buttoncolor="disabled"
          buttonSize="xs"
          onClick={closeDiscount}
        ></Button>
      </div>
    </div>
  );
}
