import * as THREE from "three";
import { Edges } from "@react-three/drei";
import { align, fromMM } from "../Utils/frameUtils";
import { useMemo } from "react";
import { COMPONENT_ALIGNMENT } from "../Constants";

export default function CarbonCylindricalFilters({ frame }) {
  const { carbonCylindricalFilter } = frame;

  const rows = carbonCylindricalFilter?.rows || [0.6, -0.1, 0.3];
  const columns = carbonCylindricalFilter?.columns || [0.333, 0.333, 0.333];
  const numberOfCylinders = carbonCylindricalFilter?.numberOfCylinders || [10, 12, 10, -1, -1, -1, 3, 3, 3];

  const totalFilterWidth = fromMM(carbonCylindricalFilter?.plateWidth || 1885);
  const totalFilterHeight = fromMM(carbonCylindricalFilter?.plateHeight || 1070);

  const length = fromMM(carbonCylindricalFilter?.plateThickness || 40);

  const cylinderDiameter = fromMM(carbonCylindricalFilter?.cylinderDiameter || 145);
  const cylinderLength = fromMM(carbonCylindricalFilter?.cylinderLength || 250);

  const frameWidth = fromMM(frame.width);

  const filters = useMemo(() => {
    return createFilters();
  }, [rows, columns, length,cylinderLength, totalFilterWidth, totalFilterHeight, frame.componentAlignment]);

  function createFilters() {
    const filters = [];
    const initalZ = align(0, frameWidth, columns[0] * totalFilterWidth, COMPONENT_ALIGNMENT.LEFT) - (columns[0] * totalFilterWidth) / 2;

    let z = initalZ;
    let y = align(0, totalFilterHeight, rows[0] * totalFilterHeight, COMPONENT_ALIGNMENT.BOTTOM) - (rows[0] * totalFilterHeight) / 2;

    for (let r = 0; r < rows.length; r++) {
      for (let c = 0; c < columns.length; c++) {
        if (rows[r] > 0 && columns[c] > 0) {
          filters.push(
            <CarbonCylindricalFilter
              key={`carbon-cylinder-${r * columns.length + c}`}
              frame={frame}
              plateWidth={columns[c] * totalFilterWidth}
              plateHeight={rows[r] * totalFilterHeight}
              plateLength={length}
              cylinderDiameter={cylinderDiameter}
              cylinderLength={cylinderLength}
              numberOfCylinders={numberOfCylinders[r * columns.length + c]}
              position={[
                align(0, fromMM(frame.length), length + cylinderLength, frame.componentAlignment),
                y + (rows[r] * totalFilterHeight) / 2,
                z + (columns[c] * totalFilterWidth) / 2,
              ]}
            />
          );
        }
        z += columns[c] * totalFilterWidth;
      }
      y += Math.abs(rows[r]) * totalFilterHeight;
      z = initalZ;
    }

    return filters;
  }

  return <group position={[0, 0, (frameWidth - totalFilterWidth) / 2]}>{filters}</group>;
}

export function CarbonCylindricalFilter({ frame, position, plateWidth, plateHeight, plateLength, cylinderDiameter, cylinderLength, numberOfCylinders }) {
  const frameLength = fromMM(frame.length);

  const columns = 3;
  const cylindersByColumn = [Math.floor(numberOfCylinders / columns), Math.ceil(numberOfCylinders / columns), Math.floor(numberOfCylinders / columns)];

  const maxColumns = Math.max(...cylindersByColumn);

  const holeGapX = plateWidth / columns;
  const holeGapY = plateHeight / maxColumns;

  const gapY = (holeGapY - cylinderDiameter) / 2;
  const gapX = (holeGapX - cylinderDiameter) / 2;

  const cylindricalPositions = [];

  for (let c = 0; c < columns; c++) {
    for (let i = 0; i < cylindersByColumn[c]; i++) {
      cylindricalPositions.push([
        0,
        gapY + align(holeGapY * i, plateHeight, cylinderDiameter, COMPONENT_ALIGNMENT.BOTTOM) + (maxColumns - cylindersByColumn[c]) * (gapY + cylinderDiameter / 2),
        -gapX + align(-holeGapX * c, plateWidth, cylinderDiameter, COMPONENT_ALIGNMENT.FRONT),
      ]);
    }
  }

  return (
    <group position={position}>
      <mesh position={[align(-position[0], frameLength, plateLength, frame.componentAlignment), 0, 0]}>
        <boxGeometry args={[plateLength, plateHeight, plateWidth]} />
        <meshStandardMaterial color={"DarkGrey"} />
        <Edges scale={1} renderOrder={1000}>
          <meshBasicMaterial transparent color="#111" widthTest={true} />
        </Edges>
      </mesh>
      {cylindricalPositions.map((c) => (
        <mesh key={c} position={c} rotation={[0, 0, Math.PI / 2]}>
          <cylinderGeometry args={[cylinderDiameter / 2, cylinderDiameter / 2, cylinderLength, 32]} />
          <meshStandardMaterial color={"Grey"} />
          <Edges scale={1} renderOrder={1000}>
            <meshBasicMaterial transparent color="#111" widthTest={true} />
          </Edges>
        </mesh>
      ))}
    </group>
  );
}
