import { useStore } from "../../components/designer/Store/zustandStore";
import { useConfigurationFan } from "../../hooks/useQueries";
import ItemGrid from "../selection/ItemGrid";
import { useState, useEffect } from "react";
import { MagnifyingGlassPlusIcon, CheckIcon} from "@heroicons/react/24/outline";
import Button from "../shared/Button";


export default function DriveGrid({ ventilationUnitId }) {

  const [selectedUUID] = useStore((state) => [state.selectedUUID]);

  const { fans, selectDrive } = new useConfigurationFan(selectedUUID);
  //MotorPulleySize
  const columns = [
    // {
    //   name: "Model",
    //   //selector: (row) => row?.fan?.modelName,
    //   sortable: true,
    // },

    {
      name: "Dev(%)",
      selector: (row) => row?.drive?.deviation,
      sortable: true,
    },
    {
      name: "M.Pulley",
      selector: (row) => row?.drive?.motorPulleySize,
      sortable: true,
    },

    {
      name: "F.Pulley",
      selector: (row) => row?.drive?.fanPulleySize,
      sortable: true,
    },
    {
      name: "Belt",
      selector: (row) => row?.drive?.beltLength,
      sortable: true,
    },
    {
      name: "No.Belts",
      selector: (row) => row?.drive?.numberBelts,
      sortable: true,
    },
    {
      name: "Centres",
      selector: (row) => row?.drive?.actualCentres,
      sortable: true,
    },
    // {
    //   name: "Motor",
    //   selector: (row) => row?.drive?.motorFrame,
    //   sortable: true,
    // },
    {
      name: "M.Speed",
      selector: (row) => row?.drive?.motorSpeed,
      sortable: true,
    },
    // {
    //   name: "A.Speed",
    //   selector: (row) => row?.drive?.actualFanSpeed,
    //   sortable: true,
    // },
    {
      name: "kW",
      selector: (row) => row?.drive?.motorKW,
      sortable: true,
    },
    {
      name: "Brg Life L10",
      selector: (row) => row?.drive?.operatingHoursL10,
      sortable: true,
    },
    {
      name: "Brg Life L50",
      selector: (row) => row?.drive?.operatingHoursL50,
      sortable: true,
    },
    {
      name: "",
      selector: (row) => row?.drive?.guid == fans?.drives?.activeDrive?.guid ? "" : <Button buttonSize="xs" buttoncolor={"primary"} cssclass="!rounded-md" onClick={() => selectDrive({ventilationUnitId:ventilationUnitId, fanId: fans?.guid, driveId: row?.drive?.guid , referrer: "Selector" })}>Select</Button>,
      sortable: true,
      maxWidth: "30px"  

    }
  ];

  const conditionalRowStyles = [
    {
      //when: (row) => row.velocity <= 4 && row.velocity > 2.5,
      when: (row) => row?.drive?.guid == fans?.drives?.activeDrive?.guid,
      style: {
        backgroundColor: "green",
        opacity: 0.6,
        color: "white",
        "&:hover": {
          cursor: "pointer",
        },
      },
    },

  ];


  const getDriveItems = (vuFan) => {
    let driveItems = [];

    if (vuFan?.drives?.items.length > 0) {
      for (let index = 0; index < vuFan.drives.items.length; index++) {
        const drive = vuFan.drives.items[index];
        driveItems.push({
          drive,
        });
      }
      return driveItems;
    }

    return null;
  };

  useEffect(() => {
    setDriveItems(getDriveItems(fans));
  },[fans]);

  function OpenDrive()
  {
    
  }

const [driveItems, setDriveItems] = useState();

  return (
    driveItems && (
      <div>
          <div className="p-3 flex flex-row">
          <div>
            <h3 className="text-base font-semibold leading-6 text-gray-900">Available Drives</h3>
          </div>
          {/* <div className="ml-auto">
          <Button buttonSize="xs" buttoncolor={"primary"} cssclass="!rounded-md" onClick={OpenDrive}>
            <span id="showPanels-description">
              <a className="floating-btn">
              <MagnifyingGlassPlusIcon className="w-3"></MagnifyingGlassPlusIcon>
              </a>
            </span>{" "}
          </Button>
          </div> */}
        </div>
        <ItemGrid
        columns={columns}
        data={driveItems}
        defaultSortFieldId={2}
        conditionalRowStyles={conditionalRowStyles}
        // progressPending={resultsLoading}
        // // selectableRows
        // onSelectedRowsChange={selectedRowChangedHandler}
         pagination
      />
      </div>
      
    )
  );
}
