import * as THREE from "three";
import { Edges } from "@react-three/drei";
import { align, fromMM } from "../Utils/frameUtils";
import { useMemo } from "react";
import { buildBagFilter } from "../Utils/geometryUtils";
import { COMPONENT_ALIGNMENT } from "../Constants";

export default function BagFilters({ frame, getColour }) {

  const { filter } = frame;
  const rows = filter?.rows || [0.5, 0.25, 0.25];
  const columns = filter?.columns || [0.5, 0.5];
  const length = fromMM(filter?.length || 150);
  const totalFilterWidth = fromMM(filter?.width || frame.width);
  const totalFilterHeight = fromMM(filter?.height || frame.height);

  const filterThickness = fromMM(frame.filter?.filterThickness || 40);

 
  const filters = useMemo(() => {
    return createFilters();
  }, [rows, columns, length, totalFilterWidth, totalFilterHeight, frame.componentAlignment]);

  function createFilters() {
    const filters = [];
    const initalZ = align(0, totalFilterWidth, columns[0] * totalFilterWidth, COMPONENT_ALIGNMENT.LEFT) - (columns[0] * totalFilterWidth) / 2;

    let z = initalZ;
    let y = align(0, totalFilterHeight, rows[0] * totalFilterHeight, COMPONENT_ALIGNMENT.BOTTOM) - (rows[0] * totalFilterHeight) / 2;

    for (let r = 0; r < rows.length; r++) {
      for (let c = 0; c < columns.length; c++) {
        filters.push(
          rows[r] > 0 && columns[c] > 0 ? (
            <BagFilter
              frame={frame}
              width={columns[c] * totalFilterWidth}
              height={rows[r] * totalFilterHeight}
              length={length}
              position={[align(0, fromMM(frame.length), length, frame.componentAlignment), y + (rows[r] * totalFilterHeight) / 2, z + (columns[c] * totalFilterWidth) / 2]}
            />
          ) : (
            <mesh
              position={[
                align(0, fromMM(frame.length), length, frame.componentAlignment)- length / 2,
                y + (Math.abs(rows[r]) * totalFilterHeight) / 2,
                z + (Math.abs(columns[c]) * totalFilterWidth) / 2,
              ]}
            >
              <meshStandardMaterial side={2} color={"Grey"} />
              <Edges scale={1} renderOrder={1000}>
                <meshBasicMaterial transparent color="Grey" widthTest={true} />
              </Edges>
              <boxGeometry args={[fromMM(10), Math.abs(rows[r]) * totalFilterHeight, Math.abs(columns[c]) * totalFilterWidth]}></boxGeometry>
            </mesh>
          )
        );

        z += Math.abs(columns[c]) * totalFilterWidth;
      }
      y += Math.abs(rows[r]) * totalFilterHeight;
      z = initalZ;
    }

    return filters;
  }

  return <group>{filters}</group>;
}

export function BagFilter({ frame, width, length, height, position }) {
  //const frameWidth = fromMM(frame.width);
  //const frameHeight = fromMM(frame.height);

  // const filterTotalHeight = fromMM(frame.height) - fromMM(frame.bagFilter?.gap || 50) * 2;
  const filterThickness = fromMM(frame.filter?.filterThickness || 40);
  // const filterLength = fromMM(frame.bagFilter?.filterLength || 150);

  const requiredFilters = Math.floor(width / filterThickness);

  // const twoRows = frame.bagFilter != null ? frame.bagFilter.twoRows : true;

  // const bottomFilterHeight = filterTotalHeight * (twoRows ? 0.7 : 1);
  // const topFilterHeight = filterTotalHeight * 0.3;

  const filterGeometry = useMemo(() => {
    return buildBagFilter(height, length, filterThickness / 2, requiredFilters);
  }, [height, length, filterThickness, requiredFilters]);

  // const backPlate = useMemo(() => {
  //   return buildDuctPlateWithHole(frameHeight, frameWidth, false, frameWidth * 0.8, frameHeight * 0.8, 0, frameHeight * 0.1);
  // }, [frameHeight, frameWidth]);

  return (
    <group position={position}>
      {/* <mesh geometry={backPlate} rotation-y={Math.PI / 2} position={[((frame.componentAlignment == COMPONENT_ALIGNMENT.LEFT ? -1 : 1) * filterLength) / 2, 0, 0]}>
        <meshStandardMaterial color={getColour("DarkGrey")} />
      </mesh> */}
      <mesh
        castShadow
        receiveShadow
        rotation-y={frame.componentAlignment == COMPONENT_ALIGNMENT.RIGHT ? Math.PI : 0}
        //position={[0, -(filterTotalHeight - bottomFilterHeight) / 2, 0]}
        geometry={filterGeometry}
      >
        <meshStandardMaterial color={"BurlyWood"} />
        <Edges scale={1} renderOrder={1000}>
          <meshBasicMaterial transparent color="#111" depthTest={true} />
        </Edges>
      </mesh>
    </group>
  );
}
