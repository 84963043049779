
export default function PageNotFound() {
  return (
    <div className="flex justify-center items-center h-screen mt-[-6rem]">
      <div className="text-center">
        <h2 className="text-3xl">An error occured</h2>
        <p className="mt-3">Could not find this page</p>
      </div>
    </div>
  );
}
