import { useState, useEffect, useMemo } from "react";
import {
  useSelection,
  useConfigurator,
  useConfigurationInstance,
  useNav,
  useAHUDesignerManager,
  useUser,
} from "../hooks/useQueries";
import Input from "../components/shared/Input";
import Button from "../components/shared/Button";
import ItemGrid from "../components/selection/ItemGrid";
import FanGrid from "../components/selection/FanGrid";
import DriveGrid from "../components/selection/DriveGrid";
import ConfigurationInstance from "../components/configuration/ConfigurationInstance";
// import Tabs from "../components/designer/Tabs";
import { queryClient } from "../react-query/queryClient";
import { selectionQueryKeys } from "../react-query/queryConstants";
import { useNavigate } from "react-router-dom";
import Designer from "../components/selection/Designer";
import Technical from "../components/technical/Technical";
import Pricing from "../components/selection/Pricing";
import { useStore } from "../components/designer/Store/zustandStore";
import Drawings from "../components/selection/Drawings";
import Chart from "../components/selection/Chart";
import SelectionHeader from "../components/headers/SelectionHeader";
import NewModal from "../components/shared/NewModal";
import PrintOptions from "../components/shared/PrintOptions";
import axios from "../axios";
import fileDownload from "js-file-download";
import { generalStore } from "../stores/userStore";
import { TabButton, Tabs } from "../components/shared/Tabs";

export default function Selection(props) {
  const { user } = useUser();

  const [setIsLoading] = generalStore((state) => [state.setIsLoading]);

  const { addComponent } = new useAHUDesignerManager();

  const [showAddComponentSection, setShowAddComponentSection] = useState(false);

  const [
    selectedConnectionMenu,
    selectedUUID,
    showAddComponentOptions,
    toolMode,
    setRequestDrawings,
    showConfigureTab,
    setShowAddComponentOptions,
    setSelectedUUID,
    drawings,
    setShowDrawing,
  ] = useStore((state) => [
    state.setSelectedConnectionMenu,
    state.selectedUUID,
    state.showAddComponentOptions,
    state.toolMode,
    state.setRequestDrawings,
    state.showConfigureTab,
    state.setShowAddComponentOptions,
    state.setSelectedUUID,
    state.drawings,
    state.setShowDrawing,
  ]);

  const {
    //selectProducts,
    // selectionResults,
    selectionInformationObject,
    updateSelectionInformation,
    ventilationInformation,
    //setVentilationInformation,
    getUnits,
    resetSelection,
  } = useSelection();

  const [selectedRows, setSelectedRows] = useState(false);

  const [volume, setVolume] = useState(ventilationInformation?.volume ?? 0);
  const [pressure, setPressure] = useState(
    ventilationInformation?.pressure ?? 0
  );
  const [extraStaticPressure, setExtraStaticPressure] = useState(
    ventilationInformation?.extraStaticPressure ?? 0
  );
  const [volumeUnit, setVolumeUnit] = useState(
    ventilationInformation?.volumeUnit
  );
  const [pressureUnit, setPressureUnit] = useState(
    ventilationInformation?.pressureUnit
  );
  const [printOptionModalIsOpen, setPrintOptionModalIsOpen] = useState();
  const [selectedTechnicalTab, setSelectedTechnicalTab] = useState("Designer");
  const [selectedSelectionTab, setSelectedSelectionTab] = useState("Configure");
  // const [currentTab, setCurrentTab] = useState(tabs[0]);
  // const [currentLeftTab, setCurrentLeftTab] = useState(leftTabs[0]);
  const [selectedVUId, setselectedVUId] = useState();
  const [resultsLoading, setResultsLoading] = useState(false);
  const [printing, setPrinting] = useState(false);
  const [tabs, setTabs] = useState([
    { id: "Designer", name: "Designer", href: "", current: true, icon: null, visible:true },
    {
      id: "Technical",
      name: "Technical",
      href: "",
      current: false,
      icon: null,
      visible:true
    },
    {
      id: "Drawing",
      name: "Drawing",
      href: "",
      current: false,
      icon: null,
      visible:true
    },
    {
      id: "Chart",
      name: "Chart",
      href: "",
      current: false,
      icon: null,
      visible:true
    },
    {
      id: "Pricing",
      name: "Pricing",
      href: "",
      current: false,
      icon: null,
      visible:user.userGroupId == 2 || (user.showCostPrice ?? false) || (user.showSalePrice ?? false)
    },
  ]);
  const [leftTabs, setLeftTabs] = useState([
    { id: "Configure", name: "Configure", href: "", current: true, icon: null },
    {
      id: "Results",
      name: "Available Units",
      href: "",
      current: false,
      icon: null,
    },
  ]);

  useEffect(() => {
    if (ventilationInformation) {
      setVolume(ventilationInformation.volume);
      setPressure(ventilationInformation.pressure);
      setPressureUnit(ventilationInformation.pressureUnit);
      setVolumeUnit(ventilationInformation.volumeUnit);
    }
  }, [ventilationInformation]);

  const {
    configurationInstances,
    selectedConfigurationInstances,
    BOMConfigurationInstances,
    selectedUnitModelName,
    selectVentilationUnit,
  } = useConfigurator(["DesignDetails"]);

  const { configurationInstance } = useConfigurationInstance(
    BOMConfigurationInstances != null && BOMConfigurationInstances.length > 0
      ? BOMConfigurationInstances[0].configurationInstanceId
      : "-"
  );

  useEffect(() => {
    const updatedTabs = [...tabs];
    for (let tab of updatedTabs) {
      if (tab.id == "Designer") {
        tab.name = selectedUnitModelName ? selectedUnitModelName : "Designer";
      }
    }
    setTabs(updatedTabs);
  }, [selectedUnitModelName]);

  //frig to make the drawing tab work
  const [renderDrawing, setRenderDrawing] = useState(false);
  const [selectingTechnicalTab, setSelectingTechnicalTab] = useState(false);
  function selectTechnicalTab(tabId) {
    if (selectedTechnicalTab == "Designer") {
      setRequestDrawings(true);
      setRenderDrawing(true);
      setSelectingTechnicalTab(tabId);
    } else {
      setSelectedTechnicalTab(tabId);
    }
  }

  useEffect(() => {
    if (renderDrawing) {
      setSelectedTechnicalTab(selectingTechnicalTab);
      setRenderDrawing(false);
    }
    console.log(drawings);
  }, [drawings]);

  useEffect(() => {
    setSelectedSelectionTab("Configure");
  }, [showConfigureTab, showAddComponentOptions]);

  const [selectionInformation, setSelectionInformation] = useState(
    queryClient.getQueryData([selectionQueryKeys.selectionInformation]) ??
      selectionInformationObject
  );

  const inputChangedHandler = (key, value) => {
    // ventilationInformation[key] = value;
    if (key == "volume") {
      setVolume(value);
    } else if (key == "pressure") {
      setPressure(value);
    } else if (key == "extraStaticPressure") {
      setExtraStaticPressure(value);
    } else if (key == "volumeUnit") {
      setVolumeUnit(value);
    } else if (key == "pressureUnit") {
      setPressureUnit(value);
    }
  };

  const dutyInputKeyPressHandler = async (e, key, value) => {
    if (e.key === "Enter") {
      // ventilationInformation[key] = value;
    if (key == "volume") {
      setVolume(value);
    } else if (key == "pressure") {
      setPressure(value);
    } else if (key == "extraStaticPressure") {
      setExtraStaticPressure(value);
    } else if (key == "volumeUnit") {
      setVolumeUnit(value);
    } else if (key == "pressureUnit") {
      setPressureUnit(value);
    }
      // ventilationInformation.volume = parseFloat(volume);
      // ventilationInformation.pressure = parseFloat(pressure);
      // ventilationInformation.extraStaticPressure =
      //   parseFloat(extraStaticPressure);
      // ventilationInformation.pressureUnit = pressureUnit;
      // ventilationInformation.volumeUnit = volumeUnit;
      var request = {
        ...ventilationInformation,
        volume: parseFloat(volume),
        pressure: parseFloat(pressure),
        extraStaticPressure: parseFloat(extraStaticPressure),
        pressureUnit: pressureUnit,
        volumeUnit: volumeUnit,
        motorSupplier: "",
      }
      await getUnits(request);
      setShowAddComponentOptions(false);
      setSelectedUUID(null);
    }
  };

  const selectUnits = async () => {
    var request = {
      ...ventilationInformation,
      volume: parseFloat(volume),
      pressure: parseFloat(pressure),
      extraStaticPressure: parseFloat(extraStaticPressure),
      pressureUnit: pressureUnit,
      volumeUnit: volumeUnit,
      motorSupplier: "",
    }
    // setVentilationInformation((prevState) => {
    //   return {
    //     ...prevState,
    //     ...request
    //   };
    // });
    // ventilationInformation.volume = parseFloat(volume);
    // ventilationInformation.pressure = parseFloat(pressure);
    // ventilationInformation.extraStaticPressure =
    //   parseFloat(extraStaticPressure);
    // ventilationInformation.pressureUnit = pressureUnit;
    // ventilationInformation.volumeUnit = volumeUnit;
    // ventilationInformation.motorSupplier = "";
    await getUnits(request);
     setShowAddComponentOptions(false);
     setSelectedUUID(null);
  };

  let selectionResults = [];

  if (configurationInstance) {
    Object.keys(configurationInstance.items).map((key) => {
      const item = configurationInstance.items[key];
      if (item.classConfiguration == "VENTILATION_UNIT") {
        Object.keys(item.values).map((key) => {
          const value = item.values[key];
          selectionResults.push({
            modelName: value.value,
            product: value.description,
            velocity: value.attributes["velocity"]
              ? value.attributes["velocity"]?.value
              : 0,
            espFaceVelocity: value.attributes["espFaceVelocity"]
              ? value.attributes["espFaceVelocity"]?.value
              : 0,
            //espModel: value.attributes["espModel"] ? value.attributes["espModel"]?.value : "",
            espEfficiency: value.attributes["espEfficiency"]
              ? value.attributes["espEfficiency"]?.value
              : "",
            selected: value.selected,
          });
        });
      }
    });
  }

  const handleViewClick = (ventilationUnitId) => {
    setSelectedSelectionTab("Configure");
    selectVentilationUnit({
      ventilationUnitId: ventilationUnitId
    });
    setselectedVUId(ventilationUnitId);
  };

  const handleResetClick = () => {
    // ventilationInformation.volume = parseFloat(0);
    // ventilationInformation.pressure = parseFloat(0);
    // ventilationInformation.extraStaticPressure = parseFloat(0);
    setVolume(0);
    setPressure(0);
    setExtraStaticPressure(0);

    resetSelection();
  };

  const selectedRowChangedHandler = ({ selectedRows }) => {
    setSelectedRows(selectedRows);
  };

  const [toggleConfigMenu, setToggleConfigMenu] = useState(false);
  const handleConfigMenuClick = () => {
    setToggleConfigMenu(!toggleConfigMenu);
  };

  const columns = [
    {
      name: "Product",
      selector: (row) => row.product,
      sortable: true,
      minWidth: "0px",
      maxWidth: "100px",
    },
    {
      name: "Filter F/Velocity (m/s)",
      selector: (row) => row.velocity.toFixed(2),
      sortable: true,
      maxWidth: "100px",
      compact: true,
      minWidth: "0px",
      wrap: true,
    },
    {
      name: "ESP F/Velocity (m/s)",
      selector: (row) => row.espFaceVelocity.toFixed(2),
      sortable: true,
      maxWidth: "100px",
      compact: true,
      minWidth: "0px",
    },
    {
      name: "ESP Eff (%)",
      selector: (row) => row.espEfficiency.toFixed(2),
      sortable: true,
      maxWidth: "100px",
      compact: true,
      minWidth: "0px",
    },
    // {
    //   name: "Fan Style",
    //   selector: (row) => row.product.fanStyle,
    //   sortable: true,
    // },
    // {
    //   name: "Module Width",
    //   selector: (row) => row.product.moduleWidth,
    //   sortable: true,
    // },
    // {
    //   name: "Module Height",
    //   selector: (row) => row.product.moduleHeight,
    //   sortable: true,
    // },
    // // {
    // //   name: "Internal Width",
    // //   selector: (row) => row.product.internalWidth,
    // //   sortable: true,
    // // },
    // // {
    // //   name: "Internal Height",
    // //   selector: (row) => row.product.internalHeight,
    // //   sortable: true,
    // // },
    // {
    //   name: "Inlet Area",
    //   selector: (row) => row.usableUnitArea.toFixed(2),
    //   sortable: true,
    // },
    // {
    //   name: "Fan Model",
    //   selector: (row) => row.fanModel,
    //   sortable: true,
    // },
    // {
    //   name: "Fan airflow (m/s)",
    //   selector: (row) => row.fanSelectionVolume.toFixed(2),
    //   sortable: true,
    // },
    // {
    //   name: "Pressure (Pa)",
    //   selector: (row) => row.fanSelectionPressure.toFixed(2),
    //   sortable: true,
    // },
    // {
    //   name: "Efficiency (%)",
    //   selector: (row) => row.fanSelectionEfficiency.toFixed(2),
    //   sortable: true,
    // },
    {
      selector: (row) =>
        row.modelName != selectedUnitModelName ? (
          <Button
            buttonSize="xs"
            text="select"
            buttoncolor="primary"
            onClick={() => handleViewClick(row.modelName)}
          ></Button>
        ) : (
          ""
        ),
      compact: true,
    },
  ];

  const conditionalRowStyles = [
    // {
    //   when: (row) => row.velocity < 2.5,
    //   style: {
    //     backgroundColor: "grey",
    //     opacity: 0.6,
    //     color: "white",
    //     "&:hover": {
    //       cursor: "pointer",
    //     },
    //   },
    // },
    // {
    //   when: (row) => row.velocity > 4,
    //   style: {
    //     backgroundColor: "red",
    //     opacity: 0.6,
    //     color: "white",
    //     "&:hover": {
    //       cursor: "pointer",
    //     },
    //   },
    // },
    {
      //when: (row) => row.velocity <= 4 && row.velocity > 2.5,
      when: (row) => row.selected, // row.product == selectedUnitModelName,
      style: {
        backgroundColor: "green",
        opacity: 0.6,
        color: "white",
        "&:hover": {
          cursor: "pointer",
        },
      },
    },

    // You can also pass a callback to style for additional customization
    // {
    //   when: row => row.calories < 400,
    //   style: row => ({ backgroundColor: row.isSpecial ? 'pink' : 'inerit' }),
    // },
  ];

  const volumeFlowUnits = [
    { value: "M3S", data: "m³/s" },
    { value: "M3H", data: "m³/h" },
    { value: "LS", data: "l/s" },
    { value: "CFM", data: "CFM" },
  ];

  const pressureUnits = [
    { value: "Pa", data: "Pa" },
    { value: "Inwg", data: "Inwg" },
    { value: "MMwg", data: "MMwg" },
  ];

  const [selectedPrintOptions, setSelectedPrintOptions] = useState([
    "Technical",
    "Drawing",
  ]);

  const printOptions = () => {
    let options = [];
    // options.push({ text: "Summary", value: "Summary" });
    options.push({ text: "Technical Data Sheet", value: "Technical" });
    options.push({ text: "Drawings", value: "Drawing" });
    if(user.userGroupId == 2 || (user.showSalePrice ?? false))options.push({
      text: "SP Price Breakdown",
      value: "SellingPriceBreakdown",
    });
    if (user.userGroupId == 2 || (user.showCostPrice ?? false))
      options.push({
        text: "Cost Price Breakdown",
        value: "CostPriceBreakdown",
      });

    return options;
  };

  const onPrintClick = () => {
    setPrintOptionModalIsOpen(true);
  };

  const onPrintOptionsChanged = (checked, value) => {
    setSelectedPrintOptions((oldValues) => {
      if (checked) {
        oldValues.push(value);
        return [...oldValues];
      } else {
        return [...oldValues.filter((i) => i != value)];
      }
    });
  };

  const onPrint = (printType) => {
    selectTechnicalTab("Designer")
    setIsLoading(true);
    setPrinting(true);
    setRequestDrawings(true);
    setPrintOptionModalIsOpen(false);

    //   const printRequest = {
    //     Id: "-",
    //     PrintType: 1,
    //     Referrer: "Project",
    //     Culture: "en-GB",
    //     Drawings: null,
    //     showSummary: selectedPrintOptions.includes("Summary"),
    //     showTechnical: selectedPrintOptions.includes("Technical"),
    //     showDrawing: selectedPrintOptions.includes("Drawing"),
    //     showSellingPriceBreakdown: selectedPrintOptions.includes(
    //       "SellingPriceBreakdown"
    //     ),
    //     showCostPriceBreakdown:
    //       selectedPrintOptions.includes("CostPriceBreakdown"),
    //   };
    //   axios
    //     .post(`/Print/Print`, printRequest, {
    //       responseType: "blob",
    //     })
    //     .then((response) => {
    //       fileDownload(response.data, "project_summary.pdf");
    //       setPrinting(false);
    //     })
    //     .catch(function (error) {
    //       setPrinting(false);
    //     });
  };

  useEffect(() => {
    if (printing) {
      const printRequest = {
        Id: "-",
        PrintType: 1,
        Referrer: "Selector",
        Culture: "en-GB",
        Drawings: useStore.getState().drawings,
        showSummary: selectedPrintOptions.includes("Summary"),
        showTechnical: selectedPrintOptions.includes("Technical"),
        showDrawing: selectedPrintOptions.includes("Drawing"),
        showSellingPriceBreakdown: selectedPrintOptions.includes(
          "SellingPriceBreakdown"
        ),
        showCostPriceBreakdown:
          selectedPrintOptions.includes("CostPriceBreakdown"),
      };

      setPrintOptionModalIsOpen(false);
      axios
        .post(`/Print/Print`, printRequest, {
          responseType: "blob",
        })
        .then((response) => {
          fileDownload(response.data, selectedUnitModelName + ".pdf");
          setPrinting(false);
          setIsLoading(false);
        })
        .catch(function (error) {
          setPrinting(false);
          setIsLoading(false);
        });
    }
  }, [drawings]);

  return (
    <div className="grid md:grid-cols-4 gap-2 sm:grid-cols-1 grid-rows-1">
      <div className="rounded-lg bg-white col-span-1 ">
        <Tabs
          buttons={leftTabs.map((t) => {
            return (
              <TabButton
                isSelected={t.id == selectedSelectionTab}
                onClick={() => setSelectedSelectionTab(t.id)}
              >
                {t.name}
              </TabButton>
            )
          })}
        >
          <div className="divide-y divide-gray-200">
            {(() => {
              switch (selectedSelectionTab) {
                case "Configure":
                  return (
                    <>
                      <SelectionHeader
                        ventilationUnitId={selectedUnitModelName}
                        onPrintClick={onPrintClick}
                        setSelectedTechnicalTab={setSelectedTechnicalTab}
                      ></SelectionHeader>
                      <div
                        id="tabConfigure"
                        className="h-[calc(100vh-124px)] overflow-y-auto"
                      >
                        {!showAddComponentOptions && ventilationInformation && (
                          <>
                            <div className="gap-2 p-3 grid xl:grid-cols-2 lg:grid-cols-1">
                              <Input
                                elementtype="inputDropdown"
                                label="Volume"
                                name="Volume"
                                id="velocityPressure"
                                value={volume}
                                //options={["m3/s"]}
                                options={volumeFlowUnits}
                                optionValue={volumeUnit}
                                type="text"
                                onKeyPress={(e) =>
                                  dutyInputKeyPressHandler(
                                    e,
                                    "volume",
                                    e.target.value
                                  )
                                }
                                onChange={(e) =>
                                  inputChangedHandler("volume", e.target.value)
                                }
                                onOptionChanged={(e) =>
                                  inputChangedHandler(
                                    "volumeUnit",
                                    e.target.value
                                  )
                                }
                              />
                              <Input
                                elementtype="inputDropdown"
                                label="ESP"
                                name="velocityPressure"
                                id="velocityPressure"
                                options={pressureUnits}
                                optionValue={pressureUnit}
                                onOptionChanged={(e) =>
                                  inputChangedHandler(
                                    "pressureUnit",
                                    e.target.value
                                  )
                                }
                                type="text"
                                placeholder="0.00"
                                value={pressure}
                                onKeyPress={(e) =>
                                  dutyInputKeyPressHandler(
                                    e,
                                    "pressure",
                                    e.target.value
                                  )
                                }
                                onChange={(e) =>
                                  inputChangedHandler(
                                    "pressure",
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                            <div className="flex px-3 pb-3 gap-1.5">
                              <Button
                                text="Select"
                                buttoncolor="primary"
                                buttonSize="xs"
                                onClick={() => selectUnits()}
                              ></Button>
                              <Button
                                text="New Selection"
                                buttoncolor="disabled"
                                buttonSize="xs"
                                onClick={() => handleResetClick()}
                              ></Button>
                            </div>

                            <div className="w-full border-t border-gray-300" />
                          </>
                        )}
                        {configurationInstances &&
                          configurationInstances.map((ci, index) => {
                            return (
                              <>
                                {ci.configurationInstanceId !== "BOM" && (
                                  <ConfigurationInstance
                                    configurationInstance={ci}
                                    visible={!showAddComponentOptions}
                                  ></ConfigurationInstance>
                                )}
                              </>
                            );
                          })}
                        {configurationInstances &&
                          useStore
                            .getState()
                            .selectedComponentInstance?.findIndex(
                              (c) => c == "FanDetails"
                            ) > -1 &&
                          !showAddComponentOptions && (
                            <div className="divide-y  divide-gray-200">
                              <FanGrid
                                configurationInstanceId={"FanDetails"}
                                ventilationUnitId={selectedUnitModelName}
                              ></FanGrid>
                              <div className="mt-2"></div>
                              <DriveGrid
                                ventilationUnitId={selectedUnitModelName}
                              ></DriveGrid>
                            </div>
                          )}

                        {BOMConfigurationInstances && (
                          <div>
                            {/* <ul role="list" className="divide-y divide-gray-200"> */}
                            <ul role="list">
                              {BOMConfigurationInstances.map((ci, index) => (
                                <li key={index}>
                                  <ConfigurationInstance
                                    configurationInstance={ci}
                                    addComponent={addComponent}
                                    visible={showAddComponentOptions}
                                  ></ConfigurationInstance>
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </div>
                    </>
                  );
                case "Results":
                  return (
                    <div id="tabResults">
                      <ItemGrid
                        columns={columns}
                        data={selectionResults}
                        conditionalRowStyles={conditionalRowStyles}
                        progressPending={resultsLoading}
                        //selectableRows
                        onSelectedRowsChange={selectedRowChangedHandler}
                        fixedHeader
                        fixedHeaderScrollHeight="100vh"
                        // pagination
                      />
                    </div>
                  );
              }
            })()}
          </div>
        </Tabs>
      </div>

      <div className="md:col-span-3 overflow-hidden rounded-lg bg-white">
        <Tabs
          buttons={tabs.map((tab) => {
            return tab.visible &&  (
              <TabButton
                isSelected={tab.id == selectedTechnicalTab}
                onClick={() => selectTechnicalTab(tab.id)}
              >
                {tab.name}
              </TabButton>
            );
          })}
        >
          {selectedTechnicalTab == "Designer" && <Designer></Designer>}
          {selectedTechnicalTab == "Technical" && (
            <Technical ventilationUnitId={selectedUnitModelName} />
          )}
          {selectedTechnicalTab == "Drawing" && <Drawings />}
          {selectedTechnicalTab == "Chart" && <Chart />}
          {selectedTechnicalTab == "Pricing" && (
            <Pricing ventilationUnitId={selectedUnitModelName} />
          )}
          {/* {(() => {
            switch (selectedTechnicalTab) {
              case "Designer":
                return <Designer></Designer>;
              case "Technical":
                return <Technical ventilationUnitId={selectedUnitModelName} />;
              case "Drawing":
                return <Drawings />;
              case "Chart":
                return <Chart />;
              case "Pricing":
                return <Pricing ventilationUnitId={selectedUnitModelName} />;
            }
          })()} */}
        </Tabs>
      </div>

      <NewModal
        open={printOptionModalIsOpen}
        setOpen={setPrintOptionModalIsOpen}
        title="Print Options"
      >
        <PrintOptions
          printOptions={printOptions()}
          selectedPrintOptions={selectedPrintOptions}
          setSelectedPrintOptions={setSelectedPrintOptions}
          onChangedHandler={onPrintOptionsChanged}
          onPrint={onPrint}
        ></PrintOptions>
      </NewModal>
    </div>
  );
}
