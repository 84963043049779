import { Html } from "@react-three/drei";
import { useState } from "react";

export default function FloatingButton({ initialIconClass, position, active, children, onToggleMenu, onOcclude, ref, hideButton }) {
  function toggleMenu() {
    if (buttonHidden) return;
    if (onToggleMenu) onToggleMenu();
  }

  const [buttonHidden, setButtonHidden] = useState();
  
  return (
    <Html
      occlude
      onOcclude={(value) => {
       setButtonHidden(false);
        if (onOcclude) onOcclude(value);
      }}
      style={{
        //transition: "all 0.5s",
        opacity: hideButton ? 0 : 1,
        transform: `scale(${hideButton ? 0.5 : 1})`,
      }}
      position={position}
    >
      <div className={active ? "circular-menu active" : "circular-menu"}>
        <a className="floating-btn" onClick={toggleMenu} ref={ref}>
          <i className={`fa ${initialIconClass || "fa-plus"} btn-add-component` }></i>
        </a>
        <menu className="items-wrapper">{children}</menu>
      </div>
    </Html>
  );
}
