import { useEffect, useReducer } from "react";
import AHUDesigner from "../designer/AHUDesigner";

import { CalculatorIcon, CheckCircleIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import Loading from "../layout/Loading";
import { useAHUDesignerManager, usePrint } from "../../hooks/useQueries";
import axios from "../../axios";
import fileDownload from "js-file-download";
import Tabs from "../designer/Tabs";
import Dialogue from "../shared/Dialogue";
import { Modal } from "react-modal-global";
import { useStore } from "../designer/Store/zustandStore";
import frameReducer from "../designer/Store/frameReducer";
import ConfigurationInstance from "../configuration/ConfigurationInstance";

export default function Designer() {
  const [frames, dispatch] = useReducer(frameReducer);

  //const { ventilationUnitId } = route != null ? route.params : null;

  const [calculating, setCalculating] = useState(false);
  const [calculatorIcon, setCalculatorIcon] = useState(CalculatorIcon);
  const [calculateBtnText, setcalculateBtnText] = useState("Calculate");

  const [tabs, setTabs] = useState([
    { name: "Designer", href: "", current: true, icon: null },
    { name: "Technical", href: "", current: false, icon: null },
  ]);

  const [currentTab, setCurrentTab] = useState(tabs[0]);

  const onClickHandler = (name) => {
    const updatedTabs = [...tabs];
    for (let tab of updatedTabs) {
      tab.current = tab.name == name;
    }
    setTabs(updatedTabs);
    setCurrentTab(updatedTabs.find((t) => t.name == name));
  };

  const calculate = () => {
    setCalculatorIcon("");
    setCalculating(true);
    setcalculateBtnText("Calculating");
    setTimeout(() => {
      setCalculatorIcon(CheckCircleIcon);
      setCalculating(false);
      setcalculateBtnText("Calculated");
    }, 1500);
  };

  const print = (printType) => {
    const printRequest = {
      Id: ahuDesignerManager.id,
      PrintType: printType,
      Referrer: "Selector",
      Culture: "en-GB",
    };

    // axios.post("/Print/Print", printRequest);

    axios
      .get(`/Print/${ahuDesignerManager.id}/0/Selector/en-GB`, {
        responseType: "blob",
      })
      .then((response) => {
        fileDownload(response.data, ahuDesignerManager.id + ".pdf");
      });
  };

  const openDialogue = () => {
    let componentType = useStore.getState().componentType;
    axios
      .get(
        `AHUDesigner/GetConfigurationInstance/${ahuDesignerManager.id}/${componentType}`
      )
      .then((response) => {
        if (response) {
          Modal.open(Dialogue, {
            title: "CONFLICTS",
            weak: true,
            body: [
              "This option conflicts with other selected options.",
              <br />,
              "Please proceed if you would like to accept the conflicts.",
            ],
            closable: false,
            children: (
              // <Accordion>
              <ConfigurationInstance
                configurationInstance={response.data}
              ></ConfigurationInstance>
            ),
          });
        }
      });
  };

  const { ahuDesignerManager } = new useAHUDesignerManager();

  // useEffect(() => {
  //   return function () {
  //     setRequestDrawings(true);
  //   };
  // }, []);

  return (
    <div className="grid md:grid-cols-1 sm:grid-cols-1 gap-6">
      {/* <Loading
        cssClass={"absolute h-3/4 w-4/5 items-center justify-center overflow-hidden"}
        svgCssClass="w-8"
      /> */}
      <div className="columns-1 relative">
        <div className="divide-y divide-gray-200 overflow-hidden bg-white">
          {/* <div className="py-2 pt-0 sm:px-2 text-sm font-medium flex justify-end">
              <div className="ml-2">
                <Button
                  icon={PencilIcon}
                  onClick={openDialogue}
                  buttoncolor={"primary"}
                  buttonSize = "xs"
                >
                  Configure
                </Button>
              </div>
              <div className="ml-2">
                <Button
                  icon={PrinterIcon}
                  onClick={() => print(0)}
                  buttoncolor={"primary"}
                  buttonSize = "xs"
                >
                  Print
                </Button>
              </div>
              <div className="ml-2">
                <Button
                  icon={calculatorIcon}
                  onClick={calculate}
                  buttoncolor={
                    calculateBtnText == "Calculated" ? "success" : "primary"
                  }
                  buttonSize = "xs"
                >
                  {calculating && (
                    <Loading isCalculating={true} svgCssClass={"!w-3.5 !h-3.5"}>
                      calculating
                    </Loading>
                  )}
                  {calculateBtnText}
                </Button>
              </div>
            </div> */}

          {ahuDesignerManager ? (
            <AHUDesigner ahuDesignerManager={ahuDesignerManager}></AHUDesigner>
          ) : (
            <div className="flex h-40 items-center text-cente justify-center">
              <h4 className="text-center">No unit found</h4>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
