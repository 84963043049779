import Button from "./Button";
import Input from "./Input";
export default function PrintOptions({ printOptions, selectedPrintOptions, onChangedHandler, onPrint }) {
  return (
    <div className="grid grid-flow-row">
      <div className="gap-2 p-3 grid xl:grid-cols-2 lg:grid-cols-1">
        {printOptions.map((p) => (
          <Input
            elementtype="switch"
            label={p.text}
            name={p.text}
            id={p.value}
            enabled={selectedPrintOptions?.includes(p.value)}
            value={p.value}
            onChange={(e) => onChangedHandler(e, p.value)}
          />
        ))}
      </div>
      <div className="p-3">
        <Button buttonSize="xs" buttoncolor={"primary"} cssclass="!rounded-md mr-3" onClick={onPrint}>
          Print
        </Button>
      </div>
    </div>
  );
}
