import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
  getPaginationRowModel,
  getFilteredRowModel
} from "@tanstack/react-table";
import { useProject, useUser } from "../../hooks/useQueries";
import Button from "../shared/Button";
import { FolderOpenIcon, TrashIcon } from "@heroicons/react/24/outline";
import { Modal } from "react-modal-global";
import PopupModal from "../shared/Modal";
import ItemGrid from "../shared/ItemGrid";
import { useMemo, useState } from "react";

export default function ProjectLists({
  projectLists,
  onOpenProject,
  onDeleteProject,
  user,
}) {
  const data = projectLists?.projectList;

  const { openProject, deleteProject } = useProject();

  const handleOnDeleteProject = (projectId, revision) => {
    Modal.open(PopupModal, {
      title: "",
      weak: true,
      body: ["Are you sure you want to delete this project?"],
      callback: () => {
        deleteProject({
          projectId: projectId,
          userId: user.id,
          Revision: revision,
        });
      },
      closable: false,
    });
    //closeModal();
  };

  const columnHelper = createColumnHelper();

  const columns = useMemo(() => [
    columnHelper.accessor("quotationNumber", {
      id: "quotationNumber",
      // cell: (info) => info.getValue(),
      header: "Quote No.",
      //footer: (info) => info.column.id,
    }),
    columnHelper.accessor("projectName", {
      id: "projectName",

      // cell: (info) => info.getValue(),
      header: "Project Name",
      //footer: (info) => info.column.id,
    }),
    columnHelper.accessor("email", {
      id: "email",

      // cell: (info) => info.getValue(),
      header: "Created By",
      //footer: (info) => info.column.id,
    }),
    columnHelper.accessor("createdDate", {
      id: "createdDate",
      cell: (info) => (
        <i>
          {new Intl.DateTimeFormat("en-GB").format(Date.parse(info.getValue()))}
        </i>
      ),
      header: () => <span>Created Date</span>,
      sortingFn: "datetime",
      //footer: (info) => info.column.id,
    }),
    columnHelper.accessor("modifiedDate", {
      id: "modifiedDate",

      header: () => "Modified Date",
      cell: (info) => (
        <i>
          {new Intl.DateTimeFormat("en-GB").format(Date.parse(info.getValue()))}
        </i>
      ),
      sortingFn: "datetime",

      // cell: (info) => info.renderValue(),
      //footer: (info) => info.column.id,
    }),
    columnHelper.accessor("", {
      id: "openProject",
      cell: (props) => (
        <FolderOpenIcon
          className="h-5 w-5 text-green-600 cursor-pointer"
          onClick={() =>
            onOpenProject(
              props?.row?.original?.projectId,
              props?.row?.original?.projectRevision
            )
          }
        />
      ),
    }),
    columnHelper.accessor("", {
      id: "deleteProject",
      cell: (props) => (
        <TrashIcon
          className="h-5 w-5 text-red-600 cursor-pointer"
          onClick={() =>
            handleOnDeleteProject(
              props?.row?.original?.projectId,
              props?.row?.original?.projectRevision
            )
          }
        />
      ),
    }),
  ]);

  // const table = useReactTable({
  //   data,
  //   columns,
  //   getCoreRowModel: getCoreRowModel(),
  // });

  const [sorting, setSorting] = useState([
    {
      id: "createdDate",
      desc: false, // sort by name in descending order by default
    },
  ]);

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const [columnFilters, setColumnFilters] = useState(); // const [pagination, setPagination] = useState({


  return (
    <>
      <ItemGrid
        columns={columns}
        items={data}
        //columnVisibility={columnVisibility}
        //updateData = {updateData}
        tableOptions={
          {
          getSortedRowModel: getSortedRowModel(),
          getFilteredRowModel: getFilteredRowModel(), //client side filtering
          getPaginationRowModel: getPaginationRowModel(),
          state: {
            sorting,
            pagination,
            columnFilters
          },
          onSortingChange: setSorting,
          onPaginationChange: setPagination,
           onColumnFiltersChange: setColumnFilters,
        }
      }
      />
    </>
  );

  // return (
  //   data && data.length > 0 &&
  //   <div className="px-4 sm:px-6 lg:px-8">
  //     {/* <div className="sm:flex sm:items-center">
  //       <div className="sm:flex-auto">
  //         <h1 className="text-base font-semibold leading-6 text-gray-900">Users</h1>
  //         <p className="mt-2 text-sm text-gray-700">A list of all the users in your account including their name, title, email and role.</p>
  //       </div>
  //       <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
  //         <button
  //           type="button"
  //           className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
  //         >
  //           Add user
  //         </button>
  //       </div>
  //     </div> */}
  //     <div className="mt-8 flow-root">
  //       <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
  //         <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
  //           <table className="min-w-full divide-y divide-gray-300">
  //             <thead>
  //               {table.getHeaderGroups().map((headerGroup) => (
  //                 <tr key={headerGroup.id}>
  //                   {headerGroup.headers.map((header) => (
  //                     <th key={header.id} className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
  //                       {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
  //                     </th>
  //                   ))}
  //                 </tr>
  //               ))}
  //             </thead>
  //             <tbody className="bg-white">
  //               {table.getRowModel().rows.map((row) => (
  //                 <tr key={row.id} className="even:bg-gray-50">
  //                   {row.getVisibleCells().map((cell) => (
  //                     <td key={cell.id} className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
  //                       {flexRender(cell.column.columnDef.cell, cell.getContext())}
  //                     </td>
  //                   ))}
  //                 </tr>
  //               ))}

  //               {/* {people.map((person) => (
  //                 <tr key={person.email} className="even:bg-gray-50">
  //                   <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">{person.name}</td>
  //                   <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.title}</td>
  //                   <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.email}</td>
  //                   <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.role}</td>
  //                   <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3">
  //                     <a href="#" className="text-indigo-600 hover:text-indigo-900">
  //                       Edit<span className="sr-only">, {person.name}</span>
  //                     </a>
  //                   </td>
  //                 </tr>
  //               ))} */}
  //             </tbody>
  //           </table>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // );
}
