export default function TechnicalSubSection(props) {
  return (
    <div className="border">
      <div className="section-header border-b border-gray-200 py-1 px-2 font-semibold text-gray-900">
        <h4>{props.heading}</h4>
      </div>
      <div className="py-2">{props.children}</div>
    </div>
  );
}
