import { useStore } from "../../components/designer/Store/zustandStore";
import { useConfigurationFan } from "../../hooks/useQueries";
import ItemGrid from "../selection/ItemGrid";
import { useState, useEffect } from "react";
import Button from "../../components/shared/Button";

export default function FanGrid({ configurationInstanceId, ventilationUnitId }) {
  const [selectedUUID] = useStore((state) => [state.selectedUUID]);

  const [selectedFan, setSelectedFan] = useState();

  const { fans, selectFan } = new useConfigurationFan(selectedUUID);

  const handleFanSelect = (fanModelName) => {
    selectFan({ ventilationUnitId: ventilationUnitId, fanModelName: fanModelName });
  };

  const columns = [
    {
      name: "Model",
      selector: (row) => row?.fan?.name,
      sortable: true,
    },
    {
      name: "Vel (m/s)",
      selector: (row) => row?.fan?.selectionVelocity.toFixed(2),
      sortable: true,
      width:"auto"
    },
    {
      name: "Eff (%)",
      selector: (row) => row?.fan?.selectionTotalEfficiency.toFixed(2),
      sortable: true,
    },
    {
      name: "Pwr (kW)",
      selector: (row) => row?.fan?.selectionPower.toFixed(2),
      sortable:true,
      width:"auto"
    },
    {
      name: "RPM",
      selector: (row) => row?.fan?.selectionSpeed.toFixed(0),
      sortable:true
    },
    {
      name: "Mtr (kW)",
      selector: (row) => row?.fan?.motors.activeMotor?.ratedPower.toFixed(2),
      sortable:true
    },
    {
      selector: (row) =>
        selectedFan != row?.fan?.modelName && <Button buttonSize="xs" text="select" buttoncolor="primary" onClick={() => handleFanSelect(row?.fan?.modelName)}></Button>,
    },
  ];
  
  const conditionalRowStyles = [
    {
      //when: (row) => row.velocity <= 4 && row.velocity > 2.5,
      when: (row) => selectedFan == row?.fan?.modelName,
      style: {
        backgroundColor: "green",
        opacity: 0.6,
        color: "white",
        "&:hover": {
          cursor: "pointer",
        },
      },
    },

  ];

  const getFanItems = (vuFan) => {
    let fanItems = [];

    if (vuFan?.availableFans?.length > 0) {
      if (vuFan.fan != null) {
        setSelectedFan(vuFan.fan.modelName);
      }

      for (let index = 0; index < vuFan.availableFans.length; index++) {
        const fan = vuFan.availableFans[index];
        fanItems.push({
          fan,
        });
      }
      return fanItems;
    }

    return null;
  };

  useEffect(() => {
    setFanItems(getFanItems(fans));
  }, [fans]);

  const [fanItems, setFanItems] = useState();

  return (
    fanItems && (
      <div>
        <div className="p-3 flex flex-row">
          <div>
            <h3 className="text-base font-semibold leading-6 text-gray-900">Available Fans</h3>
          </div>
        </div>
        <ItemGrid
          columns={columns}
          data={fanItems}
          defaultSortFieldId={2}
           conditionalRowStyles={conditionalRowStyles}
          // progressPending={resultsLoading}
          // // selectableRows
          // onSelectedRowsChange={selectedRowChangedHandler}
          // pagination
        />
      </div>
    )
  );
}
