import Input from "../shared/Input";
import Button from "../shared/Button";

export default function ProjectDiscount({closeModal, customModal, setCustomModal, customPrice, setCustomPrice, customQty, setCustomQty, onAddCustomItem }) {

    return (
    <div className="grid gap-2">
      <Input
        elementtype="input"
        label="Model"
        name="model"
        id="model"
       value={customModal}
        type="text"
        // onKeyPress={(e) => dutyInputKeyPressHandler(e, "volume", e.target.value)}
        onChange={(e) => setCustomModal(e.target.value)}
        // onOptionChanged={(e) => inputChangedHandler("volumeUnit", e.target.value)}
      />
      <Input
        elementtype="input"
        label="Price (AED)"
        name="price"
        id="price"
        type="number"
        placeholder="0.00"
        value={customPrice}
        // onKeyPress={(e) => dutyInputKeyPressHandler(e, "pressure", e.target.value)}
        onChange={(e) => setCustomPrice(e.target.value)}
      />
            <Input
        elementtype="input"
        label="Quantity"
        name="qty"
        id="qty"
        type="number"
        // placeholder="1"
        value={customQty}
        // onKeyPress={(e) => dutyInputKeyPressHandler(e, "pressure", e.target.value)}
        onChange={(e) => setCustomQty(e.target.value)}
      />
      <div className="flex flex-row gap-2">
        <Button text="Apply" buttoncolor="primary" buttonSize="xs" onClick={onAddCustomItem}></Button>
        <Button text="Cancel" buttoncolor="disabled" buttonSize="xs" onClick={closeModal}></Button>
      </div>
    </div>
  );
}
