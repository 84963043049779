import { useMemo } from "react";
import { Edges } from "@react-three/drei";
import * as THREE from "three";
import { buildHollowFrame } from "../Utils/geometryUtils";
import { align, fromMM } from "../Utils/frameUtils";
import FilterProperties from "./Properties/FilterProperties";
import { COMPONENT_ALIGNMENT } from "../Constants";

export default function Filters({ frame }) {
  const { filter } = frame;
  const rows = filter?.rows || FilterProperties.rows;
  const columns = filter?.columns || FilterProperties.columns;
  const length = fromMM(filter?.length || FilterProperties.length);
  const totalFilterWidth = fromMM(filter?.width || frame.width);
  const totalFilterHeight = fromMM(filter?.height || frame.height);

  const filters = useMemo(() => {
    return createFilters();
  }, [rows, columns, length, totalFilterWidth, totalFilterHeight, frame.componentAlignment]);

  function createFilters() {
    const filters = [];
    const initalZ = align(0, totalFilterWidth, columns[0] * totalFilterWidth, COMPONENT_ALIGNMENT.LEFT) - (columns[0] * totalFilterWidth) / 2;

    let z = initalZ;
    let y = align(0, totalFilterHeight, rows[0] * totalFilterHeight, COMPONENT_ALIGNMENT.BOTTOM) - (rows[0] * totalFilterHeight) / 2;

    for (let r = 0; r < rows.length; r++) {
      for (let c = 0; c < columns.length; c++) {
        filters.push(
          rows[r] > 0 && columns[c] > 0 ? (
            <Filter
              frame={frame}
              width={columns[c] * totalFilterWidth}
              height={rows[r] * totalFilterHeight}
              length={length}
              position={[align(0, fromMM(frame.length), length, frame.componentAlignment), y + (rows[r] * totalFilterHeight) / 2, z + (columns[c] * totalFilterWidth) / 2]}
            />
          ) : (
            <mesh
              position={[
                align(0, fromMM(frame.length), length, frame.componentAlignment) - length / 2,
                y + (Math.abs(rows[r]) * totalFilterHeight) / 2,
                z + (Math.abs(columns[c]) * totalFilterWidth) / 2,
              ]}
            >
              <meshStandardMaterial side={2} color={"Grey"} />
              <Edges scale={1} renderOrder={1000}>
                <meshBasicMaterial transparent color="Grey" widthTest={true} />
              </Edges>
              <boxGeometry args={[fromMM(10), Math.abs(rows[r]) * totalFilterHeight, Math.abs(columns[c]) * totalFilterWidth]}></boxGeometry>
            </mesh>
          )
        );

        z += Math.abs(columns[c]) * totalFilterWidth;
      }
      y += Math.abs(rows[r]) * totalFilterHeight;
      z = initalZ;
    }

    return filters;
  }

  return <group>{filters}</group>;
}

function Filter({ frame, width, length, height, position }) {
  //const width = fromMM(frame.width);
  const filterFrameThickness = 0.01;
  const filterGrade = frame.filter?.grade || "F7";
  const component = frame.filter.component;
  const filterFrameGeometry = useMemo(() => {
    return buildHollowFrame(length, height, width, filterFrameThickness);
  }, [length, height, width]);

  return (
    <group dispose={null} position={position}>
      <mesh geometry={filterFrameGeometry}>
        <meshStandardMaterial color={filterGrade === "F7" ? "White" : "Green"} side={THREE.DoubleSide} />
        <Edges scale={1} renderOrder={1000}>
          <meshBasicMaterial transparent color="#111" widthTest={true} />
        </Edges>
      </mesh>
      <mesh>
        <boxGeometry args={[length - filterFrameThickness, height - filterFrameThickness, width - filterFrameThickness]} />
        {/* <meshStandardMaterial color={filterGrade === "F7" ? "SteelBlue" : "Yellow"} /> */}
        <meshStandardMaterial color={component === 11 ? "Orange" : component === 9 ? "Grey" : "SteelBlue"} />
        <Edges scale={1} renderOrder={1000}>
          <meshBasicMaterial transparent color="#111" widthTest={true} />
        </Edges>
      </mesh>
    </group>
  );
}
