import { useState, useEffect } from "react";
import NavBurger from "./NavBurger";
import logo from "../../assets/logo.png";
import { useNav, useUser } from "../../hooks/useQueries";
import NavMenu from "./NavMenu";
import LoginButton from "./LoginButton";
import LogoutButton from "./LogoutButton";
import { userStore } from "../../stores/userStore";
import { Outlet, useRouteLoaderData } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Layout(props) {
  const [isAuthenticated] = userStore((state) => [state.isAuthenticated]);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { navigations, updateCurrentNavigation } = useNav();
  const { refreshToken } = useUser();
  const token = useRouteLoaderData("root");
  const navChangedHandler = (key) => {
    updateCurrentNavigation(key);
    setSidebarOpen(false);
  };

  useEffect(() => {
    if (token) {
      refreshToken();
    }
  }, [token]);

  return (
    <div className="flex">
      {/* Static sidebar for desktop */}
      <div className="hidden lg:flex lg:flex-shrink-0  flex-col sticky top-0 self-start h-screen">
        <div className="flex w-48 flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex min-h-0 flex-1 flex-col border-r border-gray-200 bg-white">
            <div className="flex flex-1 flex-col overflow-y-auto pt-5 pb-4">
              <div className="flex flex-shrink-0 items-center px-4">
                <img className="h-20 w-auto" src={logo} alt="logo" />
              </div>
              <NavMenu
                navigation={navigations}
                setSidebarOpen={() => setSidebarOpen(false)}
                sidebarOpen={sidebarOpen}
                navChangedHandler={navChangedHandler}
              ></NavMenu>
            </div>           
          </div>
        </div>
        <div className="flex flex-shrink-0 border-t border-gray-200 p-4 mt-auto">
          {isAuthenticated ? (
            <LogoutButton></LogoutButton>
          ) : (
            <LoginButton></LoginButton>
          )}
        </div>
      </div>
      <div className="flex min-w-0 flex-1 flex-col bg-gray-100">
        <NavBurger setSidebarOpen={() => setSidebarOpen(true)} />
        <main>
          <div className="p-2">
            {/* Start main area*/}
            <Outlet />
          </div>
        </main>
      </div>
    </div>
  );
}
