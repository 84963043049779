import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import _ from "lodash";
import HighchartsExporting from "highcharts/modules/exporting";
import { useChart } from "../../hooks/useQueries";
import Button from "../shared/Button";
import { useQueryClient } from "react-query";
import { chartQueryKeys } from "../../react-query/queryConstants";
import { useEffect, useRef, useState } from "react";
HighchartsExporting(Highcharts);

let pressureAxis, powerAxis, efficiencyAxis, soundAxis;

const getSeries = (type, chartModel) => {
  const chartConfig = chartModel.chartConfiguration;
  let dataSet = [];

  var chartElements = chartModel[type].series;
  for (var ii = 0; ii < chartElements.length; ii++) {
    var ce = chartElements[ii];

    // if (chartConfig.chartType !== 'Combined' && renderTypes.length > 0)
    //     if (renderTypes.indexOf(ce.lineType == undefined || ce.lineType == 'Undefined' ? ce.ElementType : ce.lineType) === -1)
    //         continue;

    var dataSeries = {
      color: "rgb(210, 210, 210)",
      id: "",
      type: "line",
      data: [],
      yAxis: 0,
      lineWidth: 1,
      dashStyle: "Solid",
      showInLegend: false,
      marker: {
        enabled: false,
        symbol: "circle",
      },
      label: {
        enabled: false,
      },
      dataLabels: {
        align: "center",
        enabled: false,
        formatter: function (s) {
          return this.series.options.options.showLabel ? this.series.options.options.curveLabelText : "";
        },
        style: {
          color: "rgb(210, 210, 210)",
          textShadow: false,
          textOutline: 0,
        },
        padding: 0,
      },
      options: {
        baseColor: "",
        legendLabelText: "",
        curveLabelText: "",
        disableOnLeave: false,
        elementType: "",
        showLabel: false,
      },
    };

    // dataSeries = ce;
    // dataSeries.dataLabels = {
    //   align: "center",
    //   enabled: false,
    //   formatter: function (s) {
    //     return this.series.options.options.showLabel ? this.series.options.options.curveLabelText : "";
    //   },
    //   style: {
    //     color: "rgb(210, 210, 210)",
    //     textShadow: false,
    //     textOutline: 0,
    //   },
    //   padding: 0,
    // };
    dataSeries.data = ce.data;
    dataSet.push(dataSeries);

    var elementColor;

    switch (ce.elementType) {
      case "LineElement":
        dataSeries.options.elementType = "LineElement";
        elementColor = "rgba(" + ce.colorRGBA + ")";
        if (ce.visible) {
          for (var j = 0; j < ce.points.length; j++) {
            var p = ce.points[j];
            var dlEnabled = ce.curveLabel ? ce.curveLabel.showLabel && j == ce.points.length - 1 : false;
            dataSeries.data.push({
              x: parseFloat(p.x),
              y: parseFloat(p.y),
              dataLabels: {
                enabled: dlEnabled,
              },
            });
            // if (ce.lineType === 'Pressure') {
            //     xyPoints.push(p);
            // }
          }

          var elId = dataSeries.options.elementType + "-" + ce.id;
          // dataSeries.name = ce.curveLabel ? elId : ce.tag;
          // dataSeries.id = ce.curveLabel ? elId : ce.tag;
          // if (ce.curveLabel) {
          //   if (ce.curveLabel.text != null && ce.curveLabel.text.length > 0) {
          //     dataSeries.options.curveLabelText = ce.curveLabel.text;
          //     dataSeries.name = ce.curveLabel.text;
          //   }
          // }
          // dataSeries.dataLabels.className = "lbl" + elId;
          // dataSeries.dataLabels.enabled = ce.curveLabel
          //   ? ce.curveLabel.showLabel
          //   : false;
          // if (ce.curveLabel) {
          //   dataSeries.options.disableOnLeave = !ce.curveLabel.showLabel;
          //   dataSeries.options.showLabel = ce.curveLabel.showLabel;
          // }
          dataSeries.color = elementColor;
          // dataSeries.options.baseColor = elementColor;
          dataSeries.dataLabels.style.color = elementColor;
          // // dataSeries.showInLegend = legendLabels.indexOf(ce.Tag) === -1 && ce.IsSelected;
          // dataSeries.lineWidth = ce.width;
          // dataSeries.dashStyle = ce.lineStyle;
          // dataSeries.zIndex = 100;
          // // if (dataSeries.showInLegend)
          // //     legendLabels.push(ce.Tag);
          // if (ce.tag === "Requested" || ce.tag === "Actual") {
          //   dataSeries.name += " Duty";
          // }
          // dataSeries.showInLegend = ce.showLegend;
          if (ce.lineType === "OutputPower" || ce.lineType === "InputPower") {
            dataSeries.yAxis = powerAxis;
          } else if (ce.lineType === "OutputEfficiency" && chartConfig.curveOptions.showOutputPowerEfficiency) {
            dataSeries.yAxis = efficiencyAxis;
          } else if (ce.lineType === "SoundVolume" && chartConfig.curveOptions.showSoundVolume) {
            dataSeries.yAxis = soundAxis;
          } else if (ce.lineType === "Pressure" || ce.lineType === "System") {
            dataSeries.yAxis = pressureAxis;
            if (ce.lineType === "System") dataSeries.id = "SystemCurve";
          }

          // if (ce.legendLabel !== null) {
          //   dataSeries.legendLabelText = ce.legendLabel.text;
          // }

          // if (ce.lineStyle == "LongDash") dataSeries.dashStyle = "longdash";
          // if (ce.lineStyle == "ShortDot") dataSeries.dashStyle = "shortdot";

          // dataSeries.lineType = ce.lineType;
        }
        break;
      case "MarkerElement":
        // continue;
        if (ce.symbolType.indexOf("url") > -1) {
          dataSeries.marker.symbol = ce.symbolType;
        } else {
          elementColor = "rgba(" + ce.colorRGBA + ")";
          dataSeries.marker.fillColor = elementColor;
          dataSeries.marker.lineColor = "rgb(" + ce.borderColorRGB + ")";
          dataSeries.marker.lineWidth = ce.borderWidth;
        }
        dataSeries.marker.enabled = false;

        //dataSeries.data = [[ce.Location.X, ce.Location.Y]];
        dataSeries.data.push({
          x: ce.location.x,
          y: ce.location.y,
          dataLabels: {
            enabled: ce.curveLabel,
          },
        });
        dataSeries.zIndex = 200;
        if (ce.legendLabel !== null) {
          dataSeries.legendLabelText = ce.legendLabel.text;
        }
        if (ce.tag === "Requested" || ce.tag === "Actual") {
          dataSeries.name = ce.tag + " Duty";
          dataSeries.id = "RequestedDutyPoint";
        } else {
          dataSeries.name = ce.tag + " Marker";
        }

        if (ce.curveLabel) {
          if (ce.curveLabel.text !== null && ce.curveLabel.text.length > 0) {
            dataSeries.options.curveLabelText = ce.curveLabel.text;
            dataSeries.dataLabels.style.color = "rgb(" + ce.curveLabel.fontColorRGB + ")";
          }
        }
        dataSeries.dataLabels.className = "lbl" + elId;
        dataSeries.dataLabels.enabled = ce.curveLabel ? ce.curveLabel.showLabel : false;
        if (ce.CurveLabel) {
          dataSeries.options.disableOnLeave = !ce.curveLabel.showLabel;
          dataSeries.options.showLabel = ce.curveLabel.showLabel;
        }

        dataSeries.showInLegend = ce.ShowLegend;
        if (ce.lineType === "OutputPower" || ce.lineType === "InputPower") {
          dataSeries.yAxis = powerAxis;
        } else if (ce.lineType === "OutputEfficiency" && chartConfig.curveOptions.showOutputPowerEfficiency) {
          dataSeries.yAxis = efficiencyAxis;
        } else if (ce.lineType === "SoundVolume" && chartConfig.curveOptions.showSoundVolume) {
          dataSeries.yAxis = efficiencyAxis;
        } else if (ce.lineType === "Pressure" || ce.lineType === "System") {
          dataSeries.yAxis = pressureAxis;
        }
        break;
      default:
        break;
    }
    if (ce.visible) {
      if (dataSeries.data.length > 0) dataSet.push(dataSeries);
    }
  }

  return dataSet;
};

const getOptions = (type, chartModel) => {
  // var series = getSeries(type, chartModel);
  // series.data = chartModel[type].series.data;

  var highchartsOptions = {
    chart: {
      width: 900,
      height: 300,
      animation: false,
    },
    title: chartModel != null ? chartModel[type].title : "",

    series: chartModel[type].series,
    credits: {
      enabled: false,
    },
    plotOptions: {
      series: {
        dataLabels: {
          enabled: false,
        },
      },
    },
  };

  if (chartModel) {
    highchartsOptions.yAxis = chartModel[type].yAxis;
    highchartsOptions.xAxis = chartModel[type].xAxis;
  }
  //console.log(highchartsOptions);
  return highchartsOptions;
};

export default function Chart() {
  const { chartModel } = new useChart();

  const chartRef = useRef();

  const [options, setOptions] = useState({
    chart: {
      width: 900,
      height: 300,
      animation: false,
    },
    title: "",
    series: [
      {
        data: [1, 2, 1, 4, 3, 6],
      },
      {
        data: [2, 7, 0, 4, 6, 2],
      },
    ],
    credits: {
      enabled: false,
    },
    plotOptions: {
      series: {
        dataLabels: {
          enabled: false,
        },
      },
    },
  });

  const updateChart = async () => {
    // await queryClient.invalidateQueries([chartQueryKeys.getChartModel]);
    // //setChartData(getOptions("line", chartModel));
    // let options = getOptions("line", chartModel);
    // for (let index = 0; index < options.series.length; index++) {
    //   chartRef.current.chart.series[index].setData(options.series[index].data, true);
    // }
  };

  // useEffect(() => {
  //   var chart = chartRef.current.chart;
  //   if (chartModel != null && chartModel["Pressure"] != undefined) {
  //     //setOptions({ series: chartModel["Pressure"].series });
  //     for (let index = 0; index < chart.series.length; index++) {
  //       chartRef.current.chart.series[index].setData(chartModel["Pressure"].series[index].data, true);
  //     }
  //   }

  //   //setOptions({ series: chartModel["Pressure"].series });
  // }, [chartModel]);
  return (
    <div className="p-3">
      {chartModel?.Pressure && (
        <div>
          <HighchartsReact
            // highcharts={chartModel.Pressure.chart}
            highcharts={Highcharts}
            // options={options}
            options={getOptions("Pressure", chartModel)}
            oneToOne={true}
            ref={chartRef}
            allowChartUpdate={false}
          />
          <div className="ml-4 mb-3">
            {/* <Button buttonSize="xs" onClick={() => updateChart()}>
         Update
       </Button> */}
          </div>
        </div>
      )}
      {chartModel?.Power && (
        <div>
          <HighchartsReact highcharts={Highcharts} options={getOptions("Power", chartModel)} oneToOne={true} ref={chartRef} allowChartUpdate={false} />
          <div className="ml-4 mb-3">
            {/* <Button buttonSize="xs" onClick={() => updateChart()}>
                Update
              </Button> */}
          </div>
        </div>
      )}

      {chartModel?.Efficiency && (
        <div>
          <HighchartsReact
            // highcharts={chartModel.Pressure.chart}
            highcharts={Highcharts}
            options={getOptions("Efficiency", chartModel)}
            oneToOne={true}
            ref={chartRef}
            allowChartUpdate={false}
          />
          <div className="ml-4 mb-3">
            {/* <Button buttonSize="xs" onClick={() => updateChart()}>
            Update
          </Button> */}
          </div>
        </div>
      )}
    </div>
  );
}
