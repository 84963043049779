import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import Input from "./Input";
import { Switch } from "@headlessui/react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function DropdownButton(props) {
  const buttonPaddingX = () => {
    if (props.buttonSize) {
      if (props.buttonSize == "xs") return "px-2.5";
      if (props.buttonSize == "sm") return "px-3";
      if (props.buttonSize == "md") return "px-4";
      if (props.buttonSize == "lg") return "px-4";
      if (props.buttonSize == "xl") return "px-6";
    } else {
      return "px-4";
    }
  };

  const buttonPaddingY = () => {
    if (props.buttonSize) {
      if (props.buttonSize == "xs") return "py-1.5";
      if (props.buttonSize == "sm") return "py-2";
      if (props.buttonSize == "md") return "py-2";
      if (props.buttonSize == "lg") return "py-2";
      if (props.buttonSize == "xl") return "py-3";
    } else {
      return "py-2";
    }
  };

  const buttonTextSize = () => {
    if (props.buttonSize) {
      if (props.buttonSize == "xs") return "text-xs";
      if (props.buttonSize == "sm") return "text-sm";
      if (props.buttonSize == "md") return "text-sm";
      if (props.buttonSize == "lg") return "text-base";
      if (props.buttonSize == "xl") return "text-base";
    } else {
      return "text-sm";
    }
  };

  const color = () => {
    if (props.buttoncolor) {
      if (props.buttoncolor == "primary") {
        return "bg-primary hover:bg-primary-600 focus:ring-primary-500";
      } else if (props.buttoncolor == "success") {
        return `bg-green-500 hover:bg-green-600 focus:ring-green-500`;
      } else if (props.buttoncolor == "danger") {
        return `bg-red-500 hover:bg-red-600 focus:ring-red-500`;
      } else if (props.buttoncolor == "disabled") {
        return `bg-gray-300 hover:bg-gray-600 focus:bg-gray-500 text-black`;
      }
    } else {
      return "bg-primary hover:bg-primary-600 focus:ring-primary-500";
    }
  };

  const buttonClass = `${
    props.cssclass
  } inline-flex items-center rounded border border-transparent ${color()} ${buttonPaddingX()} ${buttonPaddingY()} ${buttonTextSize()} font-medium text-white shadow-sm  focus:outline-none focus:ring-2  focus:ring-offset-2`;

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        {/* <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"> */}
        <Menu.Button className={buttonClass}>
          {props.text}
          {props.children}
          <ChevronDownIcon
            className="-mr-1 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {props.options.map((o) => (
              //  <Input elementtype={"switch"} value={props.value} enabled={true} label={o.text} onChange={}/>
              <Menu.Item>
                {({ active }) => (
                  //<>
                  //   <a type="submit" className={classNames(active ? "bg-gray-100 text-gray-900" : "text-gray-700", "block w-full px-4 py-2 text-left text-sm")}>
                  //     <Input type="switch" text={o.text} enabled={false} />
                  //   </a>
                  //</>
                  <a
                    href=""
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "block px-4 py-2 text-sm"
                    )}
                    onClick={(e) => {
                      e.preventDefault();
                      props.onClick(o.value);
                    }}
                  >
                    {o.text}
                  </a>
                )}
              </Menu.Item>
            ))}
            {/* 
            <form method="POST" action="#">
              <Menu.Item>
                {({ active }) => (
                  <button type="submit" className={classNames(active ? "bg-gray-100 text-gray-900" : "text-gray-700", "block w-full px-4 py-2 text-left text-sm")}>
                    Sign out
                  </button>
                )}
              </Menu.Item>
            </form> */}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
