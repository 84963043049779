// import { useAuth0 } from "@auth0/auth0-react";

import { ArrowRightOnRectangleIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";

const LoginButton = () => {

  const navigate = useNavigate();

  // const { loginWithRedirect } = useAuth0();

  const loginClickHandler = () => {
    navigate("/login");
  };



  // <button onClick={() => loginClickHandler()}>Log In</button>;

  return (
    <a onClick={() => loginClickHandler()} className="group block w-full flex-shrink-0 cursor-pointer">
      <div className="flex items-center">
        <div>
          <ArrowRightOnRectangleIcon className="text-gray-500mr-4 h-6 w-6" aria-hidden="true" />
          {/* <img
                        className="inline-block h-9 w-9 rounded-full"
                        src="https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80"
                        alt=""
                      /> */}
        </div>
        <div className="ml-1">
          <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">Login</p>
          {/* <p className="text-xs font-medium text-gray-500 group-hover:text-gray-700">
              View profile
            </p> */}
        </div>
      </div>
    </a>
  );
};

export default LoginButton;
