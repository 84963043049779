export default {
  length: 600,
  height: 600,
  width: 800,
  controlBoxLength: 305,
  controlBoxHeight: 480,
  controlBoxWidth: 125,
  backControlBoxLength: 500,
  backControlBoxHeight: 200,
  backControlBoxWidth: 125,
  autoWasherBoxLength: 500,
  autoWasherBoxHeight: 200,
  autoWasherBoxWidth: 250,
  hasAutoWasher: false,
  frame: 100,
  columns:1,
  rows:1
};
