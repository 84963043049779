import { Fragment, useEffect } from "react";
import Frame from "./Frame";
import { TOOL_MODE } from "../Constants";
import Draggable from "../Controls/Draggable";
import { calculateAttached, fromMM } from "../Utils/frameUtils";
import { useStore } from "../Store/zustandStore";
import { useQuery, useQueryClient } from "react-query";
import { configuratorQueryKeys } from "../../../react-query/queryConstants";
import { createEdges, edgesToSVG } from "../Utils/edgeUtils";
import { useThree } from "@react-three/fiber";

export default function Unit({
  setIsDragging,
  isDragging,
  frames,
  setPickup,
  pickup,
  updateUnit,
  draggingEnabled,
}) {
  const [
    toolMode,
    setToolMode,
    selectedUUID,
    setSelectedUUID,
    frameThickness,
    setSelectedComponentInstance,
    requestDrawings,
    setRequestDrawings,
    setDrawings,
    drawingTolerance,
    setFramesAttachedTogether,
    setSelectedUUIDIsInSection,
    setShowConfigureTab,
    showConfigureTab,
  ] = useStore((state) => [
    state.toolMode,
    state.setToolMode,
    state.selectedUUID,
    state.setSelectedUUID,
    state.frameThickness,
    state.setSelectedComponentInstance,
    state.requestDrawings,
    state.setRequestDrawings,
    state.setDrawings,
    state.drawingTolerance,
    state.setFramesAttachedTogether,
    state.setSelectedUUIDIsInSection,
    state.setShowConfigureTab,
    state.showConfigureTab,
  ]);
  const { scene } = useThree();

  const queryClient = useQueryClient();

  useEffect(() => {
    updateUnit("FRAMES", -1, calculateAttached(frames, frameThickness));
  }, []);

  useEffect(() => {
    if (selectedUUID == null || selectedUUID.length == 0)
      setToolMode(TOOL_MODE.SELECTION);
  }, [selectedUUID]);

  useEffect(() => {
    if (requestDrawings) {
      setTimeout(() => {
        const drawings = [];
        const sectionIds = [
          ...new Set(
            frames.filter((f) => f.sectionId != null).map((f) => f.sectionId)
          ),
        ];

        var edges = createEdges(scene, frames, false, drawingTolerance);
        var svg = edgesToSVG(edges, frames, frameThickness, 0.25, 0.25, 0);
        edges = createEdges(scene, frames, true, drawingTolerance);
        var svg_side = edgesToSVG(edges, frames, frameThickness, 0.25, 0.25, 1);
        drawings.push({
          name: "Main",
          top: "data:image/svg+xml;base64," + btoa(svg),
          side: "data:image/svg+xml;base64," + btoa(svg_side),
        });

        sectionIds.forEach((sectionId) => {
          edges = createEdges(
            scene,
            frames,
            false,
            drawingTolerance,
            sectionId
          );
          svg = edgesToSVG(
            edges,
            frames,
            frameThickness,
            0.25,
            0.25,
            0,
            sectionId
          );
          edges = createEdges(scene, frames, true, drawingTolerance, sectionId);
          svg_side = edgesToSVG(
            edges,
            frames,
            frameThickness,
            0.25,
            0.25,
            1,
            sectionId
          );
          drawings.push({
            name: "Section",
            top: "data:image/svg+xml;base64," + btoa(svg),
            side: "data:image/svg+xml;base64," + btoa(svg_side),
          });
        });

        setDrawings(drawings);
        setRequestDrawings(false);
      }, 100);
    }
  }, [requestDrawings]);

  const isFramesAttachedTogether = () => {
    const sUUID = useStore.getState().selectedUUID;
    if (sUUID && sUUID.length > 1) {
      let selectedFrames = [];
      let selectedFramesAttachedConnectionIds = [];

      for (let i = 0; i < sUUID.length; i++) {
        const frame = frames.find((f) => f.id == sUUID[i]);
        if (frame) {
          for (let j = 0; j < frame.connections.length; j++) {
            if (frame.connections[j].attachedTo != null) {
              selectedFramesAttachedConnectionIds.push(
                frame.connections[j].attachedTo
              );
            }
          }
          selectedFrames.push(frame);
        }
      }

      for (let i = 0; i < selectedFrames.length; i++) {
        const frame = selectedFrames[i];
        let attached = false;
        for (let j = 0; j < frame.connections.length; j++) {
          if (frame.connections[j].attachedTo != null) {
            if (
              selectedFramesAttachedConnectionIds.includes(
                frame.connections[j].id
              )
            ) {
              attached = true;
            }
          }
        }

        if (!attached) return false;
      }

      return true;
    }

    return false;
  };

  const selectedUUIDIDHasSectionId = () => {
    const sUUID = useStore.getState().selectedUUID;

    for (let i = 0; i < sUUID.length; i++) {
      const frame = frames.find((f) => f.id == sUUID[i]);
      if (frame) {
        if (frame.sectionId == "" || frame.sectionId == null) {
          return false;
        }
      }
    }

    return true;
  };

  const handleSelection = (e, f) => {
    e.stopPropagation();

    if (
      toolMode !== TOOL_MODE.SELECTION ||
      (selectedUUID?.length > 1 && selectedUUID.includes(f.id))
    )
      return;

    let selectedFrames = [f.id];
    let selectFramesInstanceIds = [];

    if (f.sectionId) {
      const framesInSection = frames
        .filter((frame) => frame.sectionId == f.sectionId && frame.id !== f.id)
        .map((frame) => frame.id);
      selectedFrames = selectedFrames.concat(framesInSection);
    }

    if (f.id) {
      if (f.sectionId) {
        selectFramesInstanceIds = frames
          .filter((frame) => frame.sectionId == f.sectionId)
          .map((frame) => frame.componentInstance);
      } else {
        selectFramesInstanceIds = frames
          .filter((frame) => frame.id == f.id)
          .map((frame) => frame.componentInstance);
      }
    }

    if (e.shiftKey) {
      setSelectedUUID(
        selectedUUID ? selectedUUID.concat(selectedFrames) : selectedFrames
      );

      setFramesAttachedTogether(isFramesAttachedTogether());
    } else {
      setSelectedUUID(selectedFrames);
    }

    setSelectedComponentInstance(selectFramesInstanceIds);

    setSelectedUUIDIsInSection(selectedUUIDIDHasSectionId());

    queryClient.invalidateQueries([
      configuratorQueryKeys.getConfigurationInstances,
    ]);

    setShowConfigureTab(!showConfigureTab);
  };

  function baseFrameLength() {
    let length = 0;
    return frames.reduce(function (a, b) {
      return  a + b["length"];
    },0);
  }  
  function baseFrameWidth() {
    return frames[0].width;
  }

  function baseFramePosition() {
          {/* //<mesh position={[ !isEmpty(data.sectionId) ? (data.hasLeftFrame ? -frameThickness / 2 : frameThickness / 2 ) : 0, -(height / 2 + frameThickness + fromMM(data.baseFrame.height) / 2) - 0.005, 0]} */}
    let height = frames[0].height;
   // return [0,-(fromMM(height / 2) + fromMM(frameThickness) + fromMM(100) / 2) - 0.005,0];
    return [0,0,0];
  }

  return (
    <Draggable
      enabled={draggingEnabled}
      pickup={pickup}
      isDragging={isDragging}
      setIsDragging={setIsDragging}
      updateUnit={updateUnit}
      selectedUUID={selectedUUID}
      frames={frames}
    >
      {frames.map((f) => (
        <Frame
          key={f.id}
          data={f}
          selected={selectedUUID?.includes(f.id)}
          setPickup={setPickup}
          isDragging={isDragging}
          handleSelection={handleSelection}
          updateUnit={updateUnit}
          frames={frames}
        ></Frame>
      ))}
      {/* //<mesh position={[ !isEmpty(data.sectionId) ? (data.hasLeftFrame ? -frameThickness / 2 : frameThickness / 2 ) : 0, -(height / 2 + frameThickness + fromMM(data.baseFrame.height) / 2) - 0.005, 0]} */}
      {/* <mesh
        position={baseFramePosition()}
        userData={{ type: "baseFrame" }}
      >        <boxGeometry args={[fromMM(baseFrameLength()), fromMM(100), fromMM(baseFrameWidth())]} />
        <meshStandardMaterial color="grey" />
      </mesh> */}
    </Draggable>
  );
}
