import { useEffect, useMemo, useState, useRef } from "react";
import * as THREE from "three";
import { Color } from "three";
import { COMPONENT_ALIGNMENT, COMPONENT_TYPES, CONNECTION_LENGTH, DIRECTION_EXTRACT } from "../Constants";
import { Edges, Html } from "@react-three/drei";
import { useStore } from "../Store/zustandStore";
import CircularMenuButton from "./CircularMenuButton";
import { fromMM } from "../Utils/frameUtils";
import FloatingButton from "./FloatingButton";

export default function Connection({ frames, connection, frameLength, frameHeight, frameWidth, frameSelected, hasFrame, visible, isDragging, attached, addFrame }) {
  const [selectedConnectionMenu, setSelectedConnectionMenu, frameThickness, selectedUUID, setShowAddComponentOptions, setSelectedConnectionSide, setShowConfigureTab] = useStore(
    (state) => [
      state.selectedConnectionMenu,
      state.setSelectedConnectionMenu,
      fromMM(state.frameThickness),
      state.selectedUUID,
      state.setShowAddComponentOptions,
      state.setSelectedConnectionSide,
      state.setShowConfigureTab,
    ]
  );

  const geom = useMemo(() => {
    return buildConnectionGeometry(CONNECTION_LENGTH, frameHeight / connection.position.length, frameWidth, connection.side === COMPONENT_ALIGNMENT.LEFT ? -1 : 1);
  }, [frameWidth, frameHeight]);

  const position = [
    (connection.side === COMPONENT_ALIGNMENT.LEFT ? -1 : 1) * (frameLength / 2 + (hasFrame ? frameThickness : 0)),
    connection.position.length == 1 ? 0 : connection.position.indexOf(1) * (frameHeight / connection.position.length) - frameHeight / connection.position.length / 2,
    0,
  ];

  const [buttonHidden, setButtonHidden] = useState();

  // const useOutsideClick = (callback) => {
  //   const ref = useRef();

  //   useEffect(() => {

  //     const handleClick = (event) => {
  //       //TEMP
  //       if(!event.target.className.includes("btn-add-component") && !event.target.className.includes("floating-btn")){
  //         callback();
  //       }
  //       //NEED TO CHECK WHY THIS IS NOT WORKING
  //       // if (ref.current && !ref.current.contains(event.target)) {
  //       //   callback();
  //       //}
  //     };

  //     document.addEventListener('click', handleClick);

  //     return () => {
  //       document.removeEventListener('click', handleClick);
  //     };
  //   }, [ref]);

  //   return ref;
  // };

  // const handleClickOutside = () => {
  //   setShowAddComponentOptions(false);
  // };

  //const ref = useOutsideClick(handleClickOutside);

  function toggleMenu() {
    setShowAddComponentOptions(true);
    setShowConfigureTab(true);
    if (selectedConnectionMenu != connection.id) {
      setSelectedConnectionMenu(connection.id);
    } else {
      setSelectedConnectionMenu(null);
    }
    setSelectedConnectionSide(connection.side);
  }

  const handleAdd = (componentType) => {
    if (!visible || buttonHidden) return;
    setSelectedConnectionMenu(null);
    addFrame(connection, position, componentType);
  };

  const onOcclude = (value) => {
    setButtonHidden(value);
  };
  let [isSelectedSection, setIsSelectedSection] = useState(false);
  useEffect(() => {
    // if (selectedUUID == null || selectedUUID.length ==0) {
    //   setSelectedConnectionMenu(null);
    // }
    //console.log(selectedUUID)

    if (selectedUUID && selectedUUID.length > 1) {
      let sectionId = "";
      let prevSectionId = "";
      let leftSide = "";
      let rightSide = "";

      for (let i = 0; i < selectedUUID.length; i++) {
       sectionId = frames.find((f) => f.id == selectedUUID[i])?.sectionId;
        if (i != 0) {
          if (sectionId != prevSectionId) {
            isSelectedSection = setIsSelectedSection(false);
            return;
          }
        }
        prevSectionId = sectionId;
      }

      let sectionedFrames = frames.filter((f) => f.sectionId == sectionId);

      if (
        (sectionedFrames[0].connections.findIndex(f => f.id == connection.id)> -1 && connection.side === COMPONENT_ALIGNMENT.LEFT) ||
        (sectionedFrames[sectionedFrames.length - 1].connections.findIndex(f => f.id == connection.id) > -1 && connection.side === COMPONENT_ALIGNMENT.RIGHT)
      ) {
        setIsSelectedSection(true);
      }
    }

    isSelectedSection = frames;
  }, [selectedUUID]);

  //console.log(selectedConnectionMenu)

  return (
    <>
      {/* {(!attached && !frameSelected) &&
        <FloatingButton
          position={[position[0] + (connection.side === COMPONENT_ALIGNMENT.LEFT ? -0.1 : 0.1), position[1], position[2]]}
          active={selectedConnectionMenu == connection.id}
          onToggleMenu={toggleMenu}
          onOcclude={onOcclude}
          //ref={ref}
          hideButton={buttonHidden}
        >
        </FloatingButton>
      } */}
      {frameSelected && (selectedUUID?.length == 1 || isSelectedSection) && (
        <FloatingButton
          position={[position[0] + (connection.side === COMPONENT_ALIGNMENT.LEFT ? -0.1 : 0.1), position[1], position[2] + frameWidth / 2]}
          active={selectedConnectionMenu == connection.id}
          onToggleMenu={toggleMenu}
          //ref={ref}
        ></FloatingButton>
      )}
      <mesh
        position={position}
        userData={{ type: "connection", connection: connection }}
        visible={false /* visible */}
        geometry={geom}
        uuid={connection.id}
        scale={[isDragging ? 1 : 0.1, 1, 1]}
      >
        <meshStandardMaterial
          color={connection.direction == null ? Color.NAMES.grey : connection.direction === DIRECTION_EXTRACT ? Color.NAMES.crimson : Color.NAMES.dodgerblue}
          transparent
          opacity={attached ? 0.05 : 0.5}
        />
        <Edges scale={1} renderOrder={1000}>
          <meshBasicMaterial
            transparent
            color={connection.direction == null ? Color.NAMES.grey : connection.direction === DIRECTION_EXTRACT ? Color.NAMES.crimson : Color.NAMES.dodgerblue}
            widthTest={true}
          />
        </Edges>
      </mesh>
    </>
  );
}

function buildConnectionGeometry(length, height, width, side) {
  const geo = new THREE.BoxGeometry(length, height, width);
  geo.applyMatrix4(new THREE.Matrix4().makeTranslation((side * length) / 2, 0, 0));
  return geo;
}
