import UserGrid from "./UserGrid";

export default function Users() {
  return (
    <div className="grid md:grid-cols-1 sm:grid-cols-1 gap-6">

      <div className="columns-1 relative">
        <div className="divide-y divide-gray-200 overflow-hidden bg-white">
          <UserGrid></UserGrid>
        </div>
      </div>
    </div>
  );
}
