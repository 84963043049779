import { useConfigurationInstance, useConfigurationInstanceBOMId, useConfigurator, useConfigurationFan } from "../../hooks/useQueries";
import Accordion from "../shared/Accordion";
import ConfigurationItem from "./ConfigurationItem";
import ConfigurationItemBOM from "./ConfigurationItemBOM";
import { useStore } from "../../components/designer/Store/zustandStore";
import ItemGrid from "../selection/ItemGrid";
import axios from "../../axios";
import { useState, useEffect } from "react";
import Loading from "../layout/Loading";

const ConfigurationInstance = (props) => {
  const [selectedConnectionMenu, selectedUUID, selectedConnectionSide] = useStore((state) => [state.selectedConnectionMenu, state.selectedUUID, state.selectedConnectionSide]);

  const configurationInstanceModel = props.configurationInstance;

  const { configurationInstance } = useConfigurationInstance(configurationInstanceModel.configurationInstanceId ?? "");

  const addComponentHandler = (ci, s, r) => {
    if (selectedUUID && selectedUUID.length) {
      props.addComponent({
        ci: ci,
        id: selectedUUID?.length > 1 ? (selectedConnectionSide == -1 ? selectedUUID[0] : selectedUUID[selectedUUID.length - 1]) : selectedUUID[0],
        //id:   selectedUUID[0] ,
        side: selectedConnectionSide,
      });
    }
  };


  return (
    props.visible && (
      <>
        <div className="p-3">
          <div className="pt-0 pb-3 flex flex-row">
            <div>
              <h3 className="text-base font-semibold leading-6 text-gray-900">{configurationInstanceModel.configurationInstanceDescription}</h3>
            </div>
            {configurationInstance && configurationInstance.instanceId != "BOM" && <Loading cssClass="justify-end float-right ml-auto w-5" />}
          </div>
          <div>
            <div
              className={`${configurationInstance && configurationInstance.instanceId.includes("PressureDetails") ? "md:grid-cols-1 " : "grid md:grid-cols-2"}
              grid sm:grid-row-1 gap-3`}
            >
              {configurationInstance &&
                configurationInstance.instanceId != "BOM" &&
                Object.keys(configurationInstance.items).map((key) => {
                  const configurationItem = configurationInstance.items[key];
                  return (
                    configurationItem.visible && (
                      <div key={key}>
                        <ConfigurationItem configurationItem={configurationItem} configurationInstance={configurationInstance}></ConfigurationItem>
                      </div>
                    )
                  );
                })}
              {configurationInstance &&
                configurationInstance.instanceId == "BOM" &&
                !props.showAvailableBOM &&
                Object.keys(configurationInstance.items).map((key, index) => {
                  const configurationItem = configurationInstance.items[key];
                  return (
                    configurationItem.visible && (
                      <button
                        type="button"
                        className="rounded-md bg-white py-6 px-3.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                        onClick={() => addComponentHandler(configurationItem.classConfiguration, null, null)}>
                        {configurationItem.description}
                      </button>
                    )
                  );
                })}
            </div>
          </div>
        </div>
        <div className="w-full border-t border-gray-300" />
      </>
    )
  );
};

export default ConfigurationInstance;
