import { QueryClient } from "react-query";
import { toast } from "react-toastify";
import { isString } from "lodash";
import { generalStore } from "../stores/userStore";

const queryErrorHandler = (error) => {
  const setHideLoading = generalStore.getState().setHideLoading;;
  //const [setHideLoading] = generalStore((state) => [state.setHideLoading]);

  const msg =
    error instanceof Error
      ? error.response.data && isString(error.response.data)
        ? error.response.data
        : error.message
      : "error connecting to server";
  toast(msg);
  setHideLoading(false);
};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      onError: queryErrorHandler,
      //         staleTime: 600000, // 10 minutes
      //         cacheTime: 900000, // default cacheTime is 5 minutes; doesn't make sense for staleTime to exceed cacheTime
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
    mutations: {
      onError: queryErrorHandler,
    },
  },
});
