import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useEffect, useState } from "react";
import Input from "./Input";
import { date } from "yup";
import Filter from "./Filter";

export default function ItemGrid({
  columns,
  items,
  defaultColumn,
  columnVisibility,
  tableOptions,
}) {
  const [data, setData] = useState();
  //   pageIndex: 0,
  //   pageSize: 10,
  // });
  // const [sorting, setSorting] = useState([
  //   {
  //     id: "createdDate",
  //     desc: false, // sort by name in descending order by default
  //   },
  // ]);
  useEffect(
    function () {
      setData(items);
    },
    [items]
  );

  const table = useReactTable({
    data,
    columns,
    defaultColumn,
    getCoreRowModel: getCoreRowModel(),
    meta: {
      updateData: (rowIndex, columnId, value) => {
        // Skip page index reset until after next rerender
        // skipAutoResetPageIndex()
        setData((old) =>
          old.map((row, index) => {
            if (index === rowIndex) {
              var item = {
                ...old[rowIndex],
                [columnId]: value,
              };
              return item;
            }
            return row;
          })
        );
      },
    },
    ...tableOptions,
    // state: {
    //   //,
    //   columnVisibility,
    //   ...tableOptions?.state,
    // },
  });

  return (
    data && (
      <>
        <div className="table-responsive flex-grow-1 overflow-auto">
          <table className="min-w-full divide-y divide-gray-300 flex-grow-1 table-responsive">
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      className="px-2 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer"
                    >
                      <div onClick={header.column.getToggleSortingHandler()}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                        {{
                          asc: " 🔼",
                          desc: " 🔽",
                        }[header.column.getIsSorted()] ?? null}
                      </div>
                      {header.column.getCanFilter() ? (
                        <div>
                          <Filter column={header.column} />
                        </div>
                      ) : null}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody className="bg-white">
              {table.getRowModel().rows.map((row) => (
                <tr key={row.id} className="even:bg-gray-50 text-left">
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      className="whitespace-nowrap px-2 py-4 text-sm text-gray-500"
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className={"ml-auto m-3"}>
          {table.options.getPaginationRowModel && (
            <>
              <div className="h-2" />
              <div className="flex items-center gap-2">
                <button
                  className="border rounded p-1"
                  onClick={() => table.firstPage()}
                  disabled={!table.getCanPreviousPage()}
                >
                  {"<<"}
                </button>
                <button
                  className="border rounded p-1"
                  onClick={() => table.previousPage()}
                  disabled={!table.getCanPreviousPage()}
                >
                  {"<"}
                </button>
                <button
                  className="border rounded p-1"
                  onClick={() => table.nextPage()}
                  disabled={!table.getCanNextPage()}
                >
                  {">"}
                </button>
                <button
                  className="border rounded p-1"
                  onClick={() => table.lastPage()}
                  disabled={!table.getCanNextPage()}
                >
                  {">>"}
                </button>
                <span className="flex items-center gap-1">
                  <div>Page</div>
                  <strong>
                    {table.getState().pagination.pageIndex + 1} of{" "}
                    {table.getPageCount().toLocaleString()}
                  </strong>
                </span>
                <span className="flex items-center gap-1">| Go to page:</span>
                <span className="flex items-center gap-1 w-24">
                  <Input
                    elementtype="input"
                    type="number"
                    defaultValue={table.getState().pagination.pageIndex + 1}
                    onChange={(e) => {
                      const page = e.target.value
                        ? Number(e.target.value) - 1
                        : 0;
                      table.setPageIndex(page);
                    }}
                    // className="border p-1 rounded w-16"
                  />
                </span>

                <select
                  className="mt-1 block rounded-md border-gray-300 py-2 pl-3 pr-10 focus:border-primary-500 focus:outline-none focus:ring-primary-500 text-sm"
                  value={table.getState().pagination.pageSize}
                  onChange={(e) => {
                    table.setPageSize(Number(e.target.value));
                  }}
                >
                  {[10, 20, 30, 40, 50].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      Show {pageSize}
                    </option>
                  ))}
                </select>
              </div>
            </>
          )}
        </div>
      </>
    )
  );
}
