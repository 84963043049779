import create from "zustand";
import { subscribeWithSelector, shallow } from "zustand/middleware";
import { DEFAULT_EMPTY_THICKNESS, TOOL_MODE } from "../Constants";

export const useStore = create(
  subscribeWithSelector((set) => ({
    selectedConnectionMenu: null,
    setSelectedConnectionMenu: (connectionId) => set(() => ({ selectedConnectionMenu: connectionId })),

    toolMode: TOOL_MODE.SELECTION,
    setToolMode: (mode) => set(() => ({ toolMode: mode })),

    selectedUUID: null,
    setSelectedUUID: (ids) => set(() => ({ selectedUUID: ids })),

    selectedConnectionSide: null,
    setSelectedConnectionSide: (side) => set(() => ({ selectedConnectionSide: side })),

    showDimensions: true,
    setShowDimensions: (value) => set(() => ({ showDimensions: value })),

    frameThickness: DEFAULT_EMPTY_THICKNESS,
    setFrameThickness: (value) => set(() => ({ frameThickness: value })),

    showPanels: false,
    setShowPanels: (value) => set(() => ({ showPanels: value })),

    panelOpacity: 1.0,
    setPanelOpacity: (value) => set(() => ({ panelOpacity: value })),

    doorsOpen: true,
    setDoorsOpen: (value) => set(() => ({ doorsOpen: value })),

    componentType: null,
    setComponentType: (value) => set(() => ({ componentType: value })),

    selectedComponentInstance: null,
    setSelectedComponentInstance: (value) => set(() => ({ selectedComponentInstance: value })),

    showAddComponentOptions: false,
    setShowAddComponentOptions: (value) => set(() => ({ showAddComponentOptions: value })),

    requestDrawings: false,
    setRequestDrawings: (value) => set(() => ({ requestDrawings: value })),

    drawings: [],
    setDrawings: (value) => set(() => ({ drawings: value })),

    drawingTolerance: 50,
    setDrawingTolerance: (value) => set(() => ({ drawingTolerance: value })),

    framesAttachedTogether:false,
    setFramesAttachedTogether: (value) => set(() => ({ framesAttachedTogether: value })),

    selectedUUIDIsInSection:false,
    setSelectedUUIDIsInSection: (value) => set(() => ({ selectedUUIDIsInSection: value })),

    showConfigureTab:false,
    setShowConfigureTab:(value) => set(() => ({ showConfigureTab: value })),

    frames:null,
    setFrames:(value) => set(() => ({ frames: value })),
  }))
);
