import { useMemo } from "react";
import { align, fromMM } from "../Utils/frameUtils";
import { buildPanel } from "../Utils/geometryUtils";
import DoorProperties from "./Properties/DoorProperties";
import { Edges } from "@react-three/drei";
import { COMPONENT_ALIGNMENT } from "../Constants";
import Door from "./Door";
import FloatingButton from "./FloatingButton";
import CircularMenuButton from "./CircularMenuButton";
import { useStore } from "../Store/zustandStore";

export default function Panel({ length, height, rotation, thickness, position, door, opacity, doorsOpen, selected, hasMenu, getColour, accessSide }) {
  const [requestDrawings] = useStore((state) => [state.requestDrawings]);

  const doorLength = fromMM(door?.length || DoorProperties.length);
  const doorAlignment = door?.alignment || DoorProperties.alignment.value;

  const panelLength = door?.visible ? length - doorLength : length;
  const panelAlignment = door?.visible ? doorAlignment * -1 : COMPONENT_ALIGNMENT.LEFT;
  const panelPosition = door?.visible ? [align(position[0], length, panelLength, panelAlignment), position[1], position[2]] : position;
  const hasPanel = panelLength > 0;
  const doorPosition = door?.visible ? [align(position[0], length, doorLength, doorAlignment), position[1], position[2]] : [];
  const panelRotation = rotation || [0, 0, 0];

  const geom = useMemo(() => {
    return buildPanel(panelLength, height, thickness);
  }, [panelLength, height, thickness]);

  return (
    <group>
      {!requestDrawings && hasPanel && (
        <mesh geometry={geom} position={panelPosition} rotation={panelRotation} receiveShadow castShadow>
          <meshStandardMaterial color={getColour("DarkGrey")} transparent={true} opacity={opacity} />
          <Edges scale={1} renderOrder={1000}>
            <meshBasicMaterial transparent color="Grey" widthTest={true} />
          </Edges>
        </mesh>
      )}
      {door?.visible && door?.renderDoor && (
        <Door
          door={door}
          height={height}
          thickness={thickness}
          position={doorPosition}
          rotation={(Math.PI / 4) * door.hinge}
          opacity={opacity}
          open={doorsOpen}
          getColour={getColour}
          accessSide={accessSide}
        />
      )}
      {selected && hasMenu && (
        <FloatingButton initialIconClass={"fa-cog"} position={[panelPosition[0], panelPosition[1], panelPosition[2] + 0.1]} onToggleMenu={() => {}} onOcclude={() => {}}>
          <CircularMenuButton iconClass="fa-cog" handleClick={() => {}} />
        </FloatingButton>
      )}
    </group>
  );
}
