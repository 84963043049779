import { useMemo } from "react";
import { Edges } from "@react-three/drei";
import * as THREE from "three";
import { buildHollowFrame } from "../Utils/geometryUtils";
import { align, fromMM } from "../Utils/frameUtils";
import FlangeProperties from "./Properties/FlangeProperties";
import { COMPONENT_ALIGNMENT } from "../Constants";
import { map, range } from "lodash";
import { useStore } from "../Store/zustandStore";

export default function FusibleLinks({ frame }) {
  const [frameThickness] = useStore((state) => [fromMM(state.frameThickness)]);
  const { flange } = frame;

  const frameLength = fromMM(frame.length);
  const frameHeight = fromMM(frame.height);
  const frameWidth = fromMM(frame.width);

  const rows = flange?.rows || FlangeProperties.rows;
  const columns = flange?.columns || FlangeProperties.columns;
  const length = fromMM(flange?.length || FlangeProperties.length);
  const totalWidth = fromMM(flange?.width || frame.width);
  const totalHeight = fromMM(flange?.height || frame.height);

  const alignment = frame.flangeAlignment || COMPONENT_ALIGNMENT.LEFT;

  const geometry = useMemo(() => {
    return create();
  }, [rows, columns, length, totalWidth, totalHeight, alignment]);

  function create() {
    const components = [];
    const initalZ = align(0, totalWidth, columns[0] * totalWidth, COMPONENT_ALIGNMENT.LEFT) - (columns[0] * totalWidth) / 2;

    let z = initalZ;
    let y = align(0, totalHeight, rows[0] * totalHeight, COMPONENT_ALIGNMENT.BOTTOM) - (rows[0] * totalHeight) / 2;

    for (let r = 0; r < rows.length; r++) {
      for (let c = 0; c < columns.length; c++) {
        if (rows[r] > 0 && columns[c] > 0) {
          components.push(
            <FusibleLink
              key={`fusible-link-${r * columns.length + c}`}
              frame={frame}
              width={columns[c] * totalWidth}
              height={rows[r] * totalHeight}
              length={length}
              position={[align(frameThickness * alignment, fromMM(frame.length), -length, alignment), y + (rows[r] * totalHeight) / 2, z + (columns[c] * totalWidth) / 2]}
            />
          );
        }
        z += columns[c] * totalWidth;
      }
      y += Math.abs(rows[r]) * totalHeight;
      z = initalZ;
    }

    return components;
  }

  return <group>{geometry}</group>;
}

export function FusibleLink({ frame, width, length, height, position }) {
  const { fusibleLink } = frame;

  const thickness = 0.01;

  const frameGeometry = useMemo(() => {
    return buildHollowFrame(length, height, width, thickness);
  }, [length, height, width]);

  const filterHeight = fromMM(8);
  const lengthOfFilter = Math.sqrt(filterHeight ** 2 + length ** 2);
  const angleOfFilter = Math.atan(filterHeight / length);
  const numberOfSlopes = 6;
  const totalSlopeHeight = numberOfSlopes * filterHeight;
  return (
    <group position={position}>
      <mesh geometry={frameGeometry}>
        <meshStandardMaterial color={"Grey"} side={THREE.DoubleSide} />
        <Edges scale={1} renderOrder={1000}>
          <meshBasicMaterial transparent color="#111" widthTest={true} />
        </Edges>
      </mesh>
      <group position={[0, align(0, height, filterHeight, COMPONENT_ALIGNMENT.TOP) - thickness, 0]}>
        {map(range(0, numberOfSlopes), (i) => {
          return (
            <Slope
              key={`fl-slope-${i}`}
              width={width - thickness * 2}
              position={[0, -filterHeight * i, 0]}
              lengthOfFilter={lengthOfFilter}
              angleOfFilter={angleOfFilter * (i % 2 == 0 ? -1 : 1)}
            />
          );
        })}
      </group>
    </group>
  );
}

function Slope({ width, position, lengthOfFilter, angleOfFilter }) {
  return (
    <mesh position={position} castShadow receiveShadow rotation-z={angleOfFilter}>
      <boxGeometry args={[lengthOfFilter, 0.005, width]} />
      <meshStandardMaterial color={"DarkGrey"} />
      <Edges scale={1} renderOrder={1000}>
        <meshBasicMaterial transparent color="#111" depthTest={true} />
      </Edges>
    </mesh>
  );
}
