import axios from "axios";
import { webAPIUrl } from "./AppSetting";
import { toast } from "react-toastify";
import { useUser,logoutAxios } from "./hooks/useQueries";
import { userStore } from "./stores/userStore";

const instance = axios.create({
  baseURL: webAPIUrl,
  withCredentials: true,
  // headers:{
  //      'Authorization': user != null ? `Basic ${user.token}` : ""
  // }
});

instance.interceptors.request.use(async (response) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const token = user != null ? user.jwtToken : "";
  response.headers.Authorization = token;
  return response;
});

// instance.interceptors.response.use(async (response) => {

//   return response.text().then((text) => {
//     const data = text && JSON.parse(text);

//     if (!response.ok) {
//       const { logout } = useUser();
//       const [user] = userStore((state) => [state.user]);
//       if ([401, 403].includes(response.status) && user) {
//         // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
//         logout();
//       }

//       const error = (data && data.message) || response.statusText;
//       return Promise.reject(error);
//     }

//     return data;
//   });
  

// });

instance.interceptors.response.use(
  
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
 function (error) {
  //const {logout} = useUser();

  const {status} = error.response;

    // Refresh token conditions
    if (
     // refreshToken &&
      status === 401     
     // originalRequest?.url !== refreshTokenUrl &&
    //  originalRequest?._retry !== true
    ) {
      logoutAxios();


      // if (isRefreshing) {
      //   return new Promise(function (resolve, reject) {
      //     failedQueue.push({ resolve, reject });
      //   })
      //     .then(() => {
      //       return client(originalRequest);
      //     })
      //     .catch((err) => {
      //       return Promise.reject(err);
      //     });
      // }
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);


export default instance;
