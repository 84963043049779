import Button from "../shared/Button";
import DropdownButton from "../shared/DropdownButton";
import Loading from "../layout/Loading";
import { useUser } from "../../hooks/useQueries";

export default function ProjectHeader({ saveProject, getProjects, onNewProject, onPrintSummary, onPrint, printing, openDiscountModal, openCustomItemModal, onPrintClick, onRefreshPrice }) {
  const { user } = useUser();

  const printOptions = () => {
    let options = [];
    options.push({ text: "Summary", value: "Summary" });
    options.push({ text: "Technical", value: "Technical" });
    options.push({ text: "Drawings", value: "Drawing" });
    if(user.userGroupId == 2 || (user.showSalePrice ?? false))options.push({ text: "Price Breakdown", value: "SellingPriceBreakdown" });
    if (user.userGroupId == 2 || (user.showCostPrice ?? false)) options.push({ text: "Cost Price Breakdown", value: "CostPriceBreakdown" });
  };

  return (
    <div className="border-b border-gray-200 p-3">
      <Loading cssClass="justify-end float-left ml-auto w-5 mr-3" isCalculating={printing} />

      <Button buttonSize="xs" buttoncolor={"primary"} cssclass="!rounded-md mr-3" onClick={getProjects}>
        Projects
      </Button>
      <Button buttonSize="xs" buttoncolor={"primary"} cssclass="!rounded-md mr-3" onClick={onNewProject}>
        New Project
      </Button>
      {/* <Button buttonSize="xs" buttoncolor={"primary"} cssclass="!rounded-md mr-3" onClick={onPrintSummary}>
        Print Summary
      </Button>
      <Button buttonSize="xs" buttoncolor={"primary"} cssclass="!rounded-md mr-3" onClick={onPrintSummary}>
        Print Technical
      </Button> */}

      <Button buttonSize="xs" buttoncolor={"primary"} cssclass="!rounded-md mr-3" onClick={onPrintClick}>
        Print
      </Button>
      {/* <DropdownButton
        buttonSize="xs"
        buttoncolor={"primary"}
        cssclass="!rounded-md mr-3"
        options={[
          { text: "Summary", value: "Summary" },
          { text: "Technical", value: "Technical" },
          { text: "Drawings", value: "Drawing" },
          { text: "Price Breakdown", value: "SellingPriceBreakdown" },
          { text: "Cost Price Breakdown", value: "CostPriceBreakdown" },
        ]}
        onPrint={onPrint}
      >
        Print
      </DropdownButton> */}
      <Button buttonSize="xs" buttoncolor={"primary"} cssclass="!rounded-md mr-3" onClick={openDiscountModal}>
        Discounts
      </Button>
      <Button buttonSize="xs" buttoncolor={"primary"} cssclass="!rounded-md mr-3" onClick={openCustomItemModal}>
        Custom Item
      </Button>
      <Button buttonSize="xs" buttoncolor={"primary"} cssclass="!rounded-md mr-3" onClick={onRefreshPrice}>
        Refresh Price
      </Button>
      {/* <Button buttonSize="xs" buttoncolor={"primary"} cssclass="!rounded-md mr-3" onClick={saveProject}>
        Save
      </Button> */}
      <DropdownButton buttonSize="xs" buttoncolor={"primary"} cssclass="!rounded-md mr-3" onClick={(val) => saveProject(val)} options={[
          { text: "Save without revision", value: "Save" },
          { text: "Save with revision", value: "SaveIncrement" }
        ]}>
        Save
      </DropdownButton>

    </div>
  );
}
