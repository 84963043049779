import { useMemo, useRef } from "react";
import { useSpring, animated, config } from "@react-spring/three";
import { fromMM } from "../Utils/frameUtils";
import { buildPanel } from "../Utils/geometryUtils";
import DoorProperties from "./Properties/DoorProperties";
import * as THREE from "three";
import { Edges } from "@react-three/drei";
import { useStore } from "../Store/zustandStore";
import { ACCESS_SIDE, DOOR_HINGE } from "../Constants";

export default function Door({ door, height, thickness, position, opacity, open, getColour, accessSide }) {
  const [requestDrawings] = useStore((state) => [state.requestDrawings]);

  const length = fromMM(door.length || DoorProperties.length);
  const hinge = door?.hinge != undefined ? door.hinge : DoorProperties.hinge.value;
  const louvered = door?.louvered != undefined ? door.louvered : DoorProperties.louvered;
  const doorMesh = useRef();
  const doorMesh2 = useRef();
  const openAngle = (accessSide != undefined ? (accessSide == ACCESS_SIDE.LEFT ? -1 : 1) : 1) * (Math.PI / 2) * hinge;
  const closedPosition = [position[0] - (length / 2) * hinge * -1, position[1], position[2] + thickness / 2];
  const openPosition = [position[0] - (length / 2) * hinge * -1, position[1], position[2] + ((hinge == DOOR_HINGE.LIFT ? fromMM(50) : 0) + thickness / 2) * accessSide];
 
  const openPosition2 = [
    position[0] - length / 2 * hinge * -1,
    position[1] + height / 2,
    position[2] + (hinge == DOOR_HINGE.LIFT ? fromMM(50) : 0) + accessSide * thickness / 2 ,
  ];

  const { rotation, pos } = useSpring({
    rotation: open ? openAngle : 0,
    pos: open && hinge == DOOR_HINGE.LIFT ? openPosition : closedPosition,
    config: config.stiff,
  });

  const geom = useMemo(() => {
    const panel = buildPanel(length, height, thickness, louvered);
    panel.applyMatrix4(new THREE.Matrix4().makeTranslation((length / 2) * hinge * -1, 0, -thickness / 2));

    if (louvered && accessSide == ACCESS_SIDE.LEFT) {
      panel.applyMatrix4(new THREE.Matrix4().makeRotationX(90 * (Math.PI / 90)));
    }

    return panel;
  }, [length, height, hinge, louvered, thickness]);

  const geom2 = useMemo(() => {
     
    const curve = new THREE.EllipseCurve(0, 0, length, length * 0.9, 0, accessSide * -Math.PI / 2, accessSide == ACCESS_SIDE.LEFT ? false : true);
    const points = curve.getSpacedPoints(20);
    points.push(new THREE.Vector2(0, 0));
    const shape = new THREE.Shape(points);
    const geometry = new THREE.ShapeGeometry(shape);
    geometry.applyMatrix4(new THREE.Matrix4().makeRotationX(90 * (Math.PI / 180)));

    return geometry;
  }, [length, height, hinge, louvered, thickness]);

  return (
    <group>
      <animated.mesh
        geometry={geom}
        ref={doorMesh}
        position={requestDrawings ? openPosition : pos}
        rotation-y={requestDrawings ? openAngle : rotation}
        receiveShadow
        castShadow
        userData={{ type: "door" }}
      >
        //animated.mesh
        <meshStandardMaterial color={getColour("DarkGrey")} transparent={true} opacity={opacity} />
        <Edges scale={1} renderOrder={1000}>
          <meshBasicMaterial transparent color="Grey" widthTest={true} />
        </Edges>
      </animated.mesh>
      //new mesh
      {hinge != DOOR_HINGE.LIFT && requestDrawings && (
        <animated.mesh
          geometry={geom2}
          ref={doorMesh2}
          position={requestDrawings ? openPosition2 : openPosition2}
          rotation-y={requestDrawings ? openAngle : rotation}
          receiveShadow
          castShadow
          userData={{ type: "door" }}
        >
          //animated.mesh
          <meshStandardMaterial side={THREE.DoubleSide} color={getColour("DarkGrey")} transparent={true} opacity={opacity} />
        </animated.mesh>
      )}
    </group>
  );
}
