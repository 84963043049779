import { useState, useMemo, useEffect, useRef } from "react";
import Input from "../components/shared/Input";
import { useForm, useFormState, useWatch } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useProject, useUser, usePrint } from "../hooks/useQueries";
import { Tabs, TabButton } from "../components/shared/Tabs";
import ProjectHeader from "../components/project/ProjectHeader";
import ProjectLists from "../components/project/ProjectLists";
import Modal from "react-modal";
import ProjectItemsGrid from "../components/project/ProjectItemsGrid";
import { toast } from "react-toastify";
import fileDownload from "js-file-download";
import axios from "../axios";
import { generalStore } from "../stores/userStore";
import ProjectDiscount from "../components/project/ProjectDiscount";
import CustomItem from "../components/project/CustomItem";
import NewModal from "../components/shared/NewModal";
import PrintOptions from "../components/shared/PrintOptions";
import { queryClient } from "../react-query/queryClient";
import { projectQueryKeys } from "../react-query/queryConstants";

const Project = () => {
  const { user } = useUser();
  const [projectLocation, setProjectLocation] = useState();
  const [setHideLoading] = generalStore((state) => [
    state.hideLoading,
    state.setHideLoading,
  ]);

  //const { deleteProjectItem, cloneProjectItem, updateProjectItemField } = useProject();

  const {
    project,
    setProject,
    projectItems,
    saveProject,
    projectLists,
    openProject,
    newProject,
    projectLocations,
    cities,
    projectStatus,
    competitors,
    poStatus,
    lostReasons,
    consultants,
    updateProjectDiscounts,
    addCustomItem,
    updateProjectDetails,
    refreshProjectPrice,
    discountLimit,
    checkProjectDiscountWithLocation,
    projectItemsSummary,
  } = useProject(projectLocation);

  // const { printSummary } = usePrint();

  const [modalIsOpen, setIsOpen] = useState(false);
  const [discountModalIsOpen, setDiscountModalIsOpen] = useState(false);
  const [customItemModalIsOpen, setCustmItemModalIsOpen] = useState(false);
  const [projectDiscount, setProjectDiscount] = useState();
  const [salesOfficeMargin, setSalesOfficeMargin] = useState();

  const [customModal, setCustomModal] = useState();
  const [customPrice, setCustomPrice] = useState();
  const [customQty, setCustomQty] = useState(1);

  const [printOptionModalIsOpen, setPrintOptionModalIsOpen] = useState();

  useEffect(function () {
    return () => {
      if (project) updateProjectDetails({ project: getValues() });
    };
  }, []);

  useEffect(function () {}, []);

  const handleOpenProject = (projectId, revision) => {
    openProject({ projectId: projectId, userId: user.id, Revision: revision });
    closeModal();
  };

  const handleNewProject = (projectId) => {
    newProject();
    setProjectLocation("");
    closeModal();
  };

  const [printing, setPrinting] = useState(false);

  const onPrintSummary = () => {
    setPrinting(true);
    const printRequest = {
      Id: "-",
      PrintType: 1,
      Referrer: "Project",
      Culture: "en-GB",
      Drawings: null,
      showSummary: true,
      showTechnical: false,
      showDrawing: false,
    };

    axios
      .post(`/Print/Print`, printRequest, {
        responseType: "blob",
      })
      .then((response) => {
        fileDownload(response.data, "project_summary.pdf");
        setPrinting(false);
      })
      .catch(function (error) {
        setPrinting(false);
      });

    // printSummary(printRequest);
  };

  const onPrint = (printType) => {
    setPrinting(true);

    const printRequest = {
      Id: "-",
      PrintType: 1,
      Referrer: "Project",
      Culture: "en-GB",
      Drawings: null,
      showSummary: selectedPrintOptions.includes("Summary"),
      showTechnical: selectedPrintOptions.includes("Technical"),
      showDrawing: selectedPrintOptions.includes("Drawing"),
      showSellingPriceBreakdown: selectedPrintOptions.includes(
        "SellingPriceBreakdown"
      ),
      showCostPriceBreakdown:
        selectedPrintOptions.includes("CostPriceBreakdown"),
    };

    setPrintOptionModalIsOpen(false);
    axios
      .post(`/Print/Print`, printRequest, {
        responseType: "blob",
      })
      .then((response) => {
        fileDownload(response.data, "project_summary.pdf");
        setPrinting(false);
      })
      .catch(function (error) {
        setPrinting(false);
      });

    // printSummary(printRequest);
  };

  // const handleonDeleteProject = (projectId) => {
  //   Modal.open(PopupModal, {
  //     title: "",
  //     weak: true,
  //     body: ["Are you sure you want to delete this project?"],
  //     callback: () => {
  //       deleteProject({ projectId: projectId, userId: user.id, Revision: 0 })
  //     },
  //     closable: false,
  //   });

  //   //closeModal();
  // };

  const validationSchema = yup.object().shape({
    name: yup.string().required("Required"),
    contact: yup.string().required("Required"),
    projectLocation: yup.string().required("Required"),
    projectStatus: yup.string().required("Required"),
    consultant: yup.string().required("Required"),
  });

  // const validationSchema = yup.object().shape({
  //   ...project,
  // });

  // const {
  //   reset,
  //   register,
  //   formState: { errors },
  //   getValues,
  // } = useForm({
  //   resolver: yupResolver(validationSchema),
  //   defaultValues: useMemo(() => {
  //     return project;
  //   }, [project]),
  // });

  const {
    reset,
    register,
    formState: { errors },
    getValues,
    trigger,
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: useMemo(() => {
      return project;
    }, [project]),
  });

  const onError = (errors, e) => console.log(errors, e);

  useEffect(() => {
    if (project) {
      setProjectLocation(project.projectLocation);
    }
    reset(project);
  }, [project]);

  const pOStatus = watch("poStatus");
  const reasonForLost = watch("reasonForLost");
  const projectLocationWatch = watch("projectLocation");

  const handleProjectLocationChange = async (locationCode) => {
    const pass = await checkProjectDiscountWithLocation(locationCode);

    if (!pass) {
      setProjectLocation((prev) => {
        setValue("projectLocation", prev);
        return prev;
      });
      toast("Discount not allowed");
      return;
    }
    setProjectLocation(locationCode);
    setValue("projectLocation", locationCode);
  };

  // useEffect(() => {
  //   setProjectLocation(projectLocationWatch);
  // }, [projectLocationWatch]);

  // useEffect(() => {
  //   reset(project);
  // }, [projectLocation, projectStatus, poStatus, lostReasons, competitors]);
  //const consultant = watch("consultant");

  //cont
  // useEffect(() => {
  //   console.log(consultant);
  // }, [consultant]);

  //console.log(watch("poStatus"));

  const columns = [
    {
      name: "Model",
      selector: (row) => row.ventilationUnitId,
      sortable: true,
    },
    {
      name: "Filter F/Velocity (m/s)",
      selector: (row) => row.velocity.toFixed(2),
      sortable: true,
    },
    {
      name: "ESP F/Velocity (m/s)",
      selector: (row) => row.espFaceVelocity.toFixed(2),
      sortable: true,
    },
    {
      name: "Area",
      selector: (row) => row.usableArea.toFixed(2),
      sortable: true,
    },
    {
      name: "Fan Model",
      selector: (row) => row.fanModel,
      sortable: true,
    },
    {
      name: "Fan Style",
      selector: (row) => row.fanStyle,
      sortable: true,
    },
    // {
    //   name: "Fan Class",
    //   selector: (row) => row.fanClass,
    //   sortable: true,
    // },
    {
      name: "Volume (m/s)",
      selector: (row) => row.selectionVolume,
      sortable: true,
    },
    {
      name: "Pressure (Pa)",
      selector: (row) => row.selectionPressure.toFixed(2),
      sortable: true,
    },
    {
      name: "Efficiency (%)",
      selector: (row) => row.selectionStaticEfficiency.toFixed(2),
      sortable: true,
    },
    // {
    //   selector: (row) =>
    //     <Button buttonSize="xs" text="select" buttoncolor="primary" onClick={() => selectProjectItem(row.guid)}></Button>,
    //   compact: true,
    // },
  ];

  const [leftTabs, setLeftTabs] = useState([
    {
      id: "ProjectDetails",
      name: "Project Details",
      href: "",
      current: true,
      icon: null,
    },
  ]);

  const saveProjectHandler = async (saveType) => {
    const result = await trigger();
    if (result) {
      saveProject({
        project: getValues(),
        IncrementRevision: saveType == "SaveIncrement",
        userId: user?.id,
      });
    } else {
      console.log(errors);
      toast("Please fill in required fields.");
    }
  };

  const openProjectListModal = () => {
    setIsOpen(true);
  };

  const onRefreshPrice = () => {
    refreshProjectPrice();
  };

  function closeModal() {
    setIsOpen(false);
    setDiscountModalIsOpen(false);
    setCustmItemModalIsOpen(false);
  }

  const openDiscountModal = () => {
    //reset(project);
    setProjectDiscount(project.discount);
    setSalesOfficeMargin(project.salesMargin);
    setDiscountModalIsOpen(true);
  };
  const openCustomItemModal = () => {
    setCustmItemModalIsOpen(true);
  };

  const onAddCustomItem = () => {
    addCustomItem({
      model: customModal,
      price: customPrice,
      quantity: customQty,
    });
    closeModal();
  };

  const [selectedPrintOptions, setSelectedPrintOptions] = useState([
    "Summary",
    "Technical",
    "Drawing",
  ]);

  const printOptions = () => {
    let options = [];
    options.push({ text: "Summary", value: "Summary" });
    options.push({ text: "Technical Data Sheet", value: "Technical" });
    options.push({ text: "Drawings", value: "Drawing" });
    options.push({
      text: "SP Price Breakdown",
      value: "SellingPriceBreakdown",
    });
    if (user?.userGroupId == 2)
      options.push({
        text: "Cost Price Breakdown",
        value: "CostPriceBreakdown",
      });

    return options;
  };

  const onPrintClick = () => {
    setPrintOptionModalIsOpen(true);
  };

  const onPrintOptionsChanged = (checked, value) => {
    setSelectedPrintOptions((oldValues) => {
      if (checked) {
        oldValues.push(value);
        return [...oldValues];
      } else {
        return [...oldValues.filter((i) => i != value)];
      }
    });
  };

  const projectDiscountStyle = {
    content: {
      // border: '0',
      // borderRadius: '4px',
      // bottom: 'auto',
      height: "30%", // set height
      // left: '50%',
      // padding: '2rem',
      // position: 'fixed',
      // right: 'auto',
      // top: '50%', // start from center
      // transform: 'translate(-50%,-' + offsetPx + ')', // adjust top "up" based on height
      width: "50%",
      margin: "auto",
      // maxWidth: '40rem'
    },
  };
  const customItemStyle = {
    content: {
      // border: '0',
      // borderRadius: '4px',
      // bottom: 'auto',
      height: "40%", // set height
      // left: '50%',
      // padding: '2rem',
      // position: 'fixed',
      // right: 'auto',
      // top: '50%', // start from center
      // transform: 'translate(-50%,-' + offsetPx + ')', // adjust top "up" based on height
      width: "50%",
      margin: "auto",
      // maxWidth: '40rem'
    },
  };

  const date = new Date();
  const defaultValue = date.toLocaleDateString("en-CA");

  return (
    <form>
      <div className="grid md:grid-cols-4 sm:grid-cols-1 gap-2">
        <div className="col-span-1">
          <div className="overflow-hidden rounded-lg bg-white shadow mb-3">
            <div className="rounded-lg bg-white col-span-1">
              <Tabs
                buttons={
                  <TabButton isSelected={true}>Project Details</TabButton>
                }
              >
                <div className="pt-0 overflow-hidden transition-[max-height] duration-200 ease-in">
                  <div className="py-1 sm:px-3">
                    <Input
                      size="sm"
                      elementtype="input"
                      label="Quotation"
                      name="quotation"
                      id="quotation"
                      disabled
                      type="text"
                      readonly
                      {...register("quotationNumber")}
                      error={errors.title}
                    />
                  </div>
                  <div className="py-1 sm:px-3">
                    <Input
                      size="sm"
                      elementtype="input"
                      label="MEP Contractor"
                      name="mepContractor"
                      id="mepContractor"
                      type="text"
                      {...register("mepContractor")}
                      error={errors.title}
                    />
                  </div>
                  <div className="py-1 sm:px-3">
                    <Input
                      size="sm"
                      elementtype="input"
                      label="Address"
                      name="address"
                      id="address"
                      type="text"
                      {...register("address")}
                      error={errors.title}
                    />
                  </div>

                  <div className="py-1 sm:px-3">
                    <Input
                      elementtype="input"
                      label="Contact Person"
                      name="contactPerson"
                      id="contactPerson"
                      type="text"
                      {...register("contactPerson", { required: true })}
                      error={errors.contactPerson}
                    />
                    {/* {errors.contactPerson && (
                      <span className="text-red-500 text-xs">
                        {errors.contactPerson.message}
                      </span>
                    )} */}
                  </div>

                  <div className="py-1 sm:px-3">
                    <Input
                      elementtype="input"
                      label="Email"
                      name="email"
                      id="client"
                      type="text"
                      {...register("email")}
                    />
                  </div>

                  <div className="py-1 sm:px-3">
                    <Input
                      elementtype="input"
                      label="Contact No. (required)"
                      name="contact"
                      id="contact"
                      type="text"
                      {...register("contact", { required: true })}
                      error={errors.contact}
                    />
                    {errors.contact && (
                      <span className="text-red-500 text-xs">
                        {errors.contact.message}
                      </span>
                    )}
                  </div>

                  <div className="py-1 sm:px-3">
                    {/* <input
                      type="date"
                      onChange={handleChange}
                      ref={dateInputRef}
                    />{" "} */}
                    <Input
                      elementtype="input"
                      label="Project Date"
                      name="projectDate"
                      id="projectDate"
                      type="date"
                      defaultValue={defaultValue}
                      {...register("projectDate")}
                    />
                  </div>

                  <div className="py-1 sm:px-3">
                    <Input
                      elementtype="input"
                      label="Project Name (required)"
                      name="projectName"
                      id="projectName"
                      type="text"
                      {...register("name", { required: true })}
                      error={errors.name}
                    />
                    {errors.name && (
                      <span className="text-red-500 text-xs">
                        {errors.name.message}
                      </span>
                    )}
                  </div>
                  {/* <div className="py-1 sm:px-3">
                    <Input
                      elementtype="input"
                      label="Reference"
                      name="reference"
                      id="reference"
                      type="text"
                      {...register("reference")}
                    />
                  </div> */}
                  {/* <div className="py-1 sm:px-3">
                <Input elementtype="input" label="MEP Contractor" name="mepContractor" id="mepContractor" type="text" {...register("mepContractor")} />
              </div> */}

                  <div className="py-1 sm:px-3">
                    <Input
                      options={projectLocations.map(function (pi) {
                        return { value: pi.locationCode, data: pi.location };
                      })}
                      elementtype="select"
                      addBlankOption={true}
                      label="Project Location (required)"
                      name="projectLocation"
                      id="projectLocation"
                      type="text"
                      //value={projectLocation}
                      {...register(
                        "projectLocation",
                        {
                          onChange: (e) =>
                            handleProjectLocationChange(e.target.value),
                        },
                        { required: true }
                      )}
                      error={errors.projectLocation}
                      // onChange={(e) =>
                      //   handleProjectLocationChange(e.target.value)
                      // }
                    />

                    {errors.projectLocation && (
                      <span className="text-red-500 text-xs">
                        {errors.projectLocation.message}
                      </span>
                    )}
                  </div>

                  <div className="py-1 sm:px-3">
                    <Input
                      options={cities.map(function (pi) {
                        return { value: pi.city1, data: pi.city1 };
                      })}
                      elementtype="combobox"
                      addBlankOption={true}
                      label="City"
                      name="city"
                      id="city"
                      type="text"
                      listId="cityList"
                      {...register("city")}
                    />
                  </div>

                  <div className="py-1 sm:px-3">
                    <Input
                      options={projectStatus.map(function (pi) {
                        return { value: pi.id, data: pi.description };
                      })}
                      elementtype="select"
                      addBlankOption={true}
                      label="Project Status (required)"
                      name="projectStatus"
                      id="projectStatus"
                      type="text"
                      {...register("projectStatus", { required: true })}
                      error={errors.projectStatus}
                    />
                    {errors.projectStatus && (
                      <span className="text-red-500 text-xs">
                        {errors.projectStatus.message}
                      </span>
                    )}
                  </div>
                  <div className="py-1 sm:px-3">
                    <Input
                      options={poStatus.map(function (pi) {
                        return { value: pi.id, data: pi.description };
                      })}
                      elementtype="select"
                      addBlankOption={true}
                      label="PO Status"
                      name="poStatus"
                      id="poStatus"
                      type="text"
                      {...register("poStatus")}
                    />
                  </div>
                  {pOStatus == "LOST" && (
                    <div className="py-1 sm:px-3">
                      <Input
                        options={lostReasons.map(function (pi) {
                          return { value: pi.id, data: pi.description };
                        })}
                        elementtype="select"
                        addBlankOption={true}
                        label="Reason For Lost"
                        name="reasonForLost"
                        id="reasonForLost"
                        type="text"
                        {...register("reasonForLost")}
                      />
                    </div>
                  )}
                  {reasonForLost == "LOST TO COMPETITION" ||
                    (true && (
                      <div className="py-1 sm:px-3">
                        <Input
                          options={competitors.map(function (pi) {
                            return { value: pi.id, data: pi.description };
                          })}
                          elementtype="select"
                          addBlankOption={true}
                          label="Competitor"
                          name="competitor"
                          id="competitor"
                          type="text"
                          {...register("lostCompetitor")}
                        />
                      </div>
                    ))}

                  <div className="py-1 sm:px-3">
                    <Input
                      elementtype="input"
                      label="Client"
                      name="client"
                      id="client"
                      type="text"
                      {...register("client")}
                    />
                  </div>
                  <div className="py-1 sm:px-3">
                    <Input
                      options={consultants.map(function (pi) {
                        return { value: pi.consultant1, data: pi.consultant1 };
                      })}
                      elementtype="combobox"
                      addBlankOption={true}
                      label="Consultant (required)"
                      name="consultant"
                      id="consultant"
                      type="text"
                      listId="consultantList"
                      {...register("consultant", { required: true })}
                      error={errors.consultant}
                    />
                    {errors.consultant && (
                      <span className="text-red-500 text-xs">
                        {errors.consultant.message}
                      </span>
                    )}
                  </div>
                  <div className="py-1 sm:px-3">
                    <Input
                      elementtype="input"
                      label="Main Contractor"
                      name="mainContractor"
                      id="mainContractor"
                      type="text"
                      {...register("mainContractor")}
                    />
                  </div>
                  {/* <div className="py-1 sm:px-3">
                <Input
                  options={serviceTypes.map(function (pi) {
                    return { value: pi.id, data: pi.description }
                  })}
                  elementtype="select"
                  addBlankOption={true}
                  label="Service Type"
                  name="serviceType"
                  id="serviceType"
                  type="text"
                  {...register("serviceType")}
                />
              </div>
              <div className="py-1 sm:px-3">
                <Input elementtype="input" label="Notes" name="notes" id="notes" type="text" {...register("notes")} />
              </div> */}
                </div>
              </Tabs>
            </div>
          </div>
        </div>
        <div className="col-span-3 overflow-hidden rounded-lg bg-white shadow text-right">
          <ProjectHeader
            saveProject={saveProjectHandler}
            getProjects={openProjectListModal}
            onNewProject={handleNewProject}
            onPrintSummary={onPrintSummary}
            onPrint={onPrint}
            printing={printing}
            openDiscountModal={openDiscountModal}
            openCustomItemModal={openCustomItemModal}
            onPrintClick={onPrintClick}
            onRefreshPrice={onRefreshPrice}
          ></ProjectHeader>

          <ProjectItemsGrid
            items={projectItemsSummary?.projectResultItems}
            totalQuantity={projectItemsSummary?.totalQuantity}
            totalPrice={projectItemsSummary?.totalPriceText}
          ></ProjectItemsGrid>

          {/* <ItemGrid
          columns={columns}
          data={projectItems}
          // conditionalRowStyles={conditionalRowStyles}
          // progressPending={resultsLoading}
          // selectableRows
          // onSelectedRowsChange={selectedRowChangedHandler}
          pagination
        /> */}
        </div>

        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Project List Modal"
        >
          <ProjectLists
            projectLists={projectLists}
            onOpenProject={handleOpenProject}
            user={user}
          ></ProjectLists>
        </Modal>

        <Modal
          shouldCloseOnOverlayClick={true}
          style={projectDiscountStyle}
          isOpen={discountModalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Discount Modal"
        >
          <ProjectDiscount
            closeDiscount={() => closeModal()}
            projectDiscount={projectDiscount}
            salesOfficeMargin={salesOfficeMargin}
            setProjectDiscount={setProjectDiscount}
            setSalesOfficeMargin={setSalesOfficeMargin}
            updateProjectDiscounts={updateProjectDiscounts}
            discountLimit={user.userGroupId == 2 ? 0 : discountLimit}
          ></ProjectDiscount>
        </Modal>
        <Modal
          shouldCloseOnOverlayClick={true}
          style={customItemStyle}
          isOpen={customItemModalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Discount Modal"
        >
          <CustomItem
            closeModal={() => closeModal()}
            customModal={customModal}
            setCustomModal={setCustomModal}
            customPrice={customPrice}
            setCustomPrice={setCustomPrice}
            customQty={customQty}
            setCustomQty={setCustomQty}
            onAddCustomItem={onAddCustomItem}
          ></CustomItem>
        </Modal>

        <NewModal
          open={printOptionModalIsOpen}
          setOpen={setPrintOptionModalIsOpen}
          title="Print Options"
        >
          <PrintOptions
            printOptions={printOptions()}
            selectedPrintOptions={selectedPrintOptions}
            setSelectedPrintOptions={setSelectedPrintOptions}
            onChangedHandler={onPrintOptionsChanged}
            onPrint={onPrint}
          ></PrintOptions>
        </NewModal>
      </div>
    </form>
  );
};

export default Project;
