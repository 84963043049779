import { Html } from "@react-three/drei";
import { Fragment, useEffect, useState } from "react";
import { Vector3 } from "three";
import { useStore } from "../Store/zustandStore";
import { calculateBounds, calculateDimensions, fromMM, getFramesFromScene, toMM } from "../Utils/frameUtils";
import { useThree } from "@react-three/fiber";

export default function Dimensions({ frames, enabled, dimensionSide }) {
  const [frameThickness] = useStore((state) => [state.frameThickness]);
  const sectionIds = !frames ? [] : [...new Set(frames.filter((f) => f.sectionId != null).map((f) => f.sectionId))];

  return enabled && frames?.length > 0 ? (
    <group userData={{ type: "dimensions" }}>
      <Dimension frames={frames} offsetHorizontal={0.25} offsetVertical={0.5} frameThickness={frameThickness} dimensionSide={dimensionSide} isTotal={true}></Dimension>
      {frames
        .filter((f) => !f.sectionId)
        .map((f) => {
          return <Dimension key={f.id} frames={[f]} offsetHorizontal={0.25} offsetVertical={0.25} frameThickness={frameThickness} dimensionSide={dimensionSide}></Dimension>;
        })}
      {sectionIds.map((sectionId) => {
        return (
          <Dimension
            key={sectionId}
            frames={frames.filter((f) => f.sectionId == sectionId)}
            offsetHorizontal={0.25}
            offsetVertical={0.25}
            frameThickness={frameThickness}
            dimensionSide={dimensionSide}
          ></Dimension>
        );
      })}
    </group>
  ) : null;
}

function Dimension({ frames, frameThickness, dimensionSide, isTotal = false, offsetHorizontal = 0.25, offsetVertical = 0.25 }) {
  const [positionAndSizes, setPositionAndSizes] = useState();
  const multiplier = 200;

  useEffect(() => {
    if (!frames || frames.length == 0) return;
    setPositionAndSizes(calculateDimensions(frames, frameThickness, offsetHorizontal, offsetVertical, dimensionSide, isTotal));
  }, [frames, dimensionSide]);

  return (
    <group userData={{ type: "dimensions" }}>
      {positionAndSizes
        ?.filter((d) => d.lengthPosition || d.heightPosition || d.widthPosition)
        .map((positionAndSize, i) => (
          <Fragment key={`dimension-${i}`}>
            {positionAndSize.showLength && (
              <>
                <DimensionObjects position={positionAndSize.lengthPosition} rotation={[0, 0, Math.PI / 2]} length={positionAndSize.lengthSize} />
                <Html position={positionAndSize.lengthPosition} center>
                  <div
                    className="dimension-length"
                    style={{
                      width: positionAndSize.lengthSize * multiplier,
                    }}
                  >
                    <div>
                      <span>{positionAndSize.lengthSizeMM}</span>
                    </div>
                  </div>
                </Html>
              </>
            )}
            {positionAndSize.showHeight && (
              <>
                <DimensionObjects position={[ positionAndSize.heightPosition[0] * 1.1, positionAndSize.heightPosition[1],positionAndSize.heightPosition[2]]} rotation={[0, 0, 0]} length={positionAndSize.heightSize} />
                <Html position={[ positionAndSize.heightPosition[0] * 1.1, positionAndSize.heightPosition[1],positionAndSize.heightPosition[2]]} center>
                  <div className="dimension-height" style={{ height: positionAndSize.heightSize * multiplier }}>
                    <div>
                      <span>{positionAndSize.heightSizeMM}</span>
                    </div>
                  </div>
                </Html>
                {i == 0 && (
                  <>
                    <DimensionObjects
                      position={[positionAndSize.heightPosition[0], positionAndSize.heightPosition[1] - fromMM(50), positionAndSize.heightPosition[2]]}
                      rotation={[0, 0, 0]}
                      length={positionAndSize.heightSize + fromMM(100)}
                    />
                    <Html position={positionAndSize.heightPosition} center>
                      <div className="dimension-height" style={{ height: (positionAndSize.heightSize + fromMM(100)) * multiplier }}>
                        <div>
                          <span>{positionAndSize.heightSizeMM + 100}</span>
                        </div>
                      </div>
                    </Html>
                  </>
                )}
              </>
            )}
            {positionAndSize.showWidth && (
              <>
                <DimensionObjects position={positionAndSize.widthPosition} rotation={[Math.PI / 2, 0, 0]} length={positionAndSize.widthSize} />
                <Html position={positionAndSize.widthPosition} rotation-y={-Math.PI / 2} center>
                  <div className="dimension-width" style={{ width: positionAndSize.widthSize * multiplier, fontSize: "14px" }}>
                    <div>
                      <span>{positionAndSize.widthSizeMM}</span>
                    </div>
                  </div>
                </Html>
              </>
            )}
          </Fragment>
        ))}
    </group>
  );
}

function DimensionObjects({ position, rotation, length }) {
  const dimensionRadius = 0.005;
  return (
    <group position={position} rotation={rotation}>
      <mesh>
        <cylinderGeometry args={[dimensionRadius, dimensionRadius, length]} />
        <meshStandardMaterial color={"Black"} />
      </mesh>
      <mesh position={[0, -length / 2, 0]} rotation={[0, 0, -Math.PI / 2]}>
        <cylinderGeometry args={[dimensionRadius, dimensionRadius, 0.1]} />
        <meshStandardMaterial color={"Black"} />
      </mesh>
      <mesh position={[0, length / 2, 0]} rotation={[0, 0, -Math.PI / 2]}>
        <cylinderGeometry args={[dimensionRadius, dimensionRadius, 0.1]} />
        <meshStandardMaterial color={"Black"} />
      </mesh>
    </group>
  );
}
