import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
  getFilteredRowModel,
  getPaginationRowModel

} from "@tanstack/react-table";
import ItemGrid from "../shared/ItemGrid";
import {
  TrashIcon,
  CheckCircleIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import { useAdmin } from "../../hooks/useQueries";
import Button from "../shared/Button";
import { Modal } from "react-modal-global";
import PopupModal from "../shared/Modal";
import Input from "../shared/Input";
import { useMemo } from "react";
import { useState, useEffect } from "react";
import { default as Select, components } from "react-select";
import { useProject } from "../../hooks/useQueries";
import axios from "../../axios";
import { toast } from "react-toastify";
import DropdownButton from "../shared/DropdownButton";

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};
const MultiValueContainer = ({ selectProps, data }) => {
  const values = selectProps.value;
  if (values && data.value != "selectAll") {
    return values[values.length - 1]?.label === data.label
      ? data.label
      : data.label + ", ";
  } else return "";
};

export default function UserGrid() {
  const {
    userList,
    setUserList,
    approveUser,
    deleteUser,
    revokeUser,
    updateUserByAdmin,
  } = useAdmin();

  const { projectLocations } = useProject();

  const [sorting, setSorting] = useState([
    {
      id: "createdDate",
      desc: false, // sort by name in descending order by default
    },
  ]);

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const [columnFilters, setColumnFilters] = useState();

  function onApproveHandler(id, email) {
    Modal.open(PopupModal, {
      title: "APPROVE",
      weak: true,
      body: [
        `Are you sure you want to approve the user registration for ${email}?`,
      ],
      callback: () => {
        approveUser({ id: id });
      },
      closable: false,
    });
  }
  function onRevokeHandler(id, email) {
    Modal.open(PopupModal, {
      title: "REVOKE",
      weak: true,
      body: [`Are you sure you want to revoke the user account for ${email}?`],
      callback: () => {
        revokeUser({ id: id });
      },
      closable: false,
    });
  }
  function onDeleteHandler(id, email) {
    Modal.open(PopupModal, {
      title: "DELETE",
      weak: true,
      body: [`Are you sure you want to delete the user account for ${email}?`],
      callback: () => {
        deleteUser({ id: id });
      },
      closable: false,
    });
  }

  function onUserDetailsChangedHandler(id, property, value) {
    setUserList(
      userList.map((u) => {
        if (u.id == id) {
          return { ...u, [property]: value };
        } else {
          return u;
        }
      })
    );
    updateUserByAdmin({
      id: id,
      showCostPrice: property == "showCostPrice" ? value : null,
      showSalePrice: property == "showSalePrice" ? value : null,
      locations: property == "locations" ? value : null,
    });
  }

  const columnHelper = createColumnHelper();

  const Cell = (getValue, rowIndex, columnId, table, id) => {
    // const initialValue = getValue();

    const initialValue = getValue();
    // We need to keep and update the state of the cell normally
    const [value, setValue] = useState([]);

    const handleChange = async (val, action) => {
      const selectAll =
        false || val.findIndex((v) => v.value == "selectAll") > -1;

      const deselectAll =
        false ||
        (!selectAll && value.findIndex((v) => v.value == "selectAll") > -1);

      const userLocations = selectAll
        ? projectLocations.map((l) => l.locationCode)
        : deselectAll
        ? []
        : val.map((v) => { if(v.value != "selectAll"){return v.value}});

      const updateUser = await axios
        .post("/User/update-user-by-admin", {
          id: id,
          locations: userLocations,
        })
        .then(() => {
          toast("User successfully updated.");
        });

      setValue(
        selectAll
          ? [{ value: "selectAll", label: "Select All" }].concat(
              projectLocations.map((pl) => ({
                value: pl.locationCode,
                label: pl.location,
              }))
            )
          : deselectAll
          ? []
          : val
      );
    };

    const options =
      projectLocations &&
      [{ value: "selectAll", label: "Select All" }].concat(
        projectLocations.map((pl) => ({
          value: pl.locationCode,
          label: pl.location,
        }))
      );

    useEffect(() => {
      setValue(
        initialValue.flatMap((u, i) => {
          const locations = [];
          if (i == 0 && initialValue.length == projectLocations.length) {
            locations.push({ value: "selectAll", label: "Select All" });
          }
          const location = projectLocations.find((l) => l.locationCode == u);
          if (location) {
            locations.push({
              value: location.locationCode,
              label: location.location,
            });
            return locations;
          }
        })
      );
    }, [initialValue]);

    return (
      <Select
        options={options}
        isMulti={true}
        onChange={handleChange}
        onSelectResetsInput={false}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        isClearable={false}
        // controlShouldRenderValue={false}
        value={value}
        classNamePrefix="location-select"
        components={{
          Option,
          MultiValueContainer,
        }}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            width: "300px",
            height: "40px",
            //borderColor: state.isFocused ? 'grey' : 'red',
          }),
        }}
        // minMenuHeight={300}
        // maxMenuHeight={100}
        menuPlacement="auto"
      />
    );

    //   const [isOpen, setIsOpen] = useState(false);
    //  // const [value, setValue] = useState<StateOption | null>();

    //   return (
    //     <Dropdown
    //       isOpen={isOpen}
    //       onClose={() => setIsOpen(false)}
    //       // target={
    //       //   <Button
    //       //    // iconAfter={<ChevronDown />}
    //       //     onClick={() => setIsOpen((prev) => !prev)}
    //       //     isSelected={isOpen}
    //       //   >
    //       //     {value ? `State: ${value.label}` : 'Select a State'}
    //       //   </Button>
    //       // }
    //     >
    //       <Select
    //         autoFocus
    //         backspaceRemovesValue={false}
    //         //components={{ DropdownIndicator, IndicatorSeparator: null }}
    //         controlShouldRenderValue={false}
    //         hideSelectedOptions={false}
    //         isClearable={false}
    //         menuIsOpen
    //         onChange={(newValue) => {
    //           setValue(newValue);
    //           setIsOpen(false);
    //         }}
    //         options={options}
    //         placeholder="Search..."
    //         //styles={selectStyles}
    //         tabSelectsValue={false}
    //         value={value}
    //       />
    //     </Dropdown>
    //   )
  };

  const actionChangedHandler = (actionType, id, email) => {
    if (actionType == "revoke") {
      onRevokeHandler(id, email);
    } else if (actionType == "delete") {
      onDeleteHandler(id, email);
    } else if (actionType == "approve") {
      onApproveHandler(id, email);
    }
  };

  const columns = useMemo(() => [
    columnHelper.accessor("firstName", {
      id: "firstName",
      // cell: (info) => info.getValue(),
      header: "First Name",
      //footer: (info) => info.column.id,
    }),
    columnHelper.accessor("lastName", {
      id: "LastName",
      // cell: (info) => <i>{info.getValue()}</i>,
      header: "Last Name",
      //footer: (info) => info.column.id,
    }),
    columnHelper.accessor("company", {
      id: "company",
      // cell: (info) => <i>{info.getValue()}</i>,
      header: "Company",
      //footer: (info) => info.column.id,
    }),
    columnHelper.accessor("email", {
      id: "email",
      // cell: (info) => <i>{info.getValue()}</i>,
      header: "Email",
      //footer: (info) => info.column.id,
    }),
    columnHelper.accessor("mobileNumber", {
      id: "contactNumber",
      // cell: (info) => <i>{info.getValue()}</i>,
      header: "Mobile No.",
      //footer: (info) => info.column.id,
      enableColumnFilter: false,
    }),
    columnHelper.accessor("locations", {
      id: "locations",
      header: "Locations",
      width: "500px",
      cell: ({ getValue, row, column: { id }, table }) => {
        return Cell(getValue, row.index, id, table, row.original.id);
      },
      enableColumnFilter: false
    }),
    columnHelper.accessor("showCostPrice", {
      id: "showCostPrice",
      cell: (props) => (
        <Input
          elementtype="switch"
          enabled={props.getValue() || props?.row?.original?.userGroupId == 2}
          onChange={(e) =>
            onUserDetailsChangedHandler(
              props?.row?.original?.id,
              "showCostPrice",
              e
            )
          }
          disabled={props?.row?.original?.userGroupId == 2}
        />
      ),
      header: "Show CP",
      enableColumnFilter: false,
      //footer: (info) => info.column.id,
    }),
    columnHelper.accessor("showSalePrice", {
      id: "showSellingPrice",
      cell: (props) => (
        <Input
          elementtype="switch"
          enabled={props.getValue() || props?.row?.original?.userGroupId == 2}
          onChange={(e) =>
            onUserDetailsChangedHandler(
              props?.row?.original?.id,
              "showSalePrice",
              e
            )
          }
          disabled={props?.row?.original?.userGroupId == 2}
        />
      ),
      header: "Show SP",
      enableColumnFilter: false,
      //footer: (info) => info.column.id,
    }),
    columnHelper.accessor("", {
      id: "verified",
      cell: (props) =>
        props?.row?.original?.isVerified ? (
          <CheckCircleIcon
            className="h-5 w-5 m-auto text-green-400"
            aria-hidden="true"
          />
        ) : (
          <XCircleIcon
            className="h-5 w-5 m-auto text-red-400"
            aria-hidden="true"
          />
        ),
      header: <div className="text-center">Verified</div>,
      enableColumnFilter: false
      //footer: (info) => info.column.id,
    }),
    // columnHelper.accessor("", {
    //   id: "approve",
    //   cell: (props) =>
    //     props?.row?.original?.approved ? (
    //       <Button
    //         text="Revoke"
    //         buttonSize="xs"
    //         buttoncolor={"danger"}
    //         cssclass="!rounded-md mr-3"
    //         onClick={() =>
    //           onRevokeHandler(
    //             props?.row?.original?.id,
    //             props?.row?.original?.email
    //           )
    //         }
    //       />
    //     ) : props?.row?.original?.verified ? (
    //       <Button
    //         text="Approve"
    //         buttonSize="xs"
    //         buttoncolor={"primary"}
    //         cssclass="!rounded-md mr-3"
    //         onClick={() =>
    //           onApproveHandler(
    //             props?.row?.original?.id,
    //             props?.row?.original?.email
    //           )
    //         }
    //       />
    //     ) : (
    //       ""
    //     ),
    //   //header: "Approve",
    //   //footer: (info) => info.column.id,
    // }),
    columnHelper.accessor("", {
      id: "actions",
      cell: (props) => (
        <DropdownButton
          options={[
            {
              value: props?.row?.original?.verified ? "revoke" : "approve",
              text: props?.row?.original?.verified ? "Revoke" : "Approve",
            },
            { value: "delete", text: "Delete" },
          ]}
          text="Actions"
          buttonSize="xs"
          buttoncolor={"primary"}
          cssclass="!rounded-md mr-3"
          onClick={(val) =>
            actionChangedHandler(
              val,
              props?.row?.original?.id,
              props?.row?.original?.email
            )
          }
        />
        // <TrashIcon
        //   className="h-5 w-5 text-red-600 cursor-pointer"
        //   onClick={() =>
        //     onDeleteHandler(
        //       props?.row?.original?.id,
        //       props?.row?.original?.email
        //     )
        //   }
        // />
      ),
      header: "",
      enableColumnFilter: false,
      //footer: (info) => info.column.id,
    }),
  ]);

  return <ItemGrid columns={columns} items={userList}
  tableOptions={
    {
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(), //client side filtering
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      sorting,
      pagination,
      columnFilters
    },
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
     onColumnFiltersChange: setColumnFilters,
  }
}
  ></ItemGrid>;
}
