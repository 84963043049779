import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useUser } from "../hooks/useQueries";
import { NavLink, useSearchParams, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import Input from "../components/shared/Input";
import { useEffect } from "react";

export default function ResetPassword() {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const navigate = useNavigate();
  const { resetPassword } = useUser();
  const validationSchema = yup.object().shape({
    password: yup.string().required("Required"),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), null],"Password must match")
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: { password: "", resetPassword: "" },
  });

  useEffect(() => {
    if (token == null) {
      navigate("/login");
    }
  }, []);

  return (
    <form
      onSubmit={handleSubmit((data) => {
        data.token = token;
        resetPassword(data);
      })}
    >
      <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="mt-6 text-center text-2xl font-medium leading-9 tracking-tight text-gray-900">
            Reset Password
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
          <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12 space-y-6">
            <Input
              label="Password"
              elementtype="input"
              type="password"
              name="password"
              {...register("password")}
              error={errors.password}
            />
            {errors.password && (
              <span className="text-red-500 text-xs">
                {errors.password.message}
              </span>
            )}
            <Input
              label="Confirm Password"
              elementtype="input"
              type="password"
              name="confirmPassword"
              {...register("confirmPassword")}
              error={errors.confirmPassword}
            />
            {errors.confirmPassword && (
              <span className="text-red-500 text-xs">
                {errors.confirmPassword.message}
              </span>
            )}

            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-primary px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-primary-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-400"
              >
                Reset Password
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
