import { isEmpty } from "lodash";
import { redirect } from "react-router-dom";

export const isAuthenticated = () => {
    if (isEmpty(localStorage.getItem("user"))) {
      return redirect("/login");
    }
  };
  
export const isAdmin = () => {
    var user = JSON.parse(localStorage.getItem("user"));
    if (user == null || user.userGroup != 2) {
      return redirect("/selection");
    }
  };
  
export const tokenLoader = () => {
    var user = JSON.parse(localStorage.getItem("user"));
    if (user == null) {
      return null;
    }
    return user.jwtToken;
  }