
export default function frameReducer(state, action) {
  switch (action.type) {
    case "FRAMES":
      return [...action.value];
    case "FRAME":
      const value = action.value;
      const ids = action.id;
      return state.map((f) => {
        if (ids.includes(f.id)) {
          return {
            ...f,
            ...Object.assign(
              {},
              ...Object.keys(value).map((k) => {
                return {
                  [k]: typeof value[k] === "object" ? { ...f[k], ...value[k] } : value[k],
                };
              })
            ),
          };
        } else {
          return f;
        }
      });

    case "DELETE_FRAME":
      return state.filter((f) => !action.id.includes(f.id));

    default:
      return state;
  }
  
}
