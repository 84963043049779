import Selection from "./views/Selection";
import PageNotFound from "./components/PageNotFound";
import Project from "./views/Project";
import Login from "./views/Login";
import Register from "./views/Register";
import UserProfile from "./views/UserProfile";
import { createBrowserRouter } from "react-router-dom";
import Layout from "./components/layout/Layout";
import Error from "./components/Error";
import Users from "./components/admin/Users";
import ForgotPassword from "./views/ForgotPassword";
import ResetPassword from "./views/ResetPassword";
import { isAuthenticated, tokenLoader } from "./util/auth";

export const Router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <Error />,
    id: "root",
    loader: tokenLoader,
    children: [
      {
        index: true,
        path: "selection",
        element: <Selection />,
        loader: isAuthenticated,
      },
      {
        path: "project",
        element: <Project />,
        loader: isAuthenticated,
      },
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "verify-email",

        element: <Login />,
      },
      {
        path: "register",
        element: <Register />,
      },
      {
        path: "forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "reset-password",
        element: <ResetPassword />,
      },
      {
        path: "*",
        element: <PageNotFound />,
      },
    ],
  },
  {
    path: "/user",
    element: <Layout />,
    errorElement: <Error />,
    children: [
      {
        path: "profile",
        element: <UserProfile />,
        loader: isAuthenticated,
      },
    ],
  },
  {
    path: "/admin",
    element: <Layout />,
    errorElement: <Error />,
    children: [
      {
        path: "users",
        element: <Users />,
        loader: isAuthenticated,
      },
    ],
  },
]);
