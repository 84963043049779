export default function Error() {
  return (
    <div className="flex justify-center items-center h-screen mt-[-6rem]">
      <div className="text-center">
        <h2 className="text-3xl">Something went wrong</h2>
        <p className="mt-3">Please try again</p>
      </div>
    </div>
  );
}
