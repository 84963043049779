export default function TechnicalSectionItem({ technicalSectionItem }) {
  return (
    <>
      <div className={`col col-span-1 ${technicalSectionItem.keyOptions?.cssClass}`}>
        <span>{technicalSectionItem.key}</span>
      </div>
      <div className={`col col-span-1 ${technicalSectionItem.valueOptions?.cssClass}`}>
        <span>{technicalSectionItem.value}</span>
      </div>
    </>
  );
}
