export default function TechnicalSection(props) {
  return (
    <div className="border">
      <div className="section-header border-t border-gray-200 bg-gray-50 py-2 px-2 font-semibold text-gray-900">
        <h4>{props.heading}</h4>
      </div>
      {props.children}
    </div>
  );
}
