import React, { useEffect, useState } from "react";
import { TOOL_MODE, COMPONENT_ALIGNMENT } from "./Constants";
import { calculateAttached, findFrameWithConnectionId } from "./Utils/frameUtils";
import { useStore } from "./Store/zustandStore";
import Button from "../shared/Button";
import { useAHUDesignerManager } from "../../hooks/useQueries";
import { TrashIcon } from "@heroicons/react/24/outline";

export default function Header({ frames, updateUnit, isOrthographic, setIsOrthographic, autoRotate, setAutoRotate, resetControls }) {
  const [
    toolMode,
    setToolMode,
    selectedUUID,
    showDimensions,
    setShowDimensions,
    frameThickness,
    showPanels,
    setShowPanels,
    panelOpacity,
    setPanelOpacity,
    doorsOpen,
    setDoorsOpen,
    framesAttachedTogether,
    selectedUUIDIsInSection,
  ] = useStore((state) => [
    state.toolMode,
    state.setToolMode,
    state.selectedUUID,
    state.showDimensions,
    state.setShowDimensions,
    state.frameThickness,
    state.showPanels,
    state.setShowPanels,
    state.panelOpacity,
    state.setPanelOpacity,
    state.doorsOpen,
    state.setDoorsOpen,
    state.framesAttachedTogether,
    state.selectedUUIDIsInSection,
  ]);

  const [selectedFrame, setSelectedFrame] = useState(null);

  const [showRemoveSectionBtn, setshowRemoveSectionBtn] = useState(false);

  useEffect(() => {
    setSelectedFrame(frames && selectedUUID ? frames.filter((frame) => selectedUUID.includes(frame.id)) : null);
  }, [selectedUUID, frames]);

  useEffect(() => {
    if (selectedUUID && selectedUUID.length > 0) {
      for (let i = 0; i < selectedUUID.length; i++) {
        let sectionId = frames.find((f) => f.id == selectedUUID[i])?.sectionId;
        if (sectionId != "" && sectionId != null) {
          setshowRemoveSectionBtn(true);
          return;
        }
      }
      setshowRemoveSectionBtn(false);
    }
  }, [selectedUUID]);

  const selectedIds = selectedFrame?.map((f) => f.id);

  const { calculateUnit, deleteComponent, makeSection, removeSection } = new useAHUDesignerManager();

  function recalculateAttached() {
    updateUnit("FRAMES", -1, calculateAttached(frames, frameThickness));
  }

  return (
    <div>
      <div className="relative flex items-center p-2 overflow-x-auto">
        <span className="isolate inline-flex rounded-md shadow-sm">
          <button
            type="button"
            onClick={() => setIsOrthographic(false)}
            className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
          >
            Perspective
          </button>
          <button
            type="button"
            onClick={() => setIsOrthographic(true)}
            className="relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
          >
            Orthographic
          </button>
        </span>

        <span className="ml-3"></span>
        <Button buttonSize="xs" buttoncolor={"primary"} onClick={resetControls} cssclass="!rounded-md">
          Reset View
        </Button>
        <span className="mr-3"></span>

        {/* <div className="flex h-5 items-center">
          <input
            id="autoRotate"
            name="autoRotate"
            type="checkbox"
            className="h-4 w-4 rounded border-gray-300 text-primary-600 focus:ring-primary-500"
            onChange={(e) => setAutoRotate(e.target.checked)}
            defaultChecked={autoRotate}
          />
        </div>
        <div className="ml-3 text-sm">
          <label htmlFor="autoRotate" className="text-xs font-medium text-gray-700">
            Auto rotate
          </label>
          <span id="autoRotate-description" className="text-gray-500">
            <span className="sr-only">Auto rotate </span>
          </span>
        </div> */}

        <div className="flex h-5 items-center ml-3">
          <input
            id="showDimensions"
            name="showDimensions"
            type="checkbox"
            className="h-4 w-4 rounded border-gray-300 text-primary-600 focus:ring-primary-500"
            onChange={(e) => setShowDimensions(e.target.checked)}
            defaultChecked={showDimensions}
          />
        </div>
        <div className="ml-3 text-sm">
          <label htmlFor="showDimensions" className="text-xs font-medium text-gray-700">
            Show dimensions
          </label>
          <span id="showDimensions-description" className="text-gray-500">
            <span className="sr-only">Show dimensions </span>
          </span>
        </div>

        <div className="flex h-5 items-center ml-3">
          <input
            id="showPanels"
            name="showPanels"
            type="checkbox"
            className="h-4 w-4 rounded border-gray-300 text-primary-600 focus:ring-primary-500"
            onChange={(e) => setShowPanels(e.target.checked)}
            defaultChecked={showPanels}
          />
        </div>
        <div className="ml-3 text-sm">
          <label htmlFor="showPanels" className="text-xs font-medium text-gray-700">
            Show panels
          </label>
          <span id="showPanels-description" className="text-gray-500">
            <span className="sr-only">Show panels </span>
          </span>
        </div>

        <div className="ml-3 text-sm">
          <label htmlFor="panelOpacity" className="text-xs font-medium text-gray-700">
            Opacity
          </label>
          <span id="panelOpacity-description" className="text-gray-500">
            <span className="sr-only">Opacity </span>
          </span>
        </div>
        <div className="flex h-5 items-center ml-3">
          <input
            id="panelOpacity"
            name="panelOpacity"
            type="range"
            min={0}
            max={1}
            step={0.1}
            style={{ width: "100px" }}
            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
            onChange={(e) => setPanelOpacity(e.target.value)}
            defaultValue={panelOpacity}
          />
        </div>
        {/* <Button buttonSize="xs" buttoncolor={"primary"} onClick={() => setDoorsOpen(!doorsOpen)}>
          {doorsOpen ? "Close doors" : "Open doors"}
        </Button> */}
        <Button buttonSize="xs" buttoncolor={"primary"} onClick={() =>calculateUnit(true,{})} cssclass="!rounded-md ml-3">
          Calculate
        </Button>
        <Button
          buttonSize="xs"
          buttoncolor={"primary"}
          onClick={() => {
            let newFrames = [...frames];

            // Sort by x

            selectedIds.sort((a, b) => {
              const aFrame = newFrames.find((f) => f.id === a);
              const bFrame = newFrames.find((f) => f.id === b);
              return aFrame.position[0] - bFrame.position[0];
            });

            // Look for connecting frames
            // and check they're connected

            const connectedIds = [];

            function findFramesBetween(frameA, frameB) {
              let connection_right = frameA.connections.find((c) => c.side == COMPONENT_ALIGNMENT.RIGHT);
              let finished = false;
              let errored = false;

              while (!finished) {
                const connected_frame = findFrameWithConnectionId(newFrames, connection_right.attachedTo);

                if (connected_frame.id == frameB.id) {
                  finished = true;
                } else if (!connected_frame.connections.find((c) => c.side == COMPONENT_ALIGNMENT.RIGHT)?.attachedTo) {
                  errored = true;
                  finished = true;
                } else {
                  connectedIds.push(connected_frame.id);
                  connection_right = connected_frame.connections.find((c) => c.side == COMPONENT_ALIGNMENT.RIGHT);
                }
              }

              return !errored;
            }

            const aframe = newFrames.find((f) => f.id === selectedIds[0]);
            const bframe = newFrames.find((f) => f.id === selectedIds[selectedIds.length - 1]);
            if (!findFramesBetween(aframe, bframe)) {
              alert("error gap detected");
              return;
            }

            // Set section id's

            // selectedIds.forEach((id) => {
            //   const frame = newFrames.find((f) => f.id === id);
            //   frame.sectionId = sectionId;
            // });

            // connectedIds.forEach((id) => {
            //   const frame = newFrames.find((f) => f.id === id);
            //   frame.sectionId = sectionId;
            // });

            // Set outer frames only

            // newFrames = toggleHasFrame(false, COMPONENT_ALIGNMENT.LEFT, newFrames, connectedIds, frameThickness);
            // newFrames = toggleHasFrame(false, COMPONENT_ALIGNMENT.RIGHT, newFrames, connectedIds, frameThickness);

            // newFrames = toggleHasFrame(true, COMPONENT_ALIGNMENT.LEFT, newFrames, [selectedIds[0]], frameThickness);
            // newFrames = toggleHasFrame(false, COMPONENT_ALIGNMENT.RIGHT, newFrames, [selectedIds[0]], frameThickness);

            // newFrames = toggleHasFrame(false, COMPONENT_ALIGNMENT.LEFT, newFrames, [selectedIds[selectedIds.length - 1]], frameThickness);
            // newFrames = toggleHasFrame(true, COMPONENT_ALIGNMENT.RIGHT, newFrames, [selectedIds[selectedIds.length - 1]], frameThickness);

            // updateUnit("FRAMES", -1, newFrames);

            // setSelectedUUID([...selectedIds, ...connectedIds]);

            makeSection([...selectedIds, ...connectedIds]);
          }}
          cssclass="!rounded-md ml-3"
        >
          Make Section
        </Button>
        {showRemoveSectionBtn && (
          <Button buttonSize="xs" buttoncolor={"primary"} onClick={removeSection} cssclass="!rounded-md ml-3">
            Remove Section
          </Button>
        )}

        <div className="ml-3 text-sm">
          {/* <label
            htmlFor="showPanels"
            className="text-xs font-medium text-gray-700"
          >
            Show panels
          </label> */}

          <Button buttonSize="xs" buttoncolor={"danger"} onClick={() => deleteComponent()} cssclass="!rounded-md">
            <span id="showPanels-description">
              <a className="floating-btn">
                <TrashIcon className="h-4 w-3" aria-hidden="true"></TrashIcon>
              </a>
            </span>{" "}
          </Button>
        </div>
      </div>
    </div>
  );
}
