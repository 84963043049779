import React from "react";
import { useUser } from "../../hooks/useQueries";
import { ArrowLeftOnRectangleIcon } from "@heroicons/react/24/outline";
import { NavLink } from "react-router-dom";

const LogoutButton = () => {
  const { user, logout } = useUser();

  //   <button onClick={() => logout({ returnTo: window.location.origin })}>
  //   Log Out
  // </button>
  return (
    <div className="flex">
      <div>
        <NavLink to="/user/profile" className="group block w-full flex-shrink-0 cursor-pointer">
          <div className="flex items-center">
            <div>
              <img
                className="inline-block h-9 w-9 rounded-full"
                src={`https://ui-avatars.com/api/?background=0B69C4&color=fff&name=${user?.firstName}+${user?.lastName}&rounded=true&size=48`}
                // src={user.picture}
                alt=""
              />
            </div>
          </div>
        </NavLink>
      </div>
      <div className="ml-1">
        <NavLink to="/user/profile" className="cursor-pointer">
          <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">{user?.name}</p>
          <p className="ml-1 text-xs font-medium text-gray-500 group-hover:text-gray-700">
            View profile
            {/* <a onClick={() => logout({ returnTo: window.location.origin })} className="group block w-full flex-shrink-0"> */}
          </p>
        </NavLink>
        <a onClick={logout} className="group block w-full flex-shrink-0 cursor-pointer">
          <div className="flex items-center mt-1">
            <div>
              <ArrowLeftOnRectangleIcon className="text-gray-500mr-4 h-6 w-6" aria-hidden="true" />
            </div>
            <div className="ml-1">
              <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">Logout</p>
            </div>
          </div>
        </a>
      </div>
    </div>
  );
};

export default LogoutButton;
