import { COMPONENT_ALIGNMENT, DOOR_HINGE } from "../../Constants";

export default {
  visible: false,
  length: 250,
  alignment: {
    value: COMPONENT_ALIGNMENT.LEFT,
    options: [
      { text: "Left", value: COMPONENT_ALIGNMENT.LEFT },
      { text: "Right", value: COMPONENT_ALIGNMENT.RIGHT },
    ],
  },
  louvered: false,
  hinge: {
    value: DOOR_HINGE.LEFT,
    options: [
      { text: "Left", value: DOOR_HINGE.LEFT },
      { text: "Right", value: DOOR_HINGE.RIGHT },
      { text: "Lift", value: DOOR_HINGE.LIFT },
    ],
  },
};
