import { createColumnHelper } from "@tanstack/react-table";
import ItemGrid from "../shared/ItemGrid";
import {
  FolderOpenIcon,
  TrashIcon,
  DocumentDuplicateIcon,
} from "@heroicons/react/24/outline";
import { useConfigurator, useProject, useUser } from "../../hooks/useQueries";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-modal-global";
import PopupModal from "../shared/Modal";
import Input from "../shared/Input";
import { find, round } from "lodash";
import { useState, useEffect } from "react";
import axios from "../../axios";
import { generalStore } from "../../stores/userStore";

export default function ProjectItemsGrid({ items, totalQuantity, totalPrice }) {
  const { user } = useUser();

  const [hideLoading, setHideLoading] = generalStore((state) => [
    state.hideLoading,
    state.setHideLoading,
  ]);

  const { selectProjectVentilationUnit } = useConfigurator();
  const { deleteProjectItem, cloneProjectItem, updateProjectItemField } =
    useProject();

  const navigate = useNavigate();

  const columnHelper = createColumnHelper();

  const Cell = (getValue, rowIndex, columnId, table, guid, inputType) => {
    // const initialValue = getValue();

    var resultItem = find(items, (i) => i.guid == guid);
    const initialValue = resultItem ? resultItem[columnId] : getValue();

    // We need to keep and update the state of the cell normally
    const [value, setValue] = useState();

    // if (resultItem) {
    //   setValue(resultItem[columnId]);
    // }

    // When the input is blurred, we'll call our table meta's updateData function
    const onBlur = async () => {
      table.options.meta?.updateData(rowIndex, columnId, value);

      var resultItem = find(items, (i) => i.guid == guid);

      if (resultItem) {
        resultItem[columnId] = value;
        await updateProjectItemField({
          guid: resultItem.guid,
          quantity: resultItem.quantity,
          reference: resultItem.reference,
        });

        if (columnId == "quantity") {
          setHideLoading(false);
          const { data } = await axios.get(
            `/Project/get-project-result-item/${guid}`
          );
          table.options.meta?.updateData(
            rowIndex,
            "totalPriceText",
            data.totalPriceText
          );
          setHideLoading(true);
        }
      }
    };

    // If the initialValue is changed external, sync it up with our state
    useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    return (
      <Input
        elementtype="input"
        name="reference"
        value={value}
        type={inputType ? inputType : "text"}
        onChange={(e) => setValue(e.target.value)}
        onBlur={onBlur}
      />
    );
  };

  // const updateData = async (item) => {
  //   return await axios.get(`/Project/get-project-item/${item.guid}`);
  // };

  const columns = [
    columnHelper.accessor("", {
      id: "srNo",
      cell: (info) => info.row.index + 1,
      header: "Sr No.",
    }),
    columnHelper.accessor("description", {
      id: "description",
      header: "Model",
    }),
    columnHelper.accessor("reference", {
      id: "reference",
      cell: ({ getValue, row, column: { id }, table }) => {
        return Cell(getValue, row.index, id, table, row.original.guid);
      },
      header: "Reference",
    }),
    columnHelper.accessor("volume", {
      cell: (info) =>
        info.row.original.isCustom
          ? ""
          : info.getValue() + ` (${info.row.original.volumeUnit})`,
      header: (props) => "Volume",
    }),
    columnHelper.accessor("externalStaticPressure", {
      cell: (info) =>
        info.row.original.isCustom
          ? ""
          : info.getValue() + ` (${info.row.original.pressureUnit})`,
      header: (props) => "ESP",
    }),
    columnHelper.accessor("priceText", {
      id: "priceText",
      header: "Price (AED)",
      // cell: (info) =>
      //   info.getValue() == -1 ? "Enquire" : round(info.renderValue()),
    }),
    columnHelper.accessor("quantity", {
      header: "Quantity",
      cell: ({ getValue, row, column: { id }, table }) => {
        return Cell(
          getValue,
          row.index,
          id,
          table,
          row.original.guid,
          "number"
        );
      },
    }),
    columnHelper.accessor("totalPriceText", {
      id: "totalPriceText",
      header: "Total Price (AED)",
      // cell: (info) =>
      //   info.getValue() == -1 ? "Enquire" : round(info.renderValue()),
    }),
    columnHelper.accessor("", {
      id: "cloneItem",
      cell: (props) => (
        <DocumentDuplicateIcon
          className="h-5 w-5 text-green-600 cursor-pointer"
          onClick={() => onCloneProjectItem(props?.row?.original?.guid)}
        />
      ),
    }),
    columnHelper.accessor("", {
      id: "openUnit",
      cell: (props) => (
        <FolderOpenIcon
          className="h-5 w-5 text-green-600 cursor-pointer"
          onClick={() => onOpenProjectItem(props?.row?.original?.guid)}
        />
      ),
    }),
    columnHelper.accessor("", {
      id: "deleteUnit",
      cell: (props) => (
        <TrashIcon
          className="h-5 w-5 text-red-600 cursor-pointer"
          onClick={() => onDeleteProjectItem(props?.row?.original?.guid)}
        />
      ),
    }),
  ];

  const [columnVisibility, setColumnVisibility] = useState({
    priceText: user.userGroupId == 2 || (user.showSalePrice ?? false),
    totalPriceText: user.userGroupId == 2 || (user.showSalePrice ?? false),
  });

  function onCloneProjectItem(id) {
    cloneProjectItem({ resultId: id });
  }

  function onOpenProjectItem(id) {
    selectProjectVentilationUnit({ ventilationUnitId: id });
    navigate("/selection");
  }

  function onDeleteProjectItem(id) {
    Modal.open(PopupModal, {
      title: "",
      weak: true,
      body: ["Are you sure you want to delete this unit?"],
      callback: () => {
        deleteProjectItem({ resultId: id });
      },
      closable: false,
    });
  }

  const [sorting, setSorting] = useState([
    {
      id: "createdDate",
      desc: false, // sort by name in descending order by default
    },
  ]);

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const [columnFilters, setColumnFilters] = useState(); // const [pagination, setPagination] = useState({

  return (
    <>
      <ItemGrid
        columns={columns}
        items={items}
        //columnVisibility={columnVisibility}
        tableOptions={{
          columnVisibility: { columnVisibility },
          enableColumnFilters: false,
          // state: {
          //   sorting,
          //   pagination,
          //   columnFilters,
          // },
          // onSortingChange: setSorting,
          // onPaginationChange: setPagination,
          // onColumnFiltersChange: setColumnFilters,
          // getFilteredRowModel: getFilteredRowModel(), //client side filtering
        }}
      />

      {items && items.length > 0 && (
        <div class="border-y border-gray-200 py-2 px-6">
          <div class="flex flex-row gap-2 justify-end">
            <div class="text-sm font-semibold text-gray-900">
              Total Quantity:
            </div>
            <div>{totalQuantity}</div>
          </div>
          <div class="flex flex-row gap-2 justify-end">
            <div class="text-sm font-semibold text-gray-900">
              Total Price (AED):
            </div>
            <div>{totalPrice}</div>
          </div>
          {/* <div class="flex flex-row gap-2 justify-end">
            <div class="text-sm font-semibold text-gray-900">
              Ex-Factory Price (AED):
            </div>
            <div>1</div>
          </div>
          <div class="flex flex-row gap-2 justify-end">
            <div class="text-sm font-semibold text-gray-900">
              Factory Discount (AED):
            </div>
            <div>1</div>
          </div>
          <div class="flex flex-row gap-2 justify-end">
            <div class="text-sm font-semibold text-gray-900">
              Discount Ex-Factory Price (AED):
            </div>
            <div>1</div>
          </div> */}
        </div>
      )}
    </>
  );
}
