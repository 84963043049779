import DataTable from "react-data-table-component";

export default function ItemGrid(props) {
  return (
    <DataTable
      {...props}
      // data={props.data}
      // conditionalRowStyles={props.conditionalRowStyles}
      // progressPending={props.pending}
      // highlightOnHover
      // pointerOnHover
      // pagination
    />
  );
}
