import { COMPONENT_ALIGNMENT, DEFAULT_EMPTY_THICKNESS, DEFAULT_EMPTY_LENGTH, COMPONENT_TYPES } from "../Constants";
import * as THREE from "three";

export const toMM = (v) => v / 0.001;
export const fromMM = (v) => v * 0.001;

export const calculationComponentPosition = (component_size, frame) => {
  const length = fromMM(frame.length);
  return [align(0, length, component_size, frame.componentAlignment), 0, 0];
};

export const align = (position, parentSize, childSize, alignment) => {
  switch (alignment) {
    case COMPONENT_ALIGNMENT.LEFT:
    case COMPONENT_ALIGNMENT.BOTTOM:
    case COMPONENT_ALIGNMENT.BACK:
      return position - parentSize / 2 + childSize / 2;
    case COMPONENT_ALIGNMENT.RIGHT:
    case COMPONENT_ALIGNMENT.TOP:
    case COMPONENT_ALIGNMENT.FRONT:
      return position + parentSize / 2 - childSize / 2;
    default:
      return 0;
  }
};

export const getFramesFromScene = (scene) => {
  const frames = [];
  const masterGroup = scene.children.find((c) => c.userData?.type == "masterGroup");
  if (masterGroup) {
    masterGroup.children.forEach((f) => {
      f.traverse((c) => {
        if (c.userData != null && c.userData.type == "frame") {
          frames.push(c);
        }
      });
    });
  }

  return frames;
};

export const calculateAttached = (frames, frameThickness, objects) => {
  let connections = [];

  let flattenedObjects = objects?.filter((o) => o instanceof THREE.Mesh);
  const transformGroup = objects?.find((o) => o instanceof THREE.Group);
  if (transformGroup) {
    flattenedObjects = flattenedObjects.concat(transformGroup.children);
  }

  let newFrames = frames.map((f) => {
    const object = flattenedObjects?.find((o) => o.uuid === f.id);
    if (object) {
      return {
        ...f,
        position: [object.position.x, object.position.y, object.position.z],
      };
    } else {
      return f;
    }
  });

  newFrames.forEach((f) => {
    f.connections.forEach((c) => {
      const c_pos = c.position.length == 1 ? 0 : c.position.indexOf(1) * (fromMM(f.height) / c.position.length) - fromMM(f.height) / c.position.length / 2;
      const p = [
        round(
          c.side === COMPONENT_ALIGNMENT.RIGHT
            ? f.position[0] + fromMM(f.length) / 2 + (f.hasRightFrame ? fromMM(frameThickness) : 0)
            : f.position[0] - (fromMM(f.length) / 2 + (f.hasLeftFrame ? fromMM(frameThickness) : 0))
        ),
        round(c_pos + f.position[1]),
        round(f.position[2]),
      ];

      newFrames.forEach((of) => {
        if (f.id !== of.id && !of?.delete) {
          of.connections.forEach((oc) => {
            const oc_pos = oc.position.length == 1 ? 0 : oc.position.indexOf(1) * (fromMM(of.height) / oc.position.length) - fromMM(of.height) / oc.position.length / 2;
            const op = [
              round(
                oc.side === COMPONENT_ALIGNMENT.RIGHT
                  ? of.position[0] + fromMM(of.length) / 2 + (of.hasRightFrame ? fromMM(frameThickness) : 0)
                  : of.position[0] - (fromMM(of.length) / 2 + (of.hasLeftFrame ? fromMM(frameThickness) : 0))
              ),
              round(oc_pos + of.position[1]),
              round(of.position[2]),
            ];

            //if (round(Math.abs(p[0] - op[0])) <= 0.002 && p[1] == op[1] && p[2] == op[2]) {
            if (p[0] == op[0] && p[1] == op[1] && p[2] == op[2]) {
              connections.push({ frame: f.id, from: c.id, to: oc.id });
            }
          });
        }
      });
    });
  });

  newFrames = newFrames.map((f) => {
    const connectionLink = connections.filter((c) => c.frame === f.id);
    if (connectionLink?.length > 0) {
      return {
        ...f,
        connections: f.connections.map((c) => {
          const cl = connectionLink.find((cl) => cl.from === c.id);
          return {
            ...c,
            attachedTo: cl ? cl.to : null,
            direction: c.lockedDirection ? c.direction : null,
          };
        }),
      };
    } else {
      return {
        ...f,
        connections: f.connections.map((c) => {
          return {
            ...c,
            attachedTo: null,
            direction: c.lockedDirection ? c.direction : null,
          };
        }),
      };
    }
  });

  const framesWithLockedDirectionConnections = newFrames.filter((f) => f.connections.filter((c) => c.lockedDirection).length > 0);

  function setDirection(connection) {
    const attachedFrame = findFrameWithConnectionId(newFrames, connection.attachedTo);

    attachedFrame.connections.forEach((attachedConnection) => {
      attachedConnection.direction = connection.direction;

      if (attachedConnection.id !== connection.attachedTo && attachedConnection.attachedTo) {
        setDirection(attachedConnection);
      }
    });
  }

  framesWithLockedDirectionConnections.forEach((frame) => {
    frame.connections
      .filter((c) => c.lockedDirection && c.attachedTo)
      .forEach((connection) => {
        setDirection(connection);
      });
  });

  return newFrames;
};

export const findFrameWithConnectionId = (frames, id) => {
  for (let f = 0; f < frames.length; f++) {
    const frame = frames[f];
    const connection = frame.connections.find((c) => c.id == id);
    if (connection) return frame;
  }
};

export const findConnection = (frames, id) => {
  for (let f = 0; f < frames.length; f++) {
    const frame = frames[f];
    const connection = frame.connections.find((c) => c.id == id);
    if (connection) return connection;
  }
};

export const adjustPosition = (frame, newFrames, direction, difference) => {
  frame.position = [frame.position[0] + (direction == COMPONENT_ALIGNMENT.LEFT ? -difference : difference), frame.position[1], frame.position[2]];

  frame.connections
    .filter((c) => c.attachedTo && c.side === direction)
    .forEach((c) => {
      const attachedFrame = newFrames.find((f) => f.connections.find((fc) => fc.id === c.attachedTo) != null);
      adjustPosition(attachedFrame, newFrames, direction, difference);
    });
};

export function round(num) {
  return Math.round((num + Number.EPSILON) * 1000) / 1000;
}

export function getDefaultFrameSize(componentType) {
  switch (componentType) {
    case COMPONENT_TYPES.FAN:
      return DEFAULT_EMPTY_LENGTH * 1.5;
    case COMPONENT_TYPES.FILTER:
      return DEFAULT_EMPTY_LENGTH / 2;
    default:
      return DEFAULT_EMPTY_LENGTH;
  }
}

export function toggleHasFrame(hasFrame, side, frames, selectedIds, frameThicknessMM) {
  const difference = fromMM(frameThicknessMM) * (hasFrame ? 1 : -1);
  const newFrames = [...frames];

  selectedIds.forEach((id) => {
    const frame = newFrames.find((f) => f.id === id);
    frame.position = [frame.position[0], frame.position[1], frame.position[2]];

    if (side === COMPONENT_ALIGNMENT.LEFT && frame.hasLeftFrame === hasFrame) return;
    if (side === COMPONENT_ALIGNMENT.RIGHT && frame.hasRightFrame === hasFrame) return;

    if (side === COMPONENT_ALIGNMENT.LEFT) {
      frame.hasLeftFrame = hasFrame;
    }
    if (side === COMPONENT_ALIGNMENT.RIGHT) {
      frame.hasRightFrame = hasFrame;
    }

    frame.connections
      .filter((c) => c.attachedTo && c.side === side)
      .forEach((c) => {
        const attachedFrame = newFrames.find((f) => f.connections.find((fc) => fc.id === c.attachedTo) != null);
        adjustPosition(attachedFrame, newFrames, c.side, difference);
      });
  });

  return newFrames;
}

export function calculateDimensions(frames, frameThickness, offsetHorizontal, offsetVertical, dimensionSide = 1, isTotal = false, ignoreHidden = false) {
  const _boxSize = new THREE.Vector3();
  const _boxPos = new THREE.Vector3();
  calculateBounds(frames, _boxPos, _boxSize, frameThickness);

  const lengthPosition = [_boxPos.x, _boxPos.y + _boxSize.y / 2 + offsetVertical, _boxPos.z + _boxSize.z / 2];
  const heightPosition = [_boxPos.x + (_boxSize.x / 2 + offsetHorizontal) * dimensionSide * -1, _boxPos.y, _boxPos.z + _boxSize.z / 2];
  const widthPosition = [_boxPos.x + (_boxSize.x / 2 + offsetHorizontal) * dimensionSide * (ignoreHidden ? -1 : 1), _boxPos.y - _boxSize.y / 2, _boxPos.z];

  const frameBounds = [
    {
      lengthPosition: lengthPosition,
      heightPosition: heightPosition,
      widthPosition: widthPosition,

      lengthSize: _boxSize.x,
      heightSize: _boxSize.y,
      widthSize: _boxSize.z,

      lengthSizeMM: Math.round(toMM(_boxSize.x)),
      heightSizeMM: Math.round(toMM(_boxSize.y)),
      widthSizeMM: Math.round(toMM(_boxSize.z)),

      showLength: true,
      showHeight: isTotal,
      showWidth: isTotal,
    },
  ];

  if (frames.length == 1 && frames[0].hasFlange && frames[0].component == COMPONENT_TYPES.FAN) {
    const frame = frames[0];

    let width = 0;
    let height = 0;
    let length = fromMM(frame.flange?.length ?? 70);
    let flangeDistanceFromTop = 0;

    const alignment = frame.flangeAlignment;
    const fanOutletHeight = fromMM(frame.fan?.outletHeight ?? 422);

    if (frame.component == COMPONENT_TYPES.FAN) {
      width = fromMM(frame.fan?.width ?? 322);
      height = fanOutletHeight;
      flangeDistanceFromTop = fromMM(frame.fan?.distanceFromTop ?? 150);
    } else {
      width = fromMM(frame.flange?.width ?? frame.width);
      height = fromMM(frame.flange?.height ?? frame.height);
      flangeDistanceFromTop = fromMM(frame.flange?.flangeDistanceFromTop ?? 0);
    }

    const offsetHorizontalModifier = ignoreHidden && dimensionSide == alignment ? 1 : 2;

    let lengthPosition = [align(_boxPos.x, _boxSize.x, -length, alignment), _boxPos.y + _boxSize.y / 2 + offsetVertical, _boxPos.z + _boxSize.z / 2];
    let heightPosition = [
      _boxPos.x + (_boxSize.x / 2 + offsetHorizontal * offsetHorizontalModifier) * alignment,
      align(_boxPos.y - flangeDistanceFromTop - fromMM(frameThickness), _boxSize.y, height, COMPONENT_ALIGNMENT.TOP),
      _boxPos.z + _boxSize.z / 2,
    ];
    let widthPosition = [_boxPos.x + (_boxSize.x / 2 + offsetHorizontal * offsetHorizontalModifier) * alignment, _boxPos.y - _boxSize.y / 2, _boxPos.z];

    let showLength = true;
    let showHeight = ignoreHidden ? true : alignment != dimensionSide;
    let showWidth = ignoreHidden ? true : alignment == dimensionSide;
    let dimensionYAlignment = COMPONENT_ALIGNMENT.BOTTOM;
    let outletFlangeDim = false;

    if (alignment == COMPONENT_ALIGNMENT.TOP) {
      let h = height;
      height = length;
      length = h;

      flangeDistanceFromTop = fromMM(frame.fan?.size ?? 550) - fanOutletHeight;

      heightPosition = [_boxPos.x + (_boxSize.x / 2 + offsetHorizontal) * dimensionSide * -1, align(_boxPos.y, _boxSize.y, -height, alignment), _boxPos.z + _boxSize.z / 2];
      lengthPosition = [
        align(_boxPos.x -fromMM(frameThickness) - height, _boxSize.x, length, COMPONENT_ALIGNMENT.RIGHT),
        _boxPos.y + _boxSize.y / 2 + offsetVertical / 2,
        _boxPos.z + _boxSize.z / 2,
      ];
      widthPosition = [_boxPos.x + (_boxSize.x / 2 + offsetHorizontal * 2) * dimensionSide * (ignoreHidden ? -1 : 1), _boxPos.y - _boxSize.y / 2, _boxPos.z];
      showHeight = ignoreHidden ? true : dimensionSide == -1;
      showWidth = ignoreHidden ? true : dimensionSide == 1;
      dimensionYAlignment = COMPONENT_ALIGNMENT.TOP;
      outletFlangeDim = true;
    }

    frameBounds.push({
      lengthPosition: lengthPosition,
      heightPosition: heightPosition,
      widthPosition: widthPosition,

      lengthSize: length,
      heightSize: height,
      widthSize: width,

      lengthSizeMM: Math.round(toMM(length)),
      heightSizeMM: Math.round(toMM(height)),
      widthSizeMM: Math.round(toMM(width)),

      showLength: showLength,
      showHeight: showHeight,
      showWidth: showWidth,

      dimensionYAlignment: dimensionYAlignment,

      outletFlangeDim: outletFlangeDim
    });
  }

  return frameBounds;
}

export const calculateFurthestFrame = (frames, direction, property, frameThickness) => {
  const positionIndex = property === "length" ? 0 : property === "height" ? 1 : 2;

  const initalFrameThickness = fromMM(positionIndex == 0 ? ((direction < 0 ? frames[0].hasLeftFrame : frames[0].hasRightFrame) ? frameThickness : 0) : frameThickness);
  const initialValue = frames[0].position[positionIndex] + direction * (fromMM(frames[0][property] / 2) + initalFrameThickness);

  const reduceFn = (extreme, frame) => {
    const totalFrame = fromMM(positionIndex == 0 ? ((direction < 0 ? frame.hasLeftFrame : frame.hasRightFrame) ? frameThickness : 0) : frameThickness);
    const currentValue = frame.position[positionIndex] + direction * (fromMM(frame[property] / 2) + totalFrame);
    return direction < 0 ? (currentValue < extreme ? currentValue : extreme) : currentValue > extreme ? currentValue : extreme;
  };

  return frames.reduce(reduceFn, initialValue);
};

export const calculateBounds = (frames, pos, size, frameThickness) => {
  let x1 = calculateFurthestFrame(frames, -1, "length", frameThickness);
  let x2 = calculateFurthestFrame(frames, 1, "length", frameThickness);

  let y1 = calculateFurthestFrame(frames, -1, "height", frameThickness);
  let y2 = calculateFurthestFrame(frames, 1, "height", frameThickness);

  let z1 = calculateFurthestFrame(frames, -1, "width", frameThickness);
  let z2 = calculateFurthestFrame(frames, 1, "width", frameThickness);

  // calculate totals

  const totalLength = x2 - x1;
  const totalHeight = y2 - y1;
  const totalWidth = z2 - z1;

  size.set(totalLength, totalHeight, totalWidth);
  pos.set((x1 + x2) / 2, (y1 + y2) / 2, (z1 + z2) / 2);
};

export function createSVGDimensions(frames, frameThickness, offsetHorizontal, offsetVertical, projection, groupSection, minX, maxX, minY, maxY) {
  let svg = "";

  generateSVGLines(frames, offsetHorizontal, offsetVertical * 2, true, true);

  if (groupSection) {
    const sectionIds = [...new Set(frames.filter((f) => f.sectionId != null).map((f) => f.sectionId))];

    sectionIds.forEach((sectionId) => {
      generateSVGLines(
        frames.filter((f) => f.sectionId == sectionId),
        offsetHorizontal,
        offsetVertical,
        false,
        false
      );
    });

    frames
      .filter((f) => !f.sectionId)
      .forEach((frame, i) => {
        generateSVGLines([frame], offsetHorizontal, offsetVertical, true, false);
      });
  } else {
    frames.forEach((frame, i) => {
      generateSVGLines([frame], offsetHorizontal, offsetVertical, true, false);
    });
  }

  function generateSVGLines(frames, offsetHorizontal, offsetVertical, includeSide, isTotal) {
    const _boxSize = new THREE.Vector3();
    const _boxPos = new THREE.Vector3();
    calculateBounds(frames, _boxPos, _boxSize, frameThickness);

    const dimensions = calculateDimensions(frames, frameThickness, offsetHorizontal, offsetVertical, -1, isTotal, true);
    const strokeWidth = 0.003;
    const textSize = 0.08;

    const edgeHeight = maxY - minY;
    const edgesY = edgeHeight / 2;
    const diff = _boxPos.y - edgesY;
    _boxPos.y = edgesY;

    const textXOffset = 0.02;

    for (let d = 0; d < dimensions.length; d++) {
      const dims = dimensions[d];

      if (projection == 0) {
        // Top projection

        // Dimensions length
        let x1 = dims.lengthPosition[0] - dims.lengthSize / 2;
        let x2 = dims.lengthPosition[0] + dims.lengthSize / 2;
        let y1 = dims.lengthPosition[1];
        let y2 = y1;

        if (includeSide && dims.widthPosition && dims.showWidth) {
          // Dimensions right
          x1 = dims.widthPosition[0]; //_boxSize.x / 2 + offset;
          x2 = x1;
          y1 = dims.widthPosition[2] - dims.widthSize / 2;
          y2 = dims.widthPosition[2] + dims.widthSize / 2;

          svg += `<!-- Dimension -->`;
          svg += `<line x1="${x1 - 0.02}" y1="${y1}" x2="${x1 + 0.04}" y2="${y1}" stroke-width="${strokeWidth}" stroke="black" />`;
          svg += `<line x1="${x1 - 0.02}" y1="${y2}" x2="${x2 + 0.04}" y2="${y2}" stroke-width="${strokeWidth}" stroke="black" />`;
          svg += `<line x1="${x1}" y1="${y1}" x2="${x2}" y2="${y2}" stroke-width="${strokeWidth}" stroke="black" />`;
          svg += `<text text-anchor="middle" x="${x1 + textSize + textXOffset}" y="${dims.widthPosition[2]}" font-weight="bold" font-size="${textSize}" font-family="sans-serif">${
            dims.widthSizeMM
          }</text>`;
        }
      } else {
        // Side projection

        // Dimensions length
        let x1 = dims.lengthPosition[0] - dims.lengthSize / 2;
        let x2 = dims.lengthPosition[0] + dims.lengthSize / 2;

        // Correct y position as the drawing has been converted to world
        let dimensionAlignment = dims.dimensionYAlignment;
        let y1 =
          dimensionAlignment == COMPONENT_ALIGNMENT.TOP
            ? _boxSize.y - (dims.lengthPosition[1] - (diff + (edgeHeight - _boxSize.y) / 2)) + (maxY - _boxSize.y)
            : dims.lengthPosition[1] - (diff + (edgeHeight - _boxSize.y) / 2) + (maxY - _boxSize.y);
        let y2 = y1;

        svg += `<!-- Dimension -->`;
        svg += `<line x1="${x1}" y1="${y1 - 0.04}" x2="${x1}" y2="${y1 + 0.02}" stroke-width="${strokeWidth}" stroke="black" />`;
        svg += `<line x1="${x2}" y1="${y1 - 0.04}" x2="${x2}" y2="${y1 + 0.02}" stroke-width="${strokeWidth}" stroke="black" />`;
        svg += `<line x1="${x1}" y1="${y1}" x2="${x2}" y2="${y2}" stroke-width="${strokeWidth}" stroke="black" />`;
        svg += `<text text-anchor="middle" x="${dims.lengthPosition[0]}" y="${y1 - textXOffset * (dims.outletFlangeDim ? 3 : 1)}" font-weight="bold" font-size="${textSize}" font-family="sans-serif">${
          dims.lengthSizeMM
        }</text>`;

        if (includeSide && dims.heightPosition && dims.showHeight) {
          // Dimensions right
          x1 = dims.heightPosition[0];
          x2 = x1;

          // Correct y position as the drawing has been converted to world
          dims.heightPosition[1] = _boxSize.y - (dims.heightPosition[1] - (diff + (edgeHeight - _boxSize.y) / 2)) + (maxY - _boxSize.y);
          dims.heightPosition[1] -= fromMM(100);
          y1 = dims.heightPosition[1] - dims.heightSize / 2;
          y2 = dims.heightPosition[1] + dims.heightSize / 2;

          svg += `<!-- Dimension -->`;
          svg += `<line x1="${x1 - 0.02}" y1="${y1}" x2="${x1 + 0.04}" y2="${y1}" stroke-width="${strokeWidth}" stroke="black" />`;
          svg += `<line x1="${x1 - 0.02}" y1="${y2}" x2="${x2 + 0.04}" y2="${y2}" stroke-width="${strokeWidth}" stroke="black" />`;
          svg += `<line x1="${x1}" y1="${y1}" x2="${x2}" y2="${y2}" stroke-width="${strokeWidth}" stroke="black" />`;
          svg += `<text text-anchor="middle" x="${x1 + textSize + textXOffset}" y="${
            dims.heightPosition[1] + textSize / 3
          }" font-weight="bold" font-size="${textSize}" font-family="sans-serif">${dims.heightSizeMM}</text>`;

          //Dimensions lef
          if (d == 0) {
            x1 = -0.85 * dims.heightPosition[0];
            x2 = x1;

            // Correct y position as the drawing has been converted to world
            // dims.heightPosition[1] = _boxSize.y - (dims.heightPosition[1] - (diff + (edgeHeight - _boxSize.y) / 2)) + (maxY - _boxSize.y);
            // dims.heightPosition[1] -= fromMM(100);
            y1 = dims.heightPosition[1] - dims.heightSize / 2;
            y2 = (dims.heightPosition[1] + dims.heightSize / 2)  + fromMM(100);

            svg += `<!-- Dimension -->`;
            svg += `<line x1="${x1 - 0.02}" y1="${y1}" x2="${x1 + 0.04}" y2="${y1}" stroke-width="${strokeWidth}" stroke="black" />`;
            svg += `<line x1="${x1 - 0.02}" y1="${y2}" x2="${x2 + 0.04}" y2="${y2}" stroke-width="${strokeWidth}" stroke="black" />`;
            svg += `<line x1="${x1}" y1="${y1}" x2="${x2}" y2="${y2}" stroke-width="${strokeWidth}" stroke="black" />`;
            //svg += `<text text-anchor="middle" x="${x1 + textSize}" y="${
            svg += `<text text-anchor="middle" x="${x1 - textSize - textXOffset}" y="${
              dims.heightPosition[1] + textSize / 3
            }" font-weight="bold" font-size="${textSize}" font-family="sans-serif">${dims.heightSizeMM + 100 + " "}</text>`;
          }
        }
      }
    }
  }

  return svg;
}
