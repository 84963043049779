import Input from "../shared/Input";
import { useUpdateConfiguration } from "../../hooks/useQueries";
// import { useModal } from "../../hooks/useQueries";
//import { useModal, ModalProvider } from "react-hooks-use-modal";
// import { Modal } from "react-modal-global";
// import PopupModal from "../shared/Modal";
import { useState, memo, useEffect } from "react";

const ConfigurationItem = memo((props) => {
 // const [open, close] = useModal();
  const getConfigurationValues = (ci) => {
    return Object.keys(ci.values).filter(c => ci.values[c].available == true).map((key) => {
      const item = ci.values[key];
      return {
            available: item.available,
            value: item.value,
            data: item.description,
            selected: item.selected,
            cssClass: item.available //? "visible" : "invisible"
              ? "bg-green-200"
              : item.possible
              ? //"visible"
                //:
                "bg-red-300 color-white"
              : "",
          }
    });
  };

  const configurationInstance = props.configurationInstance;
  const configurationItem = props.configurationItem;

  const configurationValues = getConfigurationValues(configurationItem);

  const fieldValues = configurationValues;

  const fieldLabel = configurationItem.description;

  // const computedSelectedValueOrDefault =
  //   configurationItem.computedSelectedValueOrDefault;

  const [computedSelectedValueOrDefault, setComputedSelectedValueOrDefault] = useState();

  useEffect(() =>{
    setComputedSelectedValueOrDefault(configurationItem.editorType == "TextBox" || configurationItem.editorType == "Text" ? configurationItem.customValue : configurationItem.computedSelectedValueOrDefault);
  },[configurationItem.customValue,  configurationItem.computedSelectedValueOrDefault])


  const [enabled, setEnabled] = useState(configurationItem.editorType == "Switch" ? (fieldValues?.length > 0 ? fieldValues[0].selected ?? false : false) : false);

  const elementConfig = () => {
    if (configurationItem.editorType == "TextBox") return { elementtype: "input", type: configurationItem.sourceFieldType == "Int" || configurationItem.sourceFieldType == "Float" ? "number" : "text" };
    if (configurationItem.editorType == "DropDown") return { elementtype: "select", type: "" };
    if (configurationItem.editorType == "Checkbox") return { elementtype: "checkbox", type: "" };
    if (configurationItem.editorType == "Radio") return { elementtype: "radio", type: "" };
    if (configurationItem.editorType == "Text") return { elementtype: "label", type: "" };
    if (configurationItem.editorType == "Switch") return { elementtype: "switch", type: "" };
    else {
      return { elementtype: "", type: "" };
    }
  };

  const { elementtype, type } = elementConfig();

  const id = `${type}_${configurationItem.classConfiguration}`;

  const updateConfiguration = useUpdateConfiguration();

  const hasConflicts = (value) => {
    //return configurationItem.classConfiguration == "DESIGN_VOLUME" || configurationItem.classConfiguration == "DESIGN_PRESSURE" || 
    return !configurationItem.values[value].available;
  };

  const checkConflits = (value) => 
  {

  }

  const onChangedHandler = (value, e, onBlur) => {
    if (configurationItem.customValue != null && value == configurationItem.customValue) return;
    
    setComputedSelectedValueOrDefault(value);

    if (configurationItem.editorType == "TextBox") {
      if(!onBlur)return;
    }

    if (configurationItem.editorType == "Switch") setEnabled(!enabled);

    const cv = {
      instance: configurationInstance.instanceId,
      key: configurationItem.classConfiguration,
      value: value,
      selected: configurationItem.editorType == "Switch" ? !enabled : true,
      conflict: false,// hasConflicts(value),
    };

    let skipConfiltCheck = configurationItem.editorType == "TextBox";
    // configurationItem.classConfiguration == "DESIGN_VOLUME" ||
    // configurationItem.classConfiguration == "DESIGN_PRESSURE";
    updateConfiguration(cv, configurationInstance);

    // if (!skipConfiltCheck && hasConflicts(value)) {
    //   const modal = {
    //     title: "",
    //     content: "There were conflicts by selecting this option",
    //   };
    //   Modal.open(PopupModal, {
    //     title: "CONFLICTS",
    //     weak: true,
    //     body: ["This option conflicts with other selected options.", <br />, "Please proceed if you would like to accept the conflicts."],
    //     callback: () => {
    //       cv.conflict = true;
    //       updateConfiguration(cv, configurationInstance);
    //     },
    //     closable: false,
    //   });
    // } else {
      
    //   updateConfiguration(cv, configurationInstance);
    // }
  };

  return (
    <>
      {configurationItem.visible && (
        <Input
          elementtype={elementtype}
          label={fieldLabel}
          name={id}
          id={id}
          options={fieldValues ?? null}
          type={type}
          onChange={
           // configurationItem.editorType == "TextBox" ? null : (e) => onChangedHandler(configurationItem.editorType == "Switch" ? computedSelectedValueOrDefault : e.target.value)
            (e) => onChangedHandler(configurationItem.editorType == "Switch" ? computedSelectedValueOrDefault : e.target.value, e)
          }
          onBlur={configurationItem.editorType == "TextBox" ? (e) => onChangedHandler(e.target.value, null, true) : null}
          // onClick ={
          //   configurationItem.editorType == "Switch"
          //   ? (e) => {setEnabled(!enabled); onChangedHandler(computedSelectedValueOrDefault, e)}
          //   : null
          // }
          value={computedSelectedValueOrDefault ?? null}
          //value={computedSelectedValueOrDefault}
          text={computedSelectedValueOrDefault ?? ""}
          enabled={enabled}
          //defaultValue=
        />
      )}
    </>
  );
});

export default ConfigurationItem;
