import { useEffect } from "react";
import { useStore } from "../designer/Store/zustandStore";
import { useAHUDesignerManager } from "../../hooks/useQueries";
export default function Drawings() {
  const [setRequestDrawings, drawings] = useStore((state) => [
    state.setRequestDrawings,
    state.drawings,
  ]);

  // const [
  //   frameThickness,
  //   requestDrawings,
  //   setRequestDrawings,
  //   setDrawings,
  //   drawingTolerance,
  //   drawings,
  //   frames
  // ] = useStore((state) => [
  //   state.frameThickness,
  //   state.requestDrawings,
  //   state.setRequestDrawings,
  //   state.setDrawings,
  //   state.drawingTolerance,
  //   state.drawings,
  //   state.frames
  // ]);

  //const { scene } = useThree();

  useEffect(() => {
   // setRequestDrawings(true);
  },[]);

  // useEffect(() => {
  //   console.log("drawing finished");
  //   console.log(drawings);
  //   console.log( useStore.getState().drawings);
  // }, [drawings]);

  // useEffect(() => {
  //   if (requestDrawings) {
  //     setTimeout(() => {
  //       const drawings = [];
  //       const sectionIds = [...new Set(frames.filter((f) => f.sectionId != null).map((f) => f.sectionId))];

  //       var edges = createEdges(scene, frames, false, drawingTolerance);
  //       var svg = edgesToSVG(edges, frames, frameThickness, 0.25, 0.25, 0);
  //       edges = createEdges(scene, frames, true, drawingTolerance);
  //       var svg_side = edgesToSVG(edges, frames, frameThickness, 0.25, 0.25, 1);
  //       drawings.push({ name: "Main", top: "data:image/svg+xml;base64," + btoa(svg), side: "data:image/svg+xml;base64," + btoa(svg_side) });

  //       sectionIds.forEach((sectionId) => {
  //         edges = createEdges(scene, frames, false, drawingTolerance, sectionId);
  //         svg = edgesToSVG(edges, frames, frameThickness, 0.25, 0.25, 0, sectionId);
  //         edges = createEdges(scene, frames, true, drawingTolerance, sectionId);
  //         svg_side = edgesToSVG(edges, frames, frameThickness, 0.25, 0.25, 1, sectionId);
  //         drawings.push({ name: "Section", top: "data:image/svg+xml;base64," + btoa(svg), side: "data:image/svg+xml;base64," + btoa(svg_side) });
  //       });

  //       setDrawings(drawings);
  //       setRequestDrawings(false);
  //     }, 0);
  //   }
  // }, [requestDrawings]);

  return (
    <div className="p-5">
      {/* <Button onClick={() => setRequestDrawings(true)}>Refresh drawings</Button> */}

      {drawings?.length > 0 && (
        <div>
          <h4>Side view</h4>
          <hr></hr>
          <br></br>
          <img src={drawings[0].side} />
          <h4>Top view</h4>
          <hr></hr>
          <br></br>
          <img src={drawings[0].top} />
        </div>
      )}
    </div>
  );
}
