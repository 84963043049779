import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useUser } from "../hooks/useQueries";
import { NavLink } from "react-router-dom";
import { useForm, useFormState } from "react-hook-form";
import Input from "../components/shared/Input";

export default function ForgotPassword() {
  const { forgotPassword } = useUser();
  const validationSchema = yup.object().shape({
    email: yup.string().email().required("Required"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: { email: "" },
  });

  return (
    <form
      onSubmit={handleSubmit((data) => {
        forgotPassword(data);
      })}
    >
      <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="mt-6 text-center text-2xl font-medium leading-9 tracking-tight text-gray-900">Forgot Password</h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
          <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12 space-y-6">
            <div>
              <Input label="Email address" elementtype="input" type="email" name="email" {...register("email")} error={errors.email} />
              {errors.email && <span className="text-red-500 text-xs">{errors.email.message}</span>}
            </div>

            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-primary px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-primary-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-400"
              >
                Reset Password
              </button>
            </div>
          </div>

          <p className="mt-6 text-center text-sm text-gray-500">
            or &nbsp;
            <NavLink className="font-semibold leading-6 text-primary hover:text-primary-400 cursor-pointer">Log in </NavLink>
          </p>
        </div>
      </div>
    </form>
  );
}
