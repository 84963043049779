export default {
  shape: {
    value: "Rectangular",
    options: ["Radial", "Rectangular", "Curved"],
  },
  size: 0.6,
  length: 50,
  rows: [0.5, 0.5],
  columns: [0.5, 0.5],
};
