export function Tabs({ children, buttons }) {
  return (
    <>
      <div className="border-b border-gray-200">
        <menu className="-mb-px flex space-x-8 px-3">{buttons}</menu>
      </div>
      {children}
    </>
  );
}

export function TabButton({ isSelected, children, ...props }) {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  const activeClass = "text-primary";
  const inActiveClass = "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700";
  return (
    <ul>
      <a className={classNames(isSelected ? activeClass : inActiveClass, "group inline-flex items-center py-4 text-sm font-medium cursor-pointer")} {...props}>
        <span>{children}</span>
      </a>
    </ul>
  );
}
